import { Modal } from "react-bootstrap";

const SaveExcelModal = ({isVisible, setIsVisible, handleSaveExcel = null}) => {
    return (
        <Modal show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>엑셀 저장</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>엑셀 파일로 저장하시겠습니까?</p>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setIsVisible(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">취소</button>
                <button onClick={() => {
                    setIsVisible(false)
                    if (handleSaveExcel) handleSaveExcel()
                }} className="dms-btn dms-btn-xs dms-btn-filled">저장</button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default SaveExcelModal;