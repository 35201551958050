import { useEffect, useRef, useState } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetch from "../useFetch";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import useFetchWithParameters from "../useFetchWithParameters";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";


const optionsInOutStatus = [
    { value: 'IN', label: 'IN' },
    { value: 'OUT', label: 'OUT' },
]
const WarehouseDetails = () => {

    const history = useNavigate();
    const [isVisibleDelete, setIsVisibleDelete] = useState(false);

    const { id } = useParams();
    
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getFAC?facilityId='+id
    const supplyHistoryApiUrl = process.env.REACT_APP_BASE_API_URL+'/sply/getSplyLst'
    const inOutHistoryApiUrl = process.env.REACT_APP_BASE_API_URL+'/sply/getFacSplyHist'
    const fileListApiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getFileLst?facilityId='+id+'&reqPageSize=10000&reqPageNo=1'
    
    const uploadFileRef = useRef()

    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isVisibleSupplySaveExcel, setIsVisibleSupplySaveExcel] = useState(false)
    const [isVisibleInOutSaveExcel, setIsVisibleInOutSaveExcel] = useState(false)
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoadingImage, setIsLoadingImage] = useState(false);

    const [name, setName] = useState('')
    const [type, setType] = useState(null)
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [coordinateX, setCoordinateX] = useState('')
    const [coordinateY, setCoordinateY] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [deptIncharge, setDeptIncharge] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [faxNumber, setFaxNumber] = useState('')
    const [repName, setRepName] = useState('')
    const [repPhoneNumber, setRepPhoneNumber] = useState('')
    const [ownerName, setOwnerName] = useState('')
    const [ownerPhoneNumber, setOwnerPhoneNumber] = useState('')
    const [otherFields, setOtherFields] = useState({})

    const [updateDt, setUpdateDt] = useState('')    
    

    const [image, setImage] = useState(null)
    
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])
    
    const [itemTypes, setItemTypes] = useState([])
    
    const [supplyItemCategories1, setSupplyItemCategories1] = useState([])
    const [supplyItemCategories2, setSupplyItemCategories2] = useState([])
    const [supplyItemCategories3, setSupplyItemCategories3] = useState([])
    const [supplyItemCategories4, setSupplyItemCategories4] = useState([])

    const [inOutItemCategories1, setInOutItemCategories1] = useState([])
    const [inOutItemCategories2, setInOutItemCategories2] = useState([])
    const [inOutItemCategories3, setInOutItemCategories3] = useState([])
    const [inOutItemCategories4, setInOutItemCategories4] = useState([])

    const [supplyHistoryParameters, setSupplyHistoryParameters] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [supplyHistories, setSupplyHistories] = useState([])
    const [supplyHistoryTotalCount, setSupplyHistoryTotalCount] = useState(null);
    const [supplyHistoryFilters, setSupplyHistoryFilters] = useState({})
    
    const [inOutHistoryParameters, setInOutHistoryParameters] = useState({
        facilityId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [inOutHistories, setInOutHistories] = useState([])
    const [inOutHistoryTotalCount, setInOutHistoryTotalCount] = useState(null);
    const [inOutHistoryFilters, setInOutHistoryFilters] = useState({})
    
    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    const { fetchResponse: fileListFetchResponse } = useFetch(fileListApiUrl);
    const { fetchResponse: supplyHistoryFetchResponse, fetchIsLoading: supplyFetchIsLoading, fetchError: supplyFetchError } = useFetchWithParameters(supplyHistoryApiUrl, supplyHistoryParameters);
    const { fetchResponse: inOutHistoryFetchResponse, fetchIsLoading: inOutFetchIsLoading, fetchError: inOutFetchError } = useFetchWithParameters(inOutHistoryApiUrl, inOutHistoryParameters);
    
    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
        ApiHelper.loadDropdown('groupCode=D003&codeDepth=1', setFacilityTypes)
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
    }, [])

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setName(fetchResponse?.resultObject?.name)
            setZipCode(fetchResponse?.resultObject?.zipCd)
            setMainAddress(fetchResponse?.resultObject?.addr)
            setDetailedAddress(fetchResponse?.resultObject?.addrDtl)
            setCoordinateX(fetchResponse?.resultObject?.xcord)
            setCoordinateY(fetchResponse?.resultObject?.ycord)
            setLatitude(fetchResponse?.resultObject?.lat)
            setLongitude(fetchResponse?.resultObject?.lot)
            setDeptIncharge(fetchResponse?.resultObject?.deptCharge)
            setPhoneNumber(fetchResponse?.resultObject?.telno)
            setFaxNumber(fetchResponse?.resultObject?.faxno)
            setRepName(fetchResponse?.resultObject?.reprMgrName)
            setRepPhoneNumber(fetchResponse?.resultObject?.reprMgrTelno)
            setOwnerName(fetchResponse?.resultObject?.ownerName)
            setOwnerPhoneNumber(fetchResponse?.resultObject?.ownerTelno)
            setOtherFields({
                acceptableArea: fetchResponse?.resultObject?.acceptableArea,
                maxCapacity: fetchResponse?.resultObject?.maxCapacity,
            })

            setUpdateDt(fetchResponse?.resultObject?.updateDt)


        }
    }, [fetchResponse])

    useEffect(() => {
        if (fileListFetchResponse?.resultList?.[0]?.fileId)
        {
            fetch(process.env.REACT_APP_BASE_API_URL+'/fac/getImage?fileId='+fileListFetchResponse?.resultList?.[0]?.fileId)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }

                return response.blob();
            })
            .then(data => {
                setImage(window.URL.createObjectURL(new Blob([data])))
            })
            .catch(error => {
            })
        }
    }, [fileListFetchResponse])

    useEffect(() => {
        if (inOutHistoryFetchResponse)
        {
            setInOutHistoryTotalCount(inOutHistoryFetchResponse?.totalCount);
            setInOutHistories(inOutHistoryFetchResponse?.resultList ?? []);
        }
    }, [inOutHistoryFetchResponse])

    useEffect(() => {
        if (supplyHistoryFetchResponse)
        {
            setSupplyHistoryTotalCount(supplyHistoryFetchResponse?.totalCount);
            setSupplyHistories(supplyHistoryFetchResponse?.resultList ?? []);
        }
    }, [supplyHistoryFetchResponse])

    useEffect(() => {
        if (fetchResponse?.resultObject && areas1) setType(facilityTypes.find(value => value.value == fetchResponse?.resultObject?.classCd))
    }, [fetchResponse, facilityTypes])

    useEffect(() => {
        if (fetchResponse?.resultObject && areas1) setArea1(areas1.find(value => value.value == fetchResponse?.resultObject?.area1Cd))
    }, [fetchResponse, areas1])

    useEffect(() => {
        if (fetchResponse?.resultObject && areas2) setArea2(areas2.find(value => value.value == fetchResponse?.resultObject?.area2Cd))
    }, [fetchResponse, areas2])
    
    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!supplyHistoryFilters?.type) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setSupplyItemCategories2([])
        setSupplyItemCategories3([])
        setSupplyItemCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1='+supplyHistoryFilters?.type, setSupplyItemCategories1)
    }, [supplyHistoryFilters?.type])

    useEffect(() => {
        if (!supplyHistoryFilters?.cat1) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setSupplyItemCategories3([])
        setSupplyItemCategories4([])

        var cat1 = supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1='+supplyHistoryFilters?.type+'&codeLev2='+cat1, setSupplyItemCategories2)
    }, [supplyHistoryFilters?.cat1])

    useEffect(() => {
        if (!supplyHistoryFilters?.cat2) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });
        
        setSupplyItemCategories4([])

        var cat1 = supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)
        var cat2 = supplyHistoryFilters?.cat2.substring(supplyHistoryFilters?.cat1.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1='+supplyHistoryFilters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2, setSupplyItemCategories3)
    }, [supplyHistoryFilters?.cat2])

    useEffect(() => {
        if (!supplyHistoryFilters?.cat3) return
        setSupplyHistoryFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)
        var cat2 = supplyHistoryFilters?.cat2.substring(supplyHistoryFilters?.cat1.length)
        var cat3 = supplyHistoryFilters?.cat3.substring(supplyHistoryFilters?.cat2.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1='+supplyHistoryFilters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2+'&codeLev4='+cat3, setSupplyItemCategories4)
    }, [supplyHistoryFilters?.cat3])

    useEffect(() => {
        if (!inOutHistoryFilters?.type) return
        setInOutHistoryFilters(current => {
            var filter = {}
            if (current?.status) filter['status'] = current.status
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setInOutItemCategories2([])
        setInOutItemCategories3([])
        setInOutItemCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1='+inOutHistoryFilters?.type, setInOutItemCategories1)
    }, [inOutHistoryFilters?.type])

    useEffect(() => {
        if (!inOutHistoryFilters?.cat1) return
        setInOutHistoryFilters(current => {
            var filter = {}
            if (current?.status) filter['status'] = current.status
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setInOutItemCategories3([])
        setInOutItemCategories4([])

        var cat1 = inOutHistoryFilters?.cat1.substring(inOutHistoryFilters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1='+inOutHistoryFilters?.type+'&codeLev2='+cat1, setInOutItemCategories2)
    }, [inOutHistoryFilters?.cat1])

    useEffect(() => {
        if (!inOutHistoryFilters?.cat2) return
        setInOutHistoryFilters(current => {
            var filter = {}
            if (current?.status) filter['status'] = current.status
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });
        
        setInOutItemCategories4([])

        var cat1 = inOutHistoryFilters?.cat1.substring(inOutHistoryFilters?.type.length)
        var cat2 = inOutHistoryFilters?.cat2.substring(inOutHistoryFilters?.cat1.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1='+inOutHistoryFilters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2, setInOutItemCategories3)
    }, [inOutHistoryFilters?.cat2])

    useEffect(() => {
        if (!inOutHistoryFilters?.cat3) return
        setInOutHistoryFilters(current => {
            var filter = {}
            if (current?.status) filter['status'] = current.status
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = inOutHistoryFilters?.cat1.substring(inOutHistoryFilters?.type.length)
        var cat2 = inOutHistoryFilters?.cat2.substring(inOutHistoryFilters?.cat1.length)
        var cat3 = inOutHistoryFilters?.cat3.substring(inOutHistoryFilters?.cat2.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1='+inOutHistoryFilters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2+'&codeLev4='+cat3, setInOutItemCategories4)
    }, [inOutHistoryFilters?.cat3])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!zipCode)
        {
            setError('Enter choose an address')
            return
        }

        setIsLoading(true)
        
        var formData = {
            facilityId: id,
            name: name,
            classCd: type?.value,
            zipCd : zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            area1Cd: area1?.value,
            area2Cd: area2?.value,
            lat: latitude,
            lot: longitude,
            deptCharge: deptIncharge,
            telno: phoneNumber,
            faxno: faxNumber,
            reprMgrTelno: repPhoneNumber,
            reprMgrName: repName,
            ownerTelno: ownerPhoneNumber,
            ownerName: ownerName,
            xcord: coordinateX,
            ycord: coordinateY,
            ...otherFields,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/updateFAC', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleSupplyHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setSupplyHistoryParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...(supplyHistoryFilters?.type ? {kindCd: supplyHistoryFilters?.type} : null),
            ...(supplyHistoryFilters?.cat1 ? {cat1Cd: supplyHistoryFilters?.cat1.substring(supplyHistoryFilters?.type.length)} : null),
            ...(supplyHistoryFilters?.cat2 ? {cat2Cd: supplyHistoryFilters?.cat2.substring(supplyHistoryFilters?.cat1.length)} : null),
            ...(supplyHistoryFilters?.cat3 ? {cat3Cd: supplyHistoryFilters?.cat3.substring(supplyHistoryFilters?.cat2.length)} : null),
            ...(supplyHistoryFilters?.cat4 ? {cat4Cd: supplyHistoryFilters?.cat4.substring(supplyHistoryFilters?.cat3.length)} : null),
        }))
    }

    const initializeSupplyHistoryFilter = () => {
        setSupplyHistoryFilters({});
        setSupplyHistoryParameters(current => ({facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    const handleSupplySaveExcel = () => {
        var getParameters = supplyHistoryParameters ? new URLSearchParams(supplyHistoryParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expSplyLst?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const handleInOutHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setInOutHistoryParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...(inOutHistoryFilters?.status ? {status: inOutHistoryFilters?.status} : null),
            ...(inOutHistoryFilters?.type ? {kindCd: inOutHistoryFilters?.type} : null),
            ...(inOutHistoryFilters?.cat1 ? {cat1Cd: inOutHistoryFilters?.cat1.substring(inOutHistoryFilters?.type.length)} : null),
            ...(inOutHistoryFilters?.cat2 ? {cat2Cd: inOutHistoryFilters?.cat2.substring(inOutHistoryFilters?.cat1.length)} : null),
            ...(inOutHistoryFilters?.cat3 ? {cat3Cd: inOutHistoryFilters?.cat3.substring(inOutHistoryFilters?.cat2.length)} : null),
            ...(inOutHistoryFilters?.cat4 ? {cat4Cd: inOutHistoryFilters?.cat4.substring(inOutHistoryFilters?.cat3.length)} : null),
        }))
    }

    const initializeInOutHistoryFilter = () => {
        setInOutHistoryFilters({});
        setInOutHistoryParameters(current => ({facilityId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    const handleInOutSaveExcel = () => {
        var getParameters = inOutHistoryParameters ? new URLSearchParams(inOutHistoryParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expSplyHistXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }
    
    const handleImageChange = (e) => {
        var file = e.target.files[0];
        if (!file) return

        setIsLoadingImage(true)

        var formData = new FormData();
        formData.append("facilityId", id);
        formData.append("addFile", file);
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/addPhoto', { 
            method: 'POST',
            body: formData,
            headers: { 
                //"Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                var reader = new FileReader();
                var url = reader.readAsDataURL(file);
                reader.onloadend = function (e) {
                    setImage(reader.result)
                    if (uploadFileRef?.current)
                    {
                        uploadFileRef.current.value = '';
                    }
                }
            }
            else
            {
                if (uploadFileRef?.current)
                {
                    uploadFileRef.current.value = '';
                }
            }
            setIsLoadingImage(false);
        })
        .catch(error => {
            setIsLoadingImage(false);
            if (uploadFileRef?.current)
            {
                uploadFileRef.current.value = '';
            }
        })
    }

    const handleDelete = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)
        
        var formData = {
            facilityId: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/delFAC', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
                history('/warehouse-management')
                /*
                setDisasterType1(null)
                setDisasterType2(null)
                setName('')
                setMemo('')
                setStartDate('')
                setOccurBasisUrl('')
                setSelectedAreas([])
                */
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호물품</h3>
                <Link to={'/inventory-management'} className="sidebar-link">구호물품 재고 관리</Link>
                <Link to={'/warehouse-management'} className="sidebar-link active">비축창고 등록/관리</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">구호물품 입고 내역</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">구호물품 출고 내역</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">비축창고 상세보기</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">{updateDt} 수정</p>
                            <Link to="/warehouse-management" className="dms-btn dms-btn-s dms-btn-outline">닫기</Link>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="상세정보">
                            <form onSubmit={handleSubmit}>
                                <div className="d-flex justify-content-end align-items-center mb-20px">
                                    <button className="dms-btn dms-btn-s dms-btn-outline mr" onClick={handleDelete}>삭제</button>
                                    <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoading ? 'disabled' : ''}`}>수정</button>
                                </div>
                                
                                <div className="white-rounded-container mb-20px">
                                    {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                                    {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                                    <div className="form-heading"><h5>상세정보</h5></div>
                                    <div className="row">
                                        <div className="col-lg-9">
                                            <div className="form-group dms-input-container">
                                                <div className="dms-form-label align-self-center">비축창고명<span className="label-ast">*</span></div>
                                                <div className="dms-input-row">
                                                    <div className="dms-input-6">
                                                        <FormControl
                                                            onChange={e => setName(e.target.value)}
                                                            value={name}
                                                            placeholder="Please enter a name"
                                                            required={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group dms-input-container">
                                                <div className="dms-form-label align-self-center">기관구분<span className="label-ast">*</span></div>
                                                <div className="dms-input-row">
                                                    <div className="dms-input-6">
                                                        <ReactSelect
                                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                            classNamePrefix="select"
                                                            isSearchable={false}
                                                            required={true}
                                                            placeholder={'Select'}
                                                            value={type ?? null}
                                                            onChange={(e) => setType(e)}
                                                            options={facilityTypes}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group dms-input-container mb-2">
                                                <div className="dms-form-label align-self-center">비축창고 주소<span className="label-ast">*</span></div>
                                                <div className="dms-input-row">
                                                    <div className="dms-input-6">
                                                        <FormControl
                                                            value={zipCode}
                                                            placeholder="Please choose address"
                                                            disabled={true} />
                                                    </div>
                                                    <div className="dms-input-6">
                                                        <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group dms-input-container mb-2">
                                                <div className="dms-form-label"></div>
                                                <div className="dms-input-row">
                                                    <div className="dms-input-12">
                                                        <FormControl
                                                            value={mainAddress}
                                                            placeholder="Please choose address"
                                                            disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group dms-input-container">
                                                <div className="dms-form-label"></div>
                                                <div className="dms-input-row">
                                                    <div className="dms-input-12">
                                                        <FormControl
                                                            onChange={e => setDetailedAddress(e.target.value)}
                                                            value={detailedAddress}
                                                            placeholder="Enter detailed address"
                                                            required={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group dms-input-container">
                                                <div className="dms-form-label align-self-center">지역</div>
                                                <div className="dms-input-row">
                                                    <div className="dms-input-3">
                                                        <ReactSelect
                                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                            classNamePrefix="select"
                                                            isSearchable={false}
                                                            placeholder={'Select Province'}
                                                            value={area1 ?? null}
                                                            onChange={(e) => setArea1(e)}
                                                            options={areas1}
                                                        />
                                                    </div>
                                                    <div className="dms-input-3">
                                                        <ReactSelect
                                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                            classNamePrefix="select"
                                                            isSearchable={false}
                                                            placeholder={'Select District'}
                                                            value={area2 ?? null}
                                                            onChange={(e) => setArea2(e)}
                                                            options={areas2}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="picture-preview-box">
                                                {image ? <img src={image} /> : <span>사진 없음</span>}
                                                <div className="overlay">
                                                    <label htmlFor="upload-file" className="dms-btn dms-btn-s dms-btn-outline align-self-center">
                                                        {image ? 'Photo change' : 'Upload'}
                                                        <input ref={uploadFileRef} type="file" disabled={isLoadingImage} accept="image/jpg,image/jpeg,image/png" id="upload-file" hidden onChange={handleImageChange}/>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">좌표</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setCoordinateX(e.target.value)}
                                                    value={coordinateX}
                                                    placeholder="X: 129308.1283" />
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setCoordinateY(e.target.value)}
                                                    value={coordinateY}
                                                    placeholder="Y: 129308.1283" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">위도/경도</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setLatitude(e.target.value)}
                                                    value={latitude}
                                                    placeholder="위도: 12.1283" />
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setLongitude(e.target.value)}
                                                    value={longitude}
                                                    placeholder="경도: 129.1283" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">담당부서</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setDeptIncharge(e.target.value)}
                                                    value={deptIncharge}
                                                    placeholder="부서명을 입력하세요." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">대표전화</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                    placeholder="전화번호를 입력하세요." />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">팩스번호</div>
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setFaxNumber(e.target.value)}
                                                    value={faxNumber}
                                                    placeholder="팩스번호를 입력하세요." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">대표 책임자<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setRepName(e.target.value)}
                                                    value={repName}
                                                    placeholder="책임자명을 적어주세요."
                                                    required={true} />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">책임자 연락처</div>
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setRepPhoneNumber(e.target.value)}
                                                    value={repPhoneNumber}
                                                    placeholder="휴대전화 번호를 입력하세요.." />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">시설 소유자<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setOwnerName(e.target.value)}
                                                    value={ownerName}
                                                    placeholder="이름을 입력하세요."
                                                    required={true} />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">소유자 연락처<span className="label-ast">*</span></div>
                                            </div>
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setOwnerPhoneNumber(e.target.value)}
                                                    value={ownerPhoneNumber}
                                                    placeholder="휴대전화 번호를 입력하세요."
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="tab2" title="구호물품 재고현황">
                            <form onSubmit={handleSupplyHistoryFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">물품 종류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={supplyHistoryFilters?.type ? itemTypes.find(value => value.value == supplyHistoryFilters?.type) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, type: e.value}))}
                                                    isSearchable={false}
                                                    options={itemTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">물품 분류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={supplyHistoryFilters?.cat1 ? supplyItemCategories1.find(value => value.value == supplyHistoryFilters?.cat1) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat1: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories1}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={supplyHistoryFilters?.cat2 ? supplyItemCategories2.find(value => value.value == supplyHistoryFilters?.cat2) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat2: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories2}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={supplyHistoryFilters?.cat3 ? supplyItemCategories3.find(value => value.value == supplyHistoryFilters?.cat3) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat3: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories3}noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={supplyHistoryFilters?.cat4 ? supplyItemCategories4.find(value => value.value == supplyHistoryFilters?.cat4) : null}
                                                    onChange={(e) => setSupplyHistoryFilters(current => ({...current, cat4: e.value}))}
                                                    isSearchable={false}
                                                    options={supplyItemCategories4}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeSupplyHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                </div>
                            </form>

                            {supplyHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {supplyHistoryTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { supplyFetchError ? <p className="search-result text-orange">{supplyFetchError}</p> : null }
                                            { supplyFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                supplyHistoryTotalCount !== null ? <p className="search-result">검색 결과 총 <span>{supplyHistoryTotalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{label: supplyHistoryParameters.reqPageSize + '개씩 보기'}}
                                                onChange={(e) => setSupplyHistoryParameters(current => ({...current, reqPageSize: e.value}))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: '10개씩 보기' },
                                                    { value: 20, label: '20개씩 보기' },
                                                    { value: 30, label: '30개씩 보기' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSupplySaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> 엑셀저장</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            { supplyHistoryTotalCount !== null && supplyHistoryTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>물품 종류</th>
                                            <th>물품 분류 1</th>
                                            <th>물품 분류 2</th>
                                            <th>물품 분류 3</th>
                                            <th>물품 분류 4</th>
                                            <th>재고</th>
                                            <th>상세보기</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {supplyHistories.map(item => (
                                            <tr key={item.splyId}>
                                                <td>{item.kind}</td>
                                                <td>{item.cat1}</td>
                                                <td>{item.cat2}</td>
                                                <td>{item.cat3}</td>
                                                <td>{item.cat4}</td>
                                                <td>{item.supplyCount}</td>
                                                <td style={{ width: "120px" }}><Link to={"/inventory-details/"+item.splyId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">상세보기</Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {supplyHistoryTotalCount !== null && supplyHistoryTotalCount !== 0 && Math.ceil(supplyHistoryTotalCount/supplyHistoryParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setSupplyHistoryParameters(current => ({...current, reqPageNo: e.selected+1}))}
                                    forcePage={Math.max(supplyHistoryParameters.reqPageNo-1, 0)}
                                    pageCount={Math.ceil(supplyHistoryTotalCount/supplyHistoryParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab3" title="입/출고 내역">
                            <form onSubmit={handleInOutHistoryFilterSubmit} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">상태</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-3">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={inOutHistoryFilters?.status ? optionsInOutStatus.find(value => value.value == inOutHistoryFilters?.status) : null}
                                                    onChange={(e) => setInOutHistoryFilters(current => ({...current, status: e.value}))}
                                                    isSearchable={false}
                                                    options={optionsInOutStatus}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">물품 종류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={inOutHistoryFilters?.type ? itemTypes.find(value => value.value == inOutHistoryFilters?.type) : null}
                                                    onChange={(e) => setInOutHistoryFilters(current => ({...current, type: e.value}))}
                                                    isSearchable={false}
                                                    options={itemTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">물품 분류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={inOutHistoryFilters?.cat1 ? inOutItemCategories1.find(value => value.value == inOutHistoryFilters?.cat1) : null}
                                                    onChange={(e) => setInOutHistoryFilters(current => ({...current, cat1: e.value}))}
                                                    isSearchable={false}
                                                    options={inOutItemCategories1}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={inOutHistoryFilters?.cat2 ? inOutItemCategories2.find(value => value.value == inOutHistoryFilters?.cat2) : null}
                                                    onChange={(e) => setInOutHistoryFilters(current => ({...current, cat2: e.value}))}
                                                    isSearchable={false}
                                                    options={inOutItemCategories2}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={inOutHistoryFilters?.cat3 ? inOutItemCategories3.find(value => value.value == inOutHistoryFilters?.cat3) : null}
                                                    onChange={(e) => setInOutHistoryFilters(current => ({...current, cat3: e.value}))}
                                                    isSearchable={false}
                                                    options={inOutItemCategories3}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    className="dropdown-sm mr"
                                                    placeholder={"전체"}
                                                    value={inOutHistoryFilters?.cat4 ? inOutItemCategories4.find(value => value.value == inOutHistoryFilters?.cat4) : null}
                                                    onChange={(e) => setInOutHistoryFilters(current => ({...current, cat4: e.value}))}
                                                    isSearchable={false}
                                                    options={inOutItemCategories4}
                                                    noOptionsMessage={() => '데이터 없음'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeInOutHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                </div>
                            </form>

                            {inOutHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {inOutHistoryTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { inOutFetchError ? <p className="search-result text-orange">{inOutFetchError}</p> : null }
                                            { inOutFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                inOutHistoryTotalCount !== null ? <p className="search-result">검색 결과 총 <span>{inOutHistoryTotalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{label: inOutHistoryParameters.reqPageSize + '개씩 보기'}}
                                                onChange={(e) => setInOutHistoryParameters(current => ({...current, reqPageSize: e.value}))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: '10개씩 보기' },
                                                    { value: 20, label: '20개씩 보기' },
                                                    { value: 30, label: '30개씩 보기' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleInOutSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> 엑셀저장</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            { inOutHistoryTotalCount !== null && inOutHistoryTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>처리일시</th>
                                            <th>물품종류</th>
                                            <th>물품분류</th>
                                            <th>보낸 곳</th>
                                            <th>보낸 곳 주소</th>
                                            <th>받는 곳</th>
                                            <th>받는 곳 주소</th>
                                            <th>상태</th>
                                            <th>수량</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inOutHistories.map(item => (
                                            <tr key={item.eventDt+item.recvFacAddress}>
                                                <td>{item.eventDt}</td>
                                                <td>{item.itemTypeName}</td>
                                                <td>{item.itemName}</td>
                                                <td>{item.sendFacName}</td>
                                                <td>{item.sendFacAddress}</td>
                                                <td>{item.recvFacName}</td>
                                                <td>{item.recvFacAddress}</td>
                                                <td className={item.status == 'Unstoring' ? 'text-orange' : 'text-green'}>{item.status}</td>
                                                <td className={item.status == 'Unstoring' ? 'text-orange' : 'text-green'}>{item.status == 'Unstoring' ? '-' : '+'}{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {inOutHistoryTotalCount !== null && inOutHistoryTotalCount !== 0 && Math.ceil(inOutHistoryTotalCount/inOutHistoryParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setInOutHistoryParameters(current => ({...current, reqPageNo: e.selected+1}))}
                                    forcePage={Math.max(inOutHistoryParameters.reqPageNo-1, 0)}
                                    pageCount={Math.ceil(inOutHistoryTotalCount/inOutHistoryParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                    </Tabs>
                </div>
                    
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />

            <SaveExcelModal handleSaveExcel={handleSupplySaveExcel} isVisible={isVisibleSupplySaveExcel} setIsVisible={setIsVisibleSupplySaveExcel} />
            <SaveExcelModal handleSaveExcel={handleInOutSaveExcel} isVisible={isVisibleInOutSaveExcel} setIsVisible={setIsVisibleInOutSaveExcel} />
        </div>
    );
}
 
export default WarehouseDetails;