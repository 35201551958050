import { useState } from "react";
import { Modal, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { SlBell } from "react-icons/sl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { store } from "../redux/store";
import { removeUser } from "../redux/userSlice";
import { useSelector } from "react-redux";

const victimsScreens = [ 'victim-management', 'victim-details', 'victim-register', 'find-missing-children', 'register-missing-children', 'find-missing-children-details', 'find-missing-parent-details', 'edit-missing-children', 'edit-missing-parent' ]
const reliefScreens = [ 'inventory-management', 'inventory-details', 'transfer-supplies', 'warehouse-management', 'warehouse-details', 'warehouse-register', 'incoming-supplies', 'outgoing-supplies', 'requests-for-supplies', 'register-for-supplies', 'requests-for-supplies-details-1', 'requests-for-supplies-details-2' ]
const shelterScreens = [ 'shelter-management', 'shelter-register', 'shelter-details', 'shelter-supplies-register', 'shelter-volunteer-register', 'shelter-inquiry-board', 'shelter-inquiry-details-1', 'shelter-inquiry-details-2', 'shelter-inquiry-details-3' ]
const volunteerScreens = [ 'volunteer-management', 'volunteer-details', 'volunteer-register', 'volunteer-assignment', 'volunteer-requests', 'volunteer-requests-details-1', 'volunteer-requests-details-2', 'volunteer-requests-register' ]
const systemScreens = [ 'statistics', 'user-management', 'user-register', 'user-details', 'disaster-management', 'disaster-register', 'disaster-details', 'notices-news', 'notices-news-register', 'notices-news-details', 'notices-news-preview' ]

const Header = () => {
    let navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    
    const currentPath = useLocation().pathname.split('/')[1];
    const [isVisibleLogout, setIsVisibleLogout] = useState(false)

    const handleLogout = (e) => {
        e.preventDefault()
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/logout?userId='+user?.userId, {
            method: 'POST',
            // headers: {
            //     "Authorization": "Bearer "+localStorage.getItem("token")
            // }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('There was some error while logging in, please try again.');
            }
            
            return response.json();
        })
        store.dispatch(removeUser());
        navigate('/login');
    }

    return (
        <>
            <Navbar expand="xl">
                <div className="container-fluid">
                    <Navbar.Brand as={Link} to={'/'}><img src="/images/logo.png"/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Nav.Link as={Link} to={'/'} className="mx-xl-2">대시보드</Nav.Link>
                            <NavDropdown className={`mx-xl-2 ${victimsScreens.includes(currentPath) ? 'active' : ''}`} title="이재민" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/victim-management'}>이재민 등록/관리</NavDropdown.Item>
                               {/* <NavDropdown.Item as={Link} to={'/find-missing-children'}>미아/부모찾기</NavDropdown.Item>*/}
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${shelterScreens.includes(currentPath) ? 'active' : ''}`} title="구호소" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/shelter-management'}>구호소 등록/관리</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/shelter-inquiry-board'}>구호소 문의게시판</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${reliefScreens.includes(currentPath) ? 'active' : ''}`} title="구호물품" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/inventory-management'}>구호물품 재고 관리</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/warehouse-management'}>비축창고 등록/관리</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/incoming-supplies'}>구호물품 입고 내역</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/outgoing-supplies'}>구호물품 출고 내역</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${volunteerScreens.includes(currentPath) ? 'active' : ''}`} title="자원봉사자" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/volunteer-management'}>자원봉사자 등록/관리</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/volunteer-assignment'}>자원봉사자 투입 현황</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className={`mx-xl-2 ${systemScreens.includes(currentPath) ? 'active' : ''}`} title="시스템 관리" id="basic-nav-dropdown" renderMenuOnMount={true}>
                                <NavDropdown.Item as={Link} to={'/statistics'}>통계자료</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/user-management'}>사용자 등록/관리</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/disaster-management'}>재난코드 등록/관리</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to={'/notices-news'}>공지사항</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#home">{user?.userName}</Nav.Link>
                            <Nav.Link onClick={() => setIsVisibleLogout(true)} className="p-0 mx-xl-2 my-1"><div className="header-logout">로그아웃</div></Nav.Link>
                            {/* <Nav.Link href="#home" className="header-badge"><SlBell size="24px" /><div className="badge-count">0</div></Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>

            <Modal show={isVisibleLogout} onHide={() => setIsVisibleLogout(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>로그아웃</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>정말 로그아웃 하시겠습니까?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleLogout(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">취소</button>
                    <button onClick={handleLogout} className="dms-btn dms-btn-xs dms-btn-filled-dark">로그아웃</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Header;
