import { useRef, useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";

const RegisterForSupplies = () => {
    const uploadFileRef = useRef()

    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [image, setImage] = useState(false)

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호물품</h3>
                <Link to={'/inventory-management'} className="sidebar-link">구호물품 재고 관리</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">비축창고 등록/관리</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">구호물품 입고 내역</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">구호물품 출고 내역</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">구호물품 요청하기</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-s dms-btn-outline mr">닫기</button>
                            <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">등록</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>상세정보</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">요청 담당자<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">담당 구호소<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'전체'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        options={[
                                            { value: 'Ulsan', label: 'Ulsan'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Citywide'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Citywide', label: 'Citywide'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Select the shelter or warehouse'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Select the shelter or warehouse', label: 'Select the shelter or warehouse'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">담당자 연락처</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl placeholder="Please enter a name" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">물품 종류<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category', label: 'Category'},
                                            { value: 'Female', label: 'Female'},
                                            { value: 'External', label: 'External'},
                                            { value: 'Facility', label: 'Facility'},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">물품 분류<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 1'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 1', label: 'Category 1' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 2'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 2', label: 'Category 2' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 3'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 3', label: 'Category 3' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Category 4'}
                                        isSearchable={false}
                                        options={[
                                            { value: 'Category 4', label: 'Category 4' },
                                            { value: 'Test', label: 'Test' },
                                            { value: 'Test Test', label: 'Test Test' }
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">요청 수량<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <div className="input-group">
                                        <FormControl placeholder="Enter quantity" />
                                        <span className="input-group-text">EA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-1">요청 상세설명</div>
                            <div className="dms-input-row">
                                <div className="dms-input-12">
                                    <FormControl as="textarea" rows="8" />  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>파일첨부</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-2">사진 업로드</div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Please enter a name" />
                                    <div className="input-helper">최대 1장의 이미지 업로드 가능. 업로드 가능한 확장자: .JPG, .JPEG, .PNG</div>
                                </div>
                                <div className="dms-input-4">
                                    <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />파일 찾기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default RegisterForSupplies;