import { useRef, useState, useEffect } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";
import useFetch from "../useFetch";
import { useSelector } from "react-redux";

const ShelterInquiryDetails1 = () => {
    const uploadFileRef = useRef()

    const { id } = useParams();
    const { user } = useSelector(state => state.user);

    //const apiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getInq?boardId=' + id;
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/fac/getInq?boardId='+id)


    const [typeCd, setTypeCd] = useState(null)
    const [facilityId, setFacilityId] = useState(null)
    const [subject, setSubject] = useState(null)
    const [area1Cd, setArea1Cd] = useState(null)
    const [area2Cd, setArea2Cd] = useState(null)

    const [city, setCity] = useState(null)
    const [dist, setDist] = useState(null)

    const [facilityName, setFacilityName] = useState(null)
    const [questionMemo, setQuestionMemo] = useState(null)
    const [answerMemo, setAnswerMemo] = useState(null)
    const [statusCd, setStatusCd] = useState(null)
    const [status, setStatus] = useState(null)


    const [typeName, setTypeName] = useState(null)
    const [writerName, setWriterName] = useState(null)
    const [writerId, setWriterId] = useState(null)
    const [answererName, setAnswererName] = useState(null)
    const [answererId, setAnswererId] = useState(null)
    const [createDt, setCreateDt] = useState(null)
    const [updateDt, setUpdateDt] = useState(null)


    const [inqueryTypes, setinqueryTypes] = useState([])    // 문의 타입
    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D007&codeDepth=1', setinqueryTypes)
    }, [])

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setTypeCd(fetchResponse?.resultObject?.typeCd);
            setFacilityId(fetchResponse?.resultObject?.facilityId);
            setSubject(fetchResponse?.resultObject?.subject);
            setArea1Cd(fetchResponse?.resultObject?.area1Cd);
            setArea2Cd(fetchResponse?.resultObject?.area2Cd);

            setCity(fetchResponse?.resultObject?.city);
            setDist(fetchResponse?.resultObject?.dist);

            setFacilityName(fetchResponse?.resultObject?.facilityName);
            setQuestionMemo(fetchResponse?.resultObject?.questionMemo);
            setAnswerMemo(fetchResponse?.resultObject?.answerMemo);

            setStatusCd(fetchResponse?.resultObject?.statusCd);
            setStatus(fetchResponse?.resultObject?.status);
            setTypeName(fetchResponse?.resultObject?.typeName);
            setWriterName(fetchResponse?.resultObject?.writerName);
            setAnswererName(fetchResponse?.resultObject?.answererName);

            setAnswererId(fetchResponse?.resultObject?.answererId);
            setCreateDt(fetchResponse?.resultObject?.createDt);
            setUpdateDt(fetchResponse?.resultObject?.updateDt);                                    
        }
    }, [fetchResponse])



    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [image, setImage] = useState(false)


    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        setIsLoading(true)
        
        var formData = {
            boardId: id,
            answerMemo: answerMemo,
            answererId: user.userId,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/updateInq', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
                setApiUrl(process.env.REACT_APP_BASE_API_URL+'/fac/getInq?boardId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호소</h3>
                <Link to={'/shelter-management'} className="sidebar-link">구호소 등록/관리</Link>
                <Link to={'/shelter-inquiry-board'} className="sidebar-link active">구호소 문의게시판</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">구호소 문의</h3>
                    </div>

                    <div className="white-rounded-container mb-20px">

                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>문의내용</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">문의타입</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Admission Support'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        value={inqueryTypes ? inqueryTypes.find(value => value.value === typeCd) ?? null : null}
                                        options={inqueryTypes}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">구호소<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={city}
                                        isSearchable={false}
                                        isDisabled={true}
                                        value={city}
                                        options={[]}
                                    />
                                </div>
                                <div className="dms-input-2">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={dist}
                                        isSearchable={false}
                                        isDisabled={true}
                                        value={dist}
                                        options={[]}

                                    />
                                </div>
                                <div className="dms-input-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={facilityName}
                                        isSearchable={false}
                                        isDisabled={true}
                                        value={facilityName}
                                        options={[]}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">요청자<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl value={writerName} placeholder="Please enter a name" disabled />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-1">문의내용</div>
                            <div className="dms-input-row">
                                <div className="dms-input-12">
                                    <FormControl value={questionMemo} as="textarea" rows="8" disabled />  
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">

                        
                        <form onSubmit={handleSubmit}>
                            <div className="form-heading mb-0 d-flex justify-content-between align-items-center">
                                <h5>답변</h5>
                                <div className="flex-fill d-flex justify-content-end align-items-center">
                                    {/*<button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>*/}
                                    <button type="submit" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active">{statusCd === '01' ? '답변 등록하기' : '답변 수정하기' }</button>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">답변자<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl value={user?.userName} placeholder="Please enter a name" disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label mt-1">답변내용</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-12">
                                        <FormControl as="textarea" value={answerMemo} rows="8" onChange={e => setAnswerMemo(e.target.value)} />  
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                    
                <Footer />
            </div>
        </div>
    );
}
 
export default ShelterInquiryDetails1;