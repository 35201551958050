import { Modal } from "react-bootstrap";

const DeleteModalWeb = ({isVisible, setIsVisible, handleDelete = null, modalTitle = null, modalBody = null, primaryButton='삭제'}) => {
    const handleYes = () => {
        setIsVisible(false)
        if (handleDelete) handleDelete()
    }

    return (
        <Modal show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle ?? '삭제 확인'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{modalBody ?? '정말로 삭제하시겠습니까?'}</p>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => setIsVisible(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">취소</button>
                <button onClick={handleYes} className="dms-btn dms-btn-xs dms-btn-filled">{primaryButton}</button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default DeleteModalWeb;