import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class GoogleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      mapCenter: { lat: 37.5665, lng: 126.9780 }, // Default center for Seoul
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState({
          address,
          mapCenter: latLng,
        });
      })
      .catch((error) => console.error('Error', error));
  };

  render() {
    const mapStyles = {
      width: '100%',
      height: '100%',
    };

    const locations = [
      { lat: 37.5665, lng: 126.9779, title: '서울 시청' },  // 'Seoul City Hall' in Korean
      { lat: 37.5659, lng: 126.9836, title: '뉴 서울 3성 호텔' },  // 'New Seoul 3-star Hotel' in Korean
      { lat: 37.5685, lng: 126.9834, title: '코리아나 4성 호텔' },  // 'Koreana 4-star Hotel' in Korean
    ];

    const customSvg = `data:image/svg+xml;utf-8,${encodeURIComponent(`
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
    <g>
      <circle cx="24" cy="24" r="20" fill="#ff1e00" opacity="1" />
      <path fill="#f01e00" d="M24 4v40c11.046 0 20-8.954 20-20S35.046 4 24 4z" opacity="1" />
      <path fill="#640008" d="M33.93 30.63C33.84 30.4 31.59 25 24 25s-9.84 5.4-9.93 5.63a1 1 0 0 0 .55 1.29 1.001 1.001 0 0 0 1.309-.536l.001-.004C16 31.2 17.81 27 24 27s8 4.18 8.07 4.38A1 1 0 0 0 33 32a1 1 0 0 0 .931-1.368l-.001-.002z" opacity="1" />
      <path fill="#500008" d="M33.93 30.63C33.84 30.4 31.59 25 24 25v2c6.18 0 8 4.18 8.07 4.38A1 1 0 0 0 33 32a1 1 0 0 0 .931-1.368l-.001-.002z" opacity="1" />
      <path fill="#640008" d="M10 15.7c.2-.5.7-.8 1.2-.7l10 3c.639.196.8.7.7 1.2-.1.4-.531.779-1 .7-.136-.023-.9-.2-.9-.2-.1 1.3-1.2 2.3-2.5 2.3-1.4 0-2.5-1.1-2.5-2.5 0-.4.1-.8.3-1.2L10.7 17c-.5-.2-.8-.8-.7-1.3z" opacity="1" />
      <path fill="#500008" d="m37.3 17-4.6 1.4c.2.3.3.8.3 1.2 0 1.4-1.1 2.5-2.5 2.5-1.3 0-2.4-1-2.5-2.3 0 0-.792.156-.891.166-.479.051-.875-.33-.97-.662-.141-.568.133-1.015.563-1.199L36.7 15.1c.5-.2 1.1.1 1.2.7.2.4-.1 1-.6 1.2z" opacity="1" />
    </g>
  </svg>
    `)}`;

    return (
      <div>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className='map-searchbox'>
              <input className='map-input-box'  {...getInputProps({ placeholder: 'Search for shelter names' })} />

              <div>
                {loading ? <div>Loading...</div> : null}
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                  };
                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <Map
          google={this.props.google}
          zoom={17}
          style={mapStyles}
          initialCenter={this.state.mapCenter}
          language='ko'  // Set language to Korean
        >
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.lat, lng: location.lng }}
              title={location.title}
              icon={{
                url: customSvg,
                anchor: new this.props.google.maps.Point(24, 24),
                scaledSize: new this.props.google.maps.Size(48, 48),
              }}
            />
          ))}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD9HxrF-U5D0gdheO23QBuBh0LGeFiX_Z8',
})(GoogleMap);
