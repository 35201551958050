import React, { useState, useEffect } from 'react';
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoBandage, IoChevronUp, IoClose, IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link, useParams } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import { IoMdHeart } from "react-icons/io";
import { FaWheelchair } from "react-icons/fa6";
import useFetch from '../useFetch';
import { useSelector } from 'react-redux';

const AdminShelterBelongVictimsInShelterDetails = () => {
    const { id } = useParams();
    const { user } = useSelector(state => state.user);

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/victim/getVCTMInfo?userId='+id;
    
    const [data, setData] = useState({})
    const [specialNotes, setSpecialNotes] = useState([])
    const [infants, setInfants] = useState([])

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setData(fetchResponse?.resultObject)
            setSpecialNotes(fetchResponse?.resultObject?.victimNoteList)
            setInfants(fetchResponse?.resultObject?.infantsList)
        }
    }, [fetchResponse])

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">이재민 상세정보</div>
                    <Link to="/shelter-admin-victims-in-shelter-belong-m" className="mobile-navbar-right plain-link">
                        <img src="/images/icons/close.svg" />
                    </Link>
                </div>
            </div>

            <div className="content-container container flex-fill pt-0 pb-4">
                <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                    <p className="text-center fw-bold fs-l mb-1">{data?.name}</p>
                    <p className="text-center text-gray-03 fs-xxs mb-n2">{data?.updateDt ?? data?.createDt}</p>
                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                        {(data.noteCdList ?? []).includes('03') ? (
                            <div className="mobile-card mx-1 py-1 px-2 d-flex align-items-center">
                                <span className="victim-tag small-tag tag-red"><IoMdHeart size="12px" /></span>
                                <p className="mb-0 ms-1 fs-xxs">임신</p>
                            </div>
                        ) : null}

                        {(data.noteCdList ?? []).includes('01') ? (
                            <div className="mobile-card mx-1 py-1 px-2 d-flex align-items-center">
                                <span className="victim-tag small-tag tag-green"><IoBandage size="12px" /></span>
                                <p className="mb-0 ms-1 fs-xxs">질병</p>
                            </div>
                        ) : null}

                        {(data.noteCdList ?? []).includes('02') ? (
                            <div className="mobile-card mx-1 py-1 px-2 d-flex align-items-center">
                                <span className="victim-tag small-tag tag-blue"><FaWheelchair size="12px" /></span>
                                <p className="mb-0 ms-1 fs-xxs">장애</p>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="mobile-card">
                    <div className="form-heading"><h5>개인정보</h5></div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0 text-gray-03">성명</p>
                        <p className="mb-0 text-end flex-fill">{data?.name}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0 text-gray-03">아이디</p>
                        <p className="mb-0 text-end flex-fill">{data?.userId}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0 text-gray-03">주민등록번호</p>
                        <p className="mb-0 text-end flex-fill">{data?.regNo}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0 text-gray-03">연락처</p>
                        <p className="mb-0 text-end flex-fill">{data?.telNo}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0 text-gray-03">주소</p>
                        <p className="mb-0 text-end flex-fill">{data?.wholeAddress}</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between my-2">
                        <p className="mb-0 text-gray-03">세대주</p>
                        <p className="mb-0 text-end flex-fill">{data?.hshdYn == 'Y' ? '세대주' : '세대주 아님'} / {data?.hshdName}</p>
                    </div>
                </div>
                
                {specialNotes.length > 0 ? (
                    <div className="mobile-card">
                        <div className="form-heading"><h5>특이사항</h5></div>
                        {specialNotes.map(note => (
                            <div>
                                <p className="mb-0 fw-bold mb-1 mt-3">{note.noteCdText}</p>
                                <p className="mb-0 text-gray-04">{note.symptom}</p>
                            </div>
                        ))}
                    </div>
                ) : null}

                {infants.length > 0 ? (
                    <div className="mobile-card">
                        <div className="form-heading"><h5>영유아</h5></div>
                        {infants.map(infant => (
                            <div key={infant.infantId}>
                                <p className="mb-0 fw-bold mb-1 mt-3">{infant.name}</p>
                                <p className="mb-0 text-gray-04"> {infant.regNo}</p>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <div className="container content-container pt-0">
                <Link to="/shelter-admin-victims-in-shelter-belong-m" className="dms-btn dms-btn-to-list-mobile dms-btn-outline w-100 px-0 align-self-center">돌아가기</Link>
            </div>
        </div>
    );
}

export default AdminShelterBelongVictimsInShelterDetails;