import { useEffect, useState } from "react";
import { Alert, FormControl, FormCheck } from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";
import { IoClose } from "react-icons/io5";

const VictimRegisterMobile = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1)
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isAdd1, setIsAdd1] = useState(false)
    const [isAdd2, setIsAdd2] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [socialNumber, setSocialNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [headHousehold, setHeadHousehold] = useState('')
    const [specialNotes, setSpecialNotes] = useState([])
    const [infants, setInfants] = useState([])

    const [specialNoteCategory, setSpecialNoteCategory] = useState(null)
    const [specialNoteSymptom, setSpecialNoteSymptom] = useState('')
    const [infantName, setInfantName] = useState('')
    const [infantSocialNumber, setInfantSocialNumber] = useState('')

    const [passwordError, setPasswordError] = useState(null)

    const [specialNoteCategories, setSpecialNoteCategories] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D004&codeDepth=1', setSpecialNoteCategories)
    }, [])

    useEffect(() => {
        if (isHeadHousehold) {
            setHeadHousehold(name)
        }
    }, [name])

    useEffect(() => {
        if (isHeadHousehold) {
            setHeadHousehold(name)
        }
    }, [isHeadHousehold])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError)) {
            if (password != passwordConfirm) {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm) {
            setPasswordError('Both password must match')
        }
        else {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleAddSpecialNotes = () => {
        if (specialNoteCategory && specialNoteSymptom) {
            setSpecialNotes(current => [...current, { id: Math.random().toString(36).substring(2, 10), noteCd: specialNoteCategory.value, note: specialNoteCategory.label, symptom: specialNoteSymptom }])
            setSpecialNoteCategory(null)
            setSpecialNoteSymptom('')
        }
    }

    const handleRemoveSpecialNotes = (item) => {
        setSpecialNotes(current => current.filter(value => value.id != item.id))
    }

    const handleClickClose = () => {
        navigate(-1);
    }

    const handleAddInfant = () => {
        if (infantName && infantSocialNumber) {
            setInfants(current => [...current, { id: Math.random().toString(36).substring(2, 10), name: infantName, regNo: infantSocialNumber }])
            setInfantName('')
            setInfantSocialNumber('')
        }
    }

    const handleRemoveInfant = (item) => {
        setInfants(current => current.filter(value => value.id != item.id))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password)) {
            setError('영문(대소문자), 숫자, 특수문자 조합 8자리 이상으로 입력하세요.')
            return
        }
        if (password != passwordConfirm) {
            setError('비밀번호가 일치하지 않습니다.')
            return
        }
        if (!zipCode) {
            setError('주소를 선택하세요.')
            return
        }

        setIsLoadingSubmit(true)

        var infantsList = infants.map(value => ({ name: value.name, regNo: value.regNo }))
        var victimNoteList = specialNotes.map(value => ({ noteCd: value.noteCd, symptom: value.symptom }))

        var formData = {
            userId: userId,
            pwd: password,
            name: name,
            bday: "",
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: "",
            typeCd: "02",
            regNo: socialNumber,
            hshdName: headHousehold,
            hshdYn: isHeadHousehold ? 'Y' : 'N',
            outYn: "N",
            delYn: "N",
            infantsList: infantsList,
            victimNoteList: victimNoteList,
        }

        fetch(process.env.REACT_APP_BASE_API_URL + '/victim/regVCTM', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                    setCurrentStep(3);


                    setName('')
                    setUserId('')
                    setPassword('')
                    setPasswordConfirm('')
                    setSocialNumber('')
                    setPhoneNumber('')
                    setZipCode('')
                    setMainAddress('')
                    setDetailedAddress('')
                    setHeadHousehold('')
                    setSpecialNotes([])
                    setInfants([])
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                    setCurrentStep(1);

                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            })
    }

    const gotoStep2 = () => {
        setError(null)
        window.scrollTo(0, 0);

        if (!userId) {
            setError('아이디를 입력하세요.')
            return
        }
        if (!password || !passwordConfirm) {
            setError('비밀번호를 입력하세요.')
            return
        }
        if (!ValidatorHelper.isValidPassword(password)) {
            setError('비밀번호는 영문(대소문자), 숫자, 특수문자 조합으로 8자리 이상이어야 합니다.')
            return
        }
        if (password != passwordConfirm) {
            setError('비밀번호가 일치하지 않습니다.')
            return
        }
        if (!name) {
            setError('이름을 입력하세요.')
            return
        }
        if (!socialNumber) {
            setError('주민등록번호를 입력하세요.')
            return
        }
        if (!zipCode) {
            setError('주소를 입력하세요.')
            return
        }
        if (!detailedAddress) {
            setError('주소를 입력하세요.')
            return
        }
        if (!headHousehold) {
            setError('세대주 성명을 입력하세요.')
            return
        }        

        setCurrentStep(2)
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    {currentStep == 2 ? (
                        <Link className="mobile-navbar-left plain-link" onClick={() => { window.scrollTo(0, 0); setCurrentStep(1) }}>
                            <img src="/images/icons/back.svg" />
                        </Link>
                    ) : (
                        <div className="blank-icon" />
                    )}
                    <div className="mobile-navbar-title">회원가입</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/close.svg" onClick={handleClickClose} />
                    </div>
                </div>
                {currentStep <= 2 ? (
                    <>
                        <div className="mobile-sub-navbar">
                            <p>STEP.{currentStep}</p>
                            <p>{currentStep == 1 ? '기본 정보 입력' : '추가 정보 입력'}</p>
                        </div>
                        <div className="navbar-progress progress-35"></div>
                    </>
                ) : null}
            </div>

            {currentStep <= 2 ? (
                <>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className="content-container container flex-fill pb-4">
                                <div className={currentStep == 1 ? 'd-block' : 'd-none'}>
                                    {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                                    {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}
                                    <div className="form-group">
                                        <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                                        <FormControl
                                            onChange={e => setUserId(e.target.value)}
                                            value={userId}
                                            placeholder="아이디를 입력하세요."
                                            required={true} />
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                                        <FormControl
                                            onChange={e => setPassword(e.target.value)}
                                            value={password}
                                            className={passwordError ? 'is-invalid' : ''}
                                            placeholder='영문(대소문자), 숫자, 특수문자 포함 8자리 이상'
                                            required={true} />
                                    </div>
                                    <div className="form-group">
                                        <FormControl
                                            onChange={e => setPasswordConfirm(e.target.value)}
                                            value={passwordConfirm}
                                            className={passwordError ? 'is-invalid' : ''}
                                            placeholder="비밀번호를 다시 입력하세요."
                                            required={true} />
                                        {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                                        <FormControl
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                            placeholder="이름을 입력하세요."
                                            required={true} />
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label align-self-center">주민등록번호<span className="label-ast">*</span></div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-fill">
                                                <FormControl
                                                    onChange={e => setSocialNumber(e.target.value)}
                                                    value={socialNumber}
                                                    required={true} />

                                            </div>
                                            <div className="px-2">-</div>
                                            <div className="flex-fill">
                                                <FormControl />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label align-self-center">연락처</div>
                                        <FormControl
                                            onChange={e => setPhoneNumber(e.target.value)}
                                            value={phoneNumber}
                                            placeholder="연락처를 입력하세요." />
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label align-self-center">주소<span className="label-ast">*</span></div>
                                        <div className="d-flex">
                                            <div className="flex-fill me-2">
                                                <FormControl
                                                    value={zipCode}
                                                    disabled={true} />
                                            </div>
                                            <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label"></div>
                                        <FormControl
                                            value={mainAddress}
                                            disabled={true} />
                                    </div>
                                    <div className="form-group">
                                        <div className="dms-form-label"></div>
                                        <FormControl
                                            onChange={e => setDetailedAddress(e.target.value)}
                                            value={detailedAddress}
                                            required={true} />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="dms-form-label align-self-center">세대주 성명</div>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-fill me-2">
                                                <FormControl
                                                    onChange={e => setHeadHousehold(e.target.value)}
                                                    value={headHousehold}
                                                    placeholder="세대주 이름을 입력하세요."
                                                    required={true}
                                                    disabled={isHeadHousehold} />
                                            </div>
                                            <div className="dms-input-4">
                                                <div
                                                    className={`dms-chip-btn dms-chip-btn-s ${isHeadHousehold ? 'dms-chip-btn-active' : ''} align-self-center`}
                                                    onClick={() => setIsHeadHousehold(current => !current)}>
                                                    <FormCheck checked={true} />
                                                    본인 세대주</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className={currentStep == 2 ? 'd-block' : 'd-none'}>
                                    <div className="form-heading">
                                        <h5>특이사항 입력</h5>
                                        <p>질병 혹은 임신 사실을 적어 보다 원활한 케어를 지원합니다.</p>
                                    </div>
                                    <div>
                                        <div className="form-group dms-input-container">
                                            <div className="dms-form-label align-self-center">특이사항</div>
                                            <div className="dms-input-row">
                                                <div className="dms-input-3">
                                                    <ReactSelect
                                                        components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                        classNamePrefix="select"
                                                        isSearchable={false}
                                                        placeholder={'Select'}
                                                        value={specialNoteCategory ?? null}
                                                        onChange={(e) => setSpecialNoteCategory(e)}
                                                        options={specialNoteCategories}
                                                    />
                                                </div>
                                                <div className="dms-input-7 mt-2">
                                                    <FormControl
                                                        onChange={e => setSpecialNoteSymptom(e.target.value)}
                                                        value={specialNoteSymptom}
                                                        placeholder="증상을 입력하세요." />
                                                </div>
                                                <div className="dms-input-2 mt-2">
                                                    <button type="button" onClick={handleAddSpecialNotes} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />추가하기</button>
                                                </div>
                                            </div>
                                        </div>

                                        {specialNotes.length > 0 ? (
                                            <table className="table dms-table-1">
                                                <thead>
                                                    <tr>
                                                        <th>특이사항</th>
                                                        <th>증상</th>
                                                        <th>관리</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {specialNotes.map(specialNote => (
                                                        <tr key={specialNote.id}>
                                                            <td>{specialNote.note}</td>
                                                            <td>{specialNote.symptom}</td>
                                                            <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveSpecialNotes(specialNote)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">삭제</button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : null}
                                    </div>

                                    <div className="mobile-section-separator" />

                                    <div className="form-heading">
                                        <h5>영유아 추가</h5>
                                    </div>
                                    <div>
                                        <div className="form-group dms-input-container">
                                            <div className="dms-form-label align-self-center">영유아 이름</div>
                                            <div className="dms-input-row">
                                                <div className="dms-input-3">
                                                    <FormControl
                                                        onChange={e => setInfantName(e.target.value)}
                                                        value={infantName} />
                                                </div>
                                                <div className="dms-input-2 d-flex mt-2">
                                                    <div className="dms-form-label align-self-center">주민등록번호</div>
                                                </div>
                                                <div className="dms-input-5">
                                                    <FormControl
                                                        onChange={e => setInfantSocialNumber(e.target.value)}
                                                        value={infantSocialNumber} />
                                                </div>
                                                <div className="dms-input-2 mt-2">
                                                    <button type="button" onClick={handleAddInfant} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />추가하기</button>
                                                </div>
                                            </div>
                                        </div>

                                        {infants.length > 0 ? (
                                            <table className="table dms-table-1">
                                                <thead>
                                                    <tr>
                                                        <th>영유아 이름</th>
                                                        <th>주민등록번호</th>
                                                        <th>관리</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {infants.map(infant => (
                                                        <tr key={infant.id}>
                                                            <td>{infant.name}</td>
                                                            <td>{infant.regNo}</td>
                                                            <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveInfant(infant)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">삭제</button></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="sticky-bottom mobile-bottom-btn-container">
                                {currentStep == 1 ? <button type="button" onClick={gotoStep2} className="dms-btn dms-btn-l dms-btn-filled">다음</button> : null}
                                {currentStep == 2 ? <button type="submit" className={`dms-btn dms-btn-l dms-btn-filled ${isLoadingSubmit ? 'disabled' : ''}`}>완료</button> : null}
                                {/* {currentStep == 2 ? <button type="button" onClick={() => { window.scrollTo(0, 0); setCurrentStep(3) }} className="dms-btn dms-btn-l dms-btn-filled" >Finish</button> : null} */}
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <>
                    <div className="container pt-4 pb-4 flex-fill text-center">
                        <img src="/images/gradient-check.svg" className="image-success-check mt-3" />
                        <p className="fs-m fw-bold mt-3">가입이 완료되었습니다.</p>
                        <p>입력한 정보의 변경점이 있을 경우,<br/> 환경설정 > 회원정보변경에서 변경해주세요.</p>
                    </div>
                    <div className="sticky-bottom mobile-bottom-btn-container">
                        <Link to={'/login-m'} className="dms-btn dms-btn-l dms-btn-filled">메인으로 이동</Link>
                    </div>
                </>
            )}

            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress}
                setIsVisible={setIsVisibleAddress}
                fullscreen={true}  />

        </div>
    );
}

export default VictimRegisterMobile;