import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import { TbQrcode } from "react-icons/tb";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";
import useFetchWithParameters from "../useFetchWithParameters";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }

const VictimDetails = () => {

    const history = useNavigate();
    const [isVisibleDelete, setIsVisibleDelete] = useState(false)

    const { id } = useParams();
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMInfo?userId='+id)
    const allListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';
    const usageHistoryApiUrl = process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMUsedSLTRList'
    const mealHistoryApiUrl = process.env.REACT_APP_BASE_API_URL+'/victim/getVTCMMealHist'
    const suppliesHistoryApiUrl = process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMSPLYHistory'
    
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isVisibleGettingInOutShelter, setIsVisibleGettingInOutShelter] = useState(false)
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoadingInOut, setIsLoadingInOut] = useState(false);
    const [successInOut, setSuccessInOut] = useState(null);
    const [errorInOut, setErrorInOut] = useState(null);

    const [isLoadingSpecialNote, setIsLoadingSpecialNote] = useState(false);
    const [successSpecialNote, setSuccessSpecialNote] = useState(null);
    const [errorSpecialNote, setErrorSpecialNote] = useState(null);

    const [isLoadingInfant, setIsLoadingInfant] = useState(false);
    const [successInfant, setSuccessInfant] = useState(null);
    const [errorInfant, setErrorInfant] = useState(null);

    const [years, setYears] = useState([])
    const [allDisasters, setAllDisasters] = useState([])
    const [usageHistoryDisasters, setUsageHistoryDisasters] = useState([])
    const [mealHistoryDisasters, setMealHistoryDisasters] = useState([])
    const [suppliesHistoryDisasters, setSuppliesHistoryDisasters] = useState([])
    
    const [name, setName] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [socialNumber, setSocialNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const [headHousehold, setHeadHousehold] = useState('')
    const [specialNotes, setSpecialNotes] = useState([])
    const [infants, setInfants] = useState([])
    const [email, setEmail] = useState('')
    const [updateDt, setUpdateDt] = useState('')    
    

    const [specialNoteCategory, setSpecialNoteCategory] = useState(null)
    const [specialNoteSymptom, setSpecialNoteSymptom] = useState('')
    const [infantName, setInfantName] = useState('')
    const [infantSocialNumber, setInfantSocialNumber] = useState('')
    
    const [passwordError, setPasswordError] = useState(null)
    
    const [specialNoteCategories, setSpecialNoteCategories] = useState([])

    const [currentShelterInfo, setCurrentShelterInfo] = useState(null)

    const [usageHistoryParameters, setUsageHistoryParameters] = useState({
        userId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [usageHistories, setUsageHistories] = useState([])
    const [usageHistoryTotalCount, setUsageHistoryTotalCount] = useState(null);
    const [usageHistoryFilters, setUsageHistoryFilters] = useState({})
    
    const [mealHistoryParameters, setMealHistoryParameters] = useState({
        userId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [mealHistories, setMealHistories] = useState([])
    const [mealHistoryTotalCount, setMealHistoryTotalCount] = useState(null);
    const [mealHistoryFilters, setMealHistoryFilters] = useState({})
    
    const [suppliesHistoryParameters, setSuppliesHistoryParameters] = useState({
        userId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [suppliesHistories, setSuppliesHistories] = useState([])
    const [suppliesHistoryTotalCount, setSuppliesHistoryTotalCount] = useState(null);
    const [suppliesHistoryFilters, setSuppliesHistoryFilters] = useState({})
    
    const [inOutArea1, setInOutArea1] = useState(null)
    const [inOutArea2, setInOutArea2] = useState(null)
    const [inOutShelter, setInOutShelter] = useState(null)
    
    const [inOutAreas1, setInOutAreas1] = useState([])
    const [inOutAreas2, setInOutAreas2] = useState([])
    const [inOutShelters, setInOutShelters] = useState([])

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);
    const { fetchResponse: usageHistoryFetchResponse } = useFetchWithParameters(usageHistoryApiUrl, usageHistoryParameters);
    const { fetchResponse: mealHistoryFetchResponse } = useFetchWithParameters(mealHistoryApiUrl, mealHistoryParameters);
    const { fetchResponse: suppliesHistoryFetchResponse } = useFetchWithParameters(suppliesHistoryApiUrl, suppliesHistoryParameters);
    
    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D004&codeDepth=1', setSpecialNoteCategories)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setInOutAreas1)
    }, [])

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setName(fetchResponse?.resultObject?.name)
            setUserId(fetchResponse?.resultObject?.userId)
            setSocialNumber(fetchResponse?.resultObject?.regNo)
            setPhoneNumber(fetchResponse?.resultObject?.telNo)
            setZipCode(fetchResponse?.resultObject?.zipCd)
            setMainAddress(fetchResponse?.resultObject?.addr)
            setDetailedAddress(fetchResponse?.resultObject?.addrDtl)
            setIsHeadHousehold(fetchResponse?.resultObject?.hshdYn == 'Y' ? true : false)
            setHeadHousehold(fetchResponse?.resultObject?.hshdName)
            setSpecialNotes(fetchResponse?.resultObject?.victimNoteList?.map(value => ({
                id: value.noteId, 
                noteCd: value.noteCd, 
                note: value.noteCdText,
                symptom: value.symptom,
                updateDt: value.updateDt,
            })))
            setInfants(fetchResponse?.resultObject?.infantsList?.map(value => ({
                id: value.infantId, 
                name: value.name, 
                regNo: value.regNo,
            })))
            setEmail(fetchResponse?.resultObject?.email)
            setCurrentShelterInfo(fetchResponse?.resultObject?.currentShelterInfo ?? {})
            setUpdateDt(fetchResponse?.resultObject?.updateDt);
        }
    }, [fetchResponse])

    useEffect(() => {
        if (allListFetchResponse)
        {
            const years = []
            const disasters = []
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt)
                {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt)
                {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }

                if (value?.name)
                {
                    if (!disasters.find(disaster => disaster.value == value.disasterCd)) disasters.push({ value: value.disasterCd, label: value.name, year: value?.startDt?.substring(0, 4) })
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({value: value, label: value})))
            setAllDisasters(disasters)
        }
    }, [allListFetchResponse])

    useEffect(() => {
        if (usageHistoryFilters?.year)
        {
            setUsageHistoryDisasters(allDisasters.filter(value => value.year == usageHistoryFilters?.year))
        }
        else
        {
            setUsageHistoryDisasters([])
        }
    }, [usageHistoryFilters?.year])

    useEffect(() => {
        if (mealHistoryFilters?.year)
        {
            setMealHistoryDisasters(allDisasters.filter(value => value.year == mealHistoryFilters?.year))
        }
        else
        {
            setMealHistoryDisasters([])
        }
    }, [mealHistoryFilters?.year])

    useEffect(() => {
        if (suppliesHistoryFilters?.year)
        {
            setSuppliesHistoryDisasters(allDisasters.filter(value => value.year == suppliesHistoryFilters?.year))
        }
        else
        {
            setSuppliesHistoryDisasters([])
        }
    }, [suppliesHistoryFilters?.year])

    useEffect(() => {
        if (usageHistoryFetchResponse)
        {
            setUsageHistoryTotalCount(usageHistoryFetchResponse?.totalCount);
            setUsageHistories(usageHistoryFetchResponse?.resultList ?? []);
        }
    }, [usageHistoryFetchResponse])

    useEffect(() => {
        if (mealHistoryFetchResponse)
        {
            setMealHistoryTotalCount(mealHistoryFetchResponse?.totalCount);
            setMealHistories(mealHistoryFetchResponse?.resultList ?? []);
        }
    }, [mealHistoryFetchResponse])

    useEffect(() => {
        if (suppliesHistoryFetchResponse)
        {
            setSuppliesHistoryTotalCount(suppliesHistoryFetchResponse?.totalCount);
            setSuppliesHistories(suppliesHistoryFetchResponse?.resultList ?? []);
        }
    }, [suppliesHistoryFetchResponse])

    useEffect(() => {
        if(inOutAreas1.length > 0) setInOutArea1(inOutAreas1[0])
    }, [inOutAreas1])

    useEffect(() => {
        if (!inOutArea1) return
        setInOutArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+inOutArea1?.value, setInOutAreas2)
    }, [inOutArea1])

    useEffect(() => {
        if (!inOutArea2) return
        setInOutShelter(null)

        ApiHelper.loadDropdown('area2Cd='+inOutArea2?.value+'&typeCd=02', setInOutShelters, 'fac/getFACLst', 'facilityId')
    }, [inOutArea2])

    useEffect(() => {
        if(isHeadHousehold)
        {
            setHeadHousehold(name)
        }
    }, [name])

    useEffect(() => {
        if(isHeadHousehold)
        {
            setHeadHousehold(name)
        }
    }, [isHeadHousehold])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password != passwordConfirm)
            {
                setPasswordError('비밀번호가 맞지 않습니다.')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm)
        {
            setPasswordError('비밀번호가 맞지 않습니다.')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleAddSpecialNotes = () => {
        if (specialNoteCategory && specialNoteSymptom)
        {
            setErrorSpecialNote(null)
            setSuccessSpecialNote(null)
            setIsLoadingSpecialNote(true)
            
            var formData = {
                userId: id,
                noteCd: specialNoteCategory.value,
                symptom: specialNoteSymptom,
            }
            
            fetch(process.env.REACT_APP_BASE_API_URL+'/victim/regVCTMNote', { 
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 
                    "Content-Type": "application/json",
                    //"Authorization": "Bearer "+localStorage.getItem("token"),
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }
    
                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0)
                {
                    setSuccessSpecialNote(data.responseMessage ?? 'Success')

                    fetch(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMNote?reqPageSize=1000&reqPageNo=1&userId='+id)
                    .then(response => {
                        if (!response.ok) throw Error('Failed to load data');
                        return response.json();
                    })
                    .then(data => {
                        if (data?.resultList)
                        {
                            setSpecialNotes(data.resultList?.map(value => ({
                                id: value.noteId, 
                                noteCd: value.noteCd, 
                                note: value.noteCdText,
                                symptom: value.symptom,
                                updateDt: value.updateDt,
                            })))
                        }
                    })
                    .catch(error => {
                    })

                    setSpecialNoteCategory(null)
                    setSpecialNoteSymptom('')
                }
                else
                {
                    setErrorSpecialNote(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSpecialNote(false);
            })
            .catch(error => {
                setErrorSpecialNote(error.message);
                setIsLoadingSpecialNote(false);
            })
        }
    }

    const handleRemoveSpecialNotes = (item) => {
        setErrorSpecialNote(null)
        setSuccessSpecialNote(null)
        setIsLoadingSpecialNote(true)
        
        var formData = {
            //parentId: id,
            noteId: item.id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/delVCTMNote', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessSpecialNote(data.responseMessage ?? 'Success')

                setSpecialNotes(current => current.filter(value => value.id != item.id))
            }
            else
            {
                setErrorSpecialNote(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSpecialNote(false);
        })
        .catch(error => {
            setErrorSpecialNote(error.message);
            setIsLoadingSpecialNote(false);
        })
    }

    const handleAddInfant = () => {
        if (infantName && infantSocialNumber)
        {
            setErrorInfant(null)
            setSuccessInfant(null)
            setIsLoadingInfant(true)
            
            var formData = {
                parentId: id,
                name: infantName,
                regNo: infantSocialNumber,
            }
            
            fetch(process.env.REACT_APP_BASE_API_URL+'/victim/regVCTMINF', { 
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 
                    "Content-Type": "application/json",
                    //"Authorization": "Bearer "+localStorage.getItem("token"),
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }
    
                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0)
                {
                    setSuccessInfant(data.responseMessage ?? 'Success')

                    fetch(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMINFLst?reqPageSize=1000&reqPageNo=1&userId='+id)
                    .then(response => {
                        if (!response.ok) throw Error('Failed to load data');
                        return response.json();
                    })
                    .then(data => {
                        if (data?.resultList)
                        {
                            setInfants(data.resultList?.map(value => ({
                                id: value.infantId, 
                                name: value.name, 
                                regNo: value.regNo,
                            })))
                        }
                    })
                    .catch(error => {
                    })

                    setInfantName('')
                    setInfantSocialNumber('')
                }
                else
                {
                    setErrorInfant(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingInfant(false);
            })
            .catch(error => {
                setErrorInfant(error.message);
                setIsLoadingInfant(false);
            })
        }
    }

    const handleRemoveInfant = (item) => {
        setErrorInfant(null)
        setSuccessInfant(null)
        setIsLoadingInfant(true)
        
        var formData = {
            parentId: id,
            infantId: item.id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/delVCTMINF', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessInfant(data.responseMessage ?? 'Success')

                setInfants(current => current.filter(value => value.id != item.id))
            }
            else
            {
                setErrorInfant(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingInfant(false);
        })
        .catch(error => {
            setErrorInfant(error.message);
            setIsLoadingInfant(false);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password))
        {
            setError('비밀번호는 글자,숫자,대문자 및 특수 문자를 포함하여 8글자 이상 작성해주세요.')
            return
        }
        if (password != passwordConfirm)
        {
            setError('비밀번호가 맞지 않습니다.')
            return
        }
        if (!zipCode)
        {
            setError('주소를 입력하세요.')
            return
        }

        setIsLoading(true)
        
        var formData = {
            userId: userId,
            pwd: password,
            name: name,
            bday: "",
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: email,
            regNo: socialNumber,
            hshdName: headHousehold,
            hshdYn: isHeadHousehold ? 'Y' : 'N',
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/updateVCTM', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleUsageHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setUsageHistoryParameters(current => ({...current, ...usageHistoryFilters}))
    }

    const initializeUsageHistoryFilter = () => {
        setUsageHistoryFilters({});
        setUsageHistoryParameters(current => ({userId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }
    
    const handleMealHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setMealHistoryParameters(current => ({...current, ...mealHistoryFilters}))
    }

    const initializeMealHistoryFilter = () => {
        setMealHistoryFilters({});
        setMealHistoryParameters(current => ({userId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }
    
    const handleSuppliesHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setSuppliesHistoryParameters(current => ({...current, ...suppliesHistoryFilters}))
    }

    const initializeSuppliesHistoryFilter = () => {
        setSuppliesHistoryFilters({});
        setSuppliesHistoryParameters(current => ({userId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    const handleGettingInOut = (e) => {
        e.preventDefault()

        setErrorInOut(null)
        setSuccessInOut(null)
        setIsLoadingInOut(true)
        
        var formData = {
            userId: userId,
            facilityId: inOutShelter?.value,
            statusCd: currentShelterInfo?.enterDt ? 'OUT' : 'IN'
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/userInout', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessInOut(data.responseMessage ?? 'Success')
                setInOutArea2(null)
                setInOutShelter(null)
                setInOutShelters([])
                setApiUrl(process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMInfo?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))
            }
            else
            {
                setErrorInOut(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingInOut(false);
        })
        .catch(error => {
            setErrorInOut(error.message);
            setIsLoadingInOut(false);
        })
    }

    const handleHideInOut = () => {
        setIsVisibleGettingInOutShelter(false)
        setErrorInOut(null)
        setSuccessInOut(null)
    }

    const handleDelete = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)
        
        var formData = {
            userId: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/delUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
                history('/victim-management')

                /*
                setDisasterType1(null)
                setDisasterType2(null)
                setName('')
                setMemo('')
                setStartDate('')
                setOccurBasisUrl('')
                setSelectedAreas([])
                */
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">이재민</h3>
                <Link to={'/victim-management'} className="sidebar-link active">이재민 등록/관리</Link>
                {/* <Link to={'/find-missing-children'} className="sidebar-link">미아/부모찾기</Link> */}
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">이재민 상세보기</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">{updateDt} 수정</p>
                            <Link to="/victim-management" className="dms-btn dms-btn-s dms-btn-outline">닫기</Link>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="이재민 개인정보">
                            <form onSubmit={handleSubmit}>
                                <div className="d-flex justify-content-end align-items-center mb-20px">
                                    {/*<button type="button" className="dms-btn dms-btn-s dms-btn-outline me-2"><TbQrcode  size="16px" /> QR Print</button> */}
                                    <button className="dms-btn dms-btn-s dms-btn-outline mr" onClick={handleDelete}>삭제</button>
                                    <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoading ? 'disabled' : ''}`}>수정하기</button>
                                </div>
                                
                                <div className="white-rounded-container mb-20px">
                                    {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                                    {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                                    <div className="form-heading"><h5>개인정보</h5></div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setName(e.target.value)}
                                                    value={name}
                                                    placeholder="Please enter a name"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    value={userId}
                                                    placeholder="Please enter ID"
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    onChange={e => setPassword(e.target.value)}
                                                    value={password}
                                                    className={passwordError ? 'is-invalid' : ''}
                                                    placeholder="영문(대소문자), 숫자, 특수문자 조합 8자리 이상"
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    onChange={e => setPasswordConfirm(e.target.value)}
                                                    value={passwordConfirm}
                                                    className={passwordError ? 'is-invalid' : ''}
                                                    placeholder="비밀번호를 다시 입력하세요."
                                                    required={true} />
                                                {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">주민등록번호<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setSocialNumber(e.target.value)}
                                                    value={socialNumber}
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">연락처<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setPhoneNumber(e.target.value)}
                                                    value={phoneNumber}
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">주소</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    value={zipCode}
                                                    disabled={true} />
                                            </div>
                                            <div className="dms-input-4">
                                                <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    value={mainAddress}
                                                    disabled={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-8">
                                                <FormControl
                                                    onChange={e => setDetailedAddress(e.target.value)}
                                                    value={detailedAddress}
                                                    required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">세대주 성명</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-4">
                                                <FormControl
                                                    onChange={e => setHeadHousehold(e.target.value)}
                                                    value={headHousehold}
                                                    required={true}
                                                    disabled={isHeadHousehold} />
                                            </div>
                                            <div className="dms-input-4">
                                                <div 
                                                    className={`dms-chip-btn dms-chip-btn-s ${isHeadHousehold ? 'dms-chip-btn-active' : ''} align-self-center`}
                                                    onClick={() => setIsHeadHousehold(current => !current)}>
                                                        <FormCheck checked={true} />
                                                        본인 세대주</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="white-rounded-container mb-20px">
                                    <div className="form-heading">
                                        <h5>특이사항 입력</h5>
                                        <p>질병 혹은 임신 사실을 적어서 보다 원활한 케어가 가능합니다.</p>
                                    </div>
                                    
                                    {successSpecialNote ? <Alert variant="success" style={{ marginTop: -20 }}>{successSpecialNote ?? 'Success'}</Alert> : null}
                                    {errorSpecialNote ? <Alert variant="danger" style={{ marginTop: -20 }}>{errorSpecialNote ?? 'Error'}</Alert> : null}
                                    
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">특이사항/설명</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <ReactSelect
                                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'선택'}
                                                    value={specialNoteCategory ?? null}
                                                    onChange={(e) => setSpecialNoteCategory(e)}
                                                    options={specialNoteCategories}
                                                />
                                            </div>
                                            <div className="dms-input-7">
                                                <FormControl
                                                    onChange={e => setSpecialNoteSymptom(e.target.value)}
                                                    value={specialNoteSymptom}
                                                    placeholder="입력하신 특이사항 증상에 대해 입력해주세요." />
                                            </div>
                                            <div className="dms-input-2">
                                                <button type="button" onClick={handleAddSpecialNotes} className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center ${isLoadingInfant ? 'disabled' : ''}`}><GoPlusCircle size="22px" />추가</button>
                                            </div>
                                        </div>
                                    </div>

                                    {specialNotes.length > 0 ? (
                                        <table className="table dms-table-1">
                                            <thead>
                                                <tr>
                                                    <th>특이사항</th>
                                                    <th>증상설명</th>
                                                    <th>최종 수정시간</th>
                                                    <th>관리</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {specialNotes.map(specialNote => (
                                                    <tr key={specialNote.id}>
                                                        <td>{specialNote.note}</td>
                                                        <td>{specialNote.symptom}</td>
                                                        <td>{specialNote?.updateDt}</td>
                                                        <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveSpecialNotes(specialNote)} className={`dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3 ${isLoadingInfant ? 'disabled' : ''}`}>삭제</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>

                                <div className="white-rounded-container mb-20px">
                                    <div className="form-heading">
                                        <h5>영유아, 어린이 추가</h5>
                                        <p>자신의 의사표현이 불가능한 영유아/어린이 정보를 입력하여, 부모와 같이 검색되도록 추가등록합니다.(ex. 현재년도 기준 12년 이전 출생자, 만 12세까지만 등록이 가능합니다.)</p>
                                    </div>
                                    
                                    {successInfant ? <Alert variant="success" style={{ marginTop: -20 }}>{successInfant ?? 'Success'}</Alert> : null}
                                    {errorInfant ? <Alert variant="danger" style={{ marginTop: -20 }}>{errorInfant ?? 'Error'}</Alert> : null}
                                    
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">영유아 성함</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <FormControl
                                                    onChange={e => setInfantName(e.target.value)}
                                                    value={infantName}
                                                    placeholder="성함을 입력해주세요." />
                                            </div>
                                            <div className="dms-input-2 d-flex">
                                                <div className="dms-form-label align-self-center">주민등록번호</div>
                                            </div>
                                            <div className="dms-input-5">
                                                <FormControl
                                                    onChange={e => setInfantSocialNumber(e.target.value)}
                                                    value={infantSocialNumber}
                                                    placeholder="주민등록번호를 입력해주세요." />
                                            </div>
                                            <div className="dms-input-2">
                                                <button type="button" onClick={handleAddInfant} className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center ${isLoadingInfant ? 'disabled' : ''}`}><GoPlusCircle size="22px" />추가</button>
                                            </div>
                                        </div>
                                    </div>

                                    {infants.length > 0 ? (
                                        <table className="table dms-table-1">
                                            <thead>
                                                <tr>
                                                    <th>영유아, 어린이 성함</th>
                                                    <th>주민등록번호</th>
                                                    <th>관리</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {infants.map(infant => (
                                                    <tr key={infant.id}>
                                                        <td>{infant.name}</td>
                                                        <td>{infant.regNo}</td>
                                                        <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveInfant(infant)} className={`dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3 ${isLoadingInfant ? 'disabled' : ''}`}>삭제</button></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    ) : null}
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="tab2" title="구호소 사용 이력">
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading d-flex justify-content-between align-items-center">
                                    <h5>현재 입소한 구호소</h5>
                                </div>

                                {currentShelterInfo === null ? (
                                    <p className="fs-s text-gray-03" style={{ marginTop: -20 }}>Loading data</p>
                                ) : (
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>지역</th>
                                                <th>시군구</th>
                                                <th>구호소명</th>
                                                <th>입소시간</th>
                                                <th>퇴소시간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentShelterInfo?.enterDt ? (
                                                <tr>
                                                    <td>{currentShelterInfo?.city}</td>
                                                    <td>{currentShelterInfo?.dist}</td>
                                                    <td>{currentShelterInfo?.shelterName}</td>
                                                    <td>{currentShelterInfo?.enterDt}</td>
                                                    <td><button onClick={() => setIsVisibleGettingInOutShelter('out')} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">퇴소 처리</button></td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan={3}>입소한 구호소가 없습니다.</td>
                                                    <td><button onClick={() => setIsVisibleGettingInOutShelter('in')} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">입소 처리</button></td>
                                                    <td><button className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3 disabled">퇴소 처리</button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>  
                                )}
                            </div>

                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>구호소 사용 이력</h5></div>

                                <form onSubmit={handleUsageHistoryFilterSubmit} className="mb-20px">
                                    <div className="row mb-20px">
                                        <div className="col-lg-2 d-flex align-items-center">
                                            <div className="dms-filter-label">년도/재난</div>
                                        </div>
                                        <div className="col-lg-10">
                                            <div className="row gx-20px">
                                                <div className="col-lg-3">
                                                    <ReactSelect
                                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                        classNamePrefix="select"
                                                        placeholder={'전체'}
                                                        value={usageHistoryFilters?.year ? {label: usageHistoryFilters?.year} : null}
                                                        onChange={(e) => setUsageHistoryFilters(current => ({...current, year: e.value}))}
                                                        isSearchable={false}
                                                        options={years}
                                                    />
                                                </div>
                                                <div className="col-lg-9">
                                                    <ReactSelect
                                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                        classNamePrefix="select"
                                                        placeholder={'전체'}
                                                        value={usageHistoryFilters?.disasterCd ? usageHistoryDisasters.find(value => value.value == usageHistoryFilters.disasterCd) ?? null : null}
                                                        onChange={(e) => setUsageHistoryFilters(current => ({...current, disasterCd: e.value}))}
                                                        isSearchable={false}
                                                        options={usageHistoryDisasters}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" onClick={initializeUsageHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                        <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                    </div>
                                </form>

                                {usageHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}
                                
                                { usageHistoryTotalCount !== null && usageHistoryTotalCount !== 0 ? (
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>시도</th>
                                                <th>시군구</th>
                                                <th>구호소명</th>
                                                <th>재난명</th>
                                                <th>입소시간</th>
                                                <th>퇴소시간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usageHistories.map(item => (
                                                <tr key={item.disasterCd+item.enterDt+item.leaveDt}>
                                                    <td>{item.city}</td>
                                                    <td>{item.dist}</td>
                                                    <td>{item.shelterName}</td>
                                                    <td>{item.disasterName}</td>
                                                    <td>{item.enterDt}</td>
                                                    <td>{item.leaveDt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : null}

                                {usageHistoryTotalCount !== null && usageHistoryTotalCount !== 0 && Math.ceil(usageHistoryTotalCount/usageHistoryParameters.reqPageSize) > 1 ? (
                                    <ReactPaginate
                                        onPageChange={(e) => setUsageHistoryParameters(current => ({...current, reqPageNo: e.selected+1}))}
                                        forcePage={Math.max(usageHistoryParameters.reqPageNo-1, 0)}
                                        pageCount={Math.ceil(usageHistoryTotalCount/usageHistoryParameters.reqPageSize)}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        previousLabel={<LuChevronLeft size="20px" />}
                                        nextLabel={<LuChevronRight size="20px" />}
                                        breakLabel="..."
                                        containerClassName="pagination justify-content-center"
                                        pageClassName="page-item"
                                        previousClassName="page-item page-previous"
                                        nextClassName="page-item page-next"
                                        breakClassName="page-item"
                                        pageLinkClassName="page-link"
                                        activeClassName="active"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                    />
                                ) : null}
                            </div>
                        </Tab>
                        <Tab eventKey="tab3" title="식사 이력">
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>식사 이력</h5></div>

                                <form onSubmit={handleMealHistoryFilterSubmit} className="mb-20px">
                                    <div className="row mb-20px">
                                        <div className="col-lg-2 d-flex align-items-center">
                                            <div className="dms-filter-label">년도/재난</div>
                                        </div>
                                        <div className="col-lg-10">
                                            <div className="row gx-20px">
                                                <div className="col-lg-3">
                                                    <ReactSelect
                                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                        classNamePrefix="select"
                                                        placeholder={'전체'}
                                                        value={mealHistoryFilters?.year ? {label: mealHistoryFilters?.year} : null}
                                                        onChange={(e) => setMealHistoryFilters(current => ({...current, year: e.value}))}
                                                        isSearchable={false}
                                                        options={years}
                                                    />
                                                </div>
                                                <div className="col-lg-9">
                                                    <ReactSelect
                                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                        classNamePrefix="select"
                                                        placeholder={'전체'}
                                                        value={mealHistoryFilters?.disasterCd ? mealHistoryDisasters.find(value => value.value == mealHistoryFilters.disasterCd) ?? null : null}
                                                        onChange={(e) => setMealHistoryFilters(current => ({...current, disasterCd: e.value}))}
                                                        isSearchable={false}
                                                        options={mealHistoryDisasters}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" onClick={initializeMealHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                        <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                    </div>
                                </form>

                                {mealHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}
                                
                                { mealHistoryTotalCount !== null && mealHistoryTotalCount !== 0 ? (
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>시도</th>
                                                <th>시군구</th>
                                                <th>구호소명</th>
                                                <th>재난명</th>
                                                <th>식사시간</th>
                                                <th>식사종류</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mealHistories.map(item => (
                                                <tr key={item.disasterCd+item.mealedTime}>
                                                    <td>{item.area1}</td>
                                                    <td>{item.area2}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.disasterName}</td>
                                                    <td>{item.mealedTime}</td>
                                                    <td>{item.mealType}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : null}

                                {mealHistoryTotalCount !== null && mealHistoryTotalCount !== 0 && Math.ceil(mealHistoryTotalCount/mealHistoryParameters.reqPageSize) > 1 ? (
                                    <ReactPaginate
                                        onPageChange={(e) => setMealHistoryParameters(current => ({...current, reqPageNo: e.selected+1}))}
                                        forcePage={Math.max(mealHistoryParameters.reqPageNo-1, 0)}
                                        pageCount={Math.ceil(mealHistoryTotalCount/mealHistoryParameters.reqPageSize)}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        previousLabel={<LuChevronLeft size="20px" />}
                                        nextLabel={<LuChevronRight size="20px" />}
                                        breakLabel="..."
                                        containerClassName="pagination justify-content-center"
                                        pageClassName="page-item"
                                        previousClassName="page-item page-previous"
                                        nextClassName="page-item page-next"
                                        breakClassName="page-item"
                                        pageLinkClassName="page-link"
                                        activeClassName="active"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                    />
                                ) : null}
                            </div>
                        </Tab>
                        <Tab eventKey="tab4" title="구호물품 수령 이력">
                            <div className="white-rounded-container mb-20px">
                                <div className="form-heading"><h5>구호물품 수령 이력</h5></div>

                                <form onSubmit={handleSuppliesHistoryFilterSubmit} className="mb-20px">
                                    <div className="row mb-20px">
                                        <div className="col-lg-2 d-flex align-items-center">
                                            <div className="dms-filter-label">년도/재난</div>
                                        </div>
                                        <div className="col-lg-10">
                                            <div className="row gx-20px">
                                                <div className="col-lg-3">
                                                    <ReactSelect
                                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                        classNamePrefix="select"
                                                        placeholder={'전체'}
                                                        value={suppliesHistoryFilters?.year ? {label: suppliesHistoryFilters?.year} : null}
                                                        onChange={(e) => setSuppliesHistoryFilters(current => ({...current, year: e.value}))}
                                                        isSearchable={false}
                                                        options={years}
                                                    />
                                                </div>
                                                <div className="col-lg-9">
                                                    <ReactSelect
                                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                        classNamePrefix="select"
                                                        placeholder={'전체'}
                                                        value={suppliesHistoryFilters?.disasterCd ? suppliesHistoryDisasters.find(value => value.value == suppliesHistoryFilters.disasterCd) ?? null : null}
                                                        onChange={(e) => setSuppliesHistoryFilters(current => ({...current, disasterCd: e.value}))}
                                                        isSearchable={false}
                                                        options={suppliesHistoryDisasters}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <button type="button" onClick={initializeSuppliesHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                        <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                    </div>
                                </form>

                                {suppliesHistoryTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}
                                
                                { suppliesHistoryTotalCount !== null && suppliesHistoryTotalCount !== 0 ? (
                                    <table className="table dms-table-1">
                                        <thead>
                                            <tr>
                                                <th>종류</th>
                                                <th>분류</th>
                                                <th>상세분류</th>
                                                <th>수량</th>
                                                <th>시도</th>
                                                <th>시군구</th>
                                                <th>구호소명</th>
                                                <th>재난명</th>
                                                <th>시간</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {suppliesHistories.map(item => (
                                                <tr key={item.disasterName+item.dstrDt}>
                                                    <td>{item.kindCd}</td>
                                                    <td>{item.cat1}</td>
                                                    <td>{item.catAll}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.area1}</td>
                                                    <td>{item.area2}</td>
                                                    <td>{item.shelterName}</td>
                                                    <td>{item.disasterName}</td>
                                                    <td>{item.dstrDt}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : null}

                                {suppliesHistoryTotalCount !== null && suppliesHistoryTotalCount !== 0 && Math.ceil(suppliesHistoryTotalCount/suppliesHistoryParameters.reqPageSize) > 1 ? (
                                    <ReactPaginate
                                        onPageChange={(e) => setSuppliesHistoryParameters(current => ({...current, reqPageNo: e.selected+1}))}
                                        forcePage={Math.max(suppliesHistoryParameters.reqPageNo-1, 0)}
                                        pageCount={Math.ceil(suppliesHistoryTotalCount/suppliesHistoryParameters.reqPageSize)}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        previousLabel={<LuChevronLeft size="20px" />}
                                        nextLabel={<LuChevronRight size="20px" />}
                                        breakLabel="..."
                                        containerClassName="pagination justify-content-center"
                                        pageClassName="page-item"
                                        previousClassName="page-item page-previous"
                                        nextClassName="page-item page-next"
                                        breakClassName="page-item"
                                        pageLinkClassName="page-link"
                                        activeClassName="active"
                                        previousLinkClassName="page-link"
                                        nextLinkClassName="page-link"
                                        breakLinkClassName="page-link"
                                    />
                                ) : null}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <DeleteModalWeb handleDelete={handleDelete} isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />

            <Modal size="lg" show={isVisibleGettingInOutShelter ? true : false} onHide={handleHideInOut} centered>
                <form onSubmit={handleGettingInOut}>
                    <Modal.Header closeButton>
                        <Modal.Title>구호소 {isVisibleGettingInOutShelter == 'in' ? '입소' : '퇴소'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successInOut ? <Alert variant="success">{successInOut ?? 'Success'}</Alert> : null}
                        {errorInOut ? <Alert variant="danger">{errorInOut ?? 'Error'}</Alert> : null}
                        
                        {successInOut ? null : (
                            <div className="row mb-2">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <div className="dms-filter-label">구호소</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gx-2">
                                        <div className="col-lg-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'선택'}
                                                value={inOutArea1 ?? null}
                                                onChange={(e) => setInOutArea1(e)}
                                                options={inOutAreas1}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'전체'}
                                                value={inOutArea2 ?? null}
                                                onChange={(e) => setInOutArea2(e)}
                                                options={inOutAreas2}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'전체'}
                                                value={inOutShelter ?? null}
                                                onChange={(e) => setInOutShelter(e)}
                                                options={inOutShelters}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {successInOut ? (
                            <button type="button" onClick={handleHideInOut} className="dms-btn dms-btn-xs dms-btn-filled">닫기</button>
                        ) : (
                            <>
                                <button type="button" onClick={handleHideInOut} className="dms-btn dms-btn-xs dms-btn-outline me-2">취소</button>
                                <button type="submit" className={`dms-btn dms-btn-xs dms-btn-filled ${isLoadingInOut ? 'disabled' : ''}`}>{isVisibleGettingInOutShelter == 'in' ? '입소' : '퇴소'}</button>
                            </>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}
 
export default VictimDetails;