import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Box, Button, DialogContent, DialogTitle, Input, Modal, ModalDialog, Stack } from "@mui/joy";
import DaumPostcode from 'react-daum-postcode';

const KakaoAddress = () => {
    const [open, setOpen] = useState(false);
    const [zipCode, setZipcode] = useState('');
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')

    const handleSelectAddress = (data) => {
        setZipcode(data.zonecode);
        setMainAddress(data.address);
        setOpen(false);
    }

    return (
        <>
            <div style={{ zIndex: '100', position: 'absolute', marginTop: '1%' }}>
                <Box sx={{ maxWidth: '400px', padding: 3 }}>
                    <Stack spacing={1}>
                        
                        <Stack direction="row" spacing={2}>
                            <Input placeholder="Zip code" value={zipCode} onChange={(e) => { setZipcode(e.target.value) }} />
                            <Button onClick={() => { setOpen(true) }}>Search</Button>
                        </Stack>
                        <Input placeholder="Main address" value={mainAddress} onChange={(e) => { setMainAddress(e.target.value) }} />
                        <Input placeholder="Detailed address" value={detailedAddress} onChange={(e) => { setDetailedAddress(e.target.value) }} />
                    </Stack>
                </Box>
                <Modal open={open} onClose={() => { setOpen(false) }} >
                    <ModalDialog variant="plain">
                        <DialogTitle>Search main address</DialogTitle>
                        <DialogContent sx={{ maxWidth: '800px' }}>
                            <DaumPostcode
                                onComplete={handleSelectAddress}  // 값을 선택할 경우 실행되는 이벤트
                                autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                                defaultQuery={mainAddress} // 팝업을 열때 기본적으로 입력되는 검색어
                            />

                        </DialogContent>
                    </ModalDialog>
                </Modal>
            </div>
        </>

    )
}

export default KakaoAddress