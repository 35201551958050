import { useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { IoChevronForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const QrAdminMobile = () => {
    const { user } = useSelector(state => state.user);
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)
    
    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="bg-blue text-white container py-4">
                    <p className="fs-xxs mb-1">2024년 4월 1일 11:28 발생</p>
                    <h4 className="mb-2">제 6호 태풍 '카눈'</h4>
                    <p className="fs-xxs mb-0">태풍 '카눈' 한반도 상륙...밤 9시께 울산 통과</p>
                </div>
            </div>
            
            <div className="content-container container flex-fill pb-2 d-flex flex-column justify-content-between">
                <div>
                    <div className="form-heading">
                        <h5>QR 촬영</h5>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <Link to="/qr-scan-in-out" className="plain-link">
                                    <h5>구호소 입소/퇴소</h5>
                                    <p className="text-gray-04 fs-xxs mb-0">이재민 QR코드를 촬영하여<br/>현재 구호소에 입소/퇴소 처리 합니다.</p>
                                </Link>
                            </div>
                            <div className="ms-2">
                                <Link to="/qr-scan-in-out" className="plain-link">
                                    <div className="bg-gray-01 rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                                        <IoChevronForward size="24px" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <Link to="/bringing-in-relief-supplies-m" className="plain-link">
                                    <h5>구호물품 불출</h5>
                                    <p className="text-gray-04 fs-xxs mb-0">구호물품을 선택 후,<br/>이재민 QR코드를 촬영하여 불출합니다.</p>
                                </Link>
                            </div>
                            <div className="ms-2">
                                <Link to="/bringing-in-relief-supplies-m" className="plain-link">
                                    <div className="bg-gray-01 rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                                        <IoChevronForward size="24px" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-card">
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <Link to="/qr-scan-meal" className="plain-link">
                                    <h5>식사</h5>
                                    <p className="text-gray-04 fs-xxs mb-0">해당 버튼을 누른 후에<br/>이재빈 QR코드를 촬영하면 완료됩니다.</p>
                                </Link>
                            </div>
                            <div className="ms-2">
                                <Link to="/qr-scan-meal" className="plain-link">
                                    <div className="bg-gray-01 rounded-circle d-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px' }}>
                                        <IoChevronForward size="24px" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center mt-3 mb-2">It was received successfully.</div> */}
            </div>

            <FooterMobile user={user} active="QR Code" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}
 
export default QrAdminMobile;