import React, { useState, useEffect, useRef } from 'react';
import { FormCheck, FormControl, Modal, Tab, Tabs, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import { setUser } from "../redux/userSlice";
import { useSelector } from "react-redux";


const ShelterVolunteerRegister = () => {
    const uploadFileRef = useRef()

    const { user } = useSelector(state => state.user);
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);

    const [items, setItems] = useState([{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [image, setImage] = useState(false)

    const [facilityId, setFacilityId] = useState('');
    const [roleCds, setRoleCds] = useState('');
    const [roleCd, setRoleCd] = useState('');
    const [writerId, setWriterId] = useState('admin_006');
    const [reqVltrCount, setReqVltrCount] = useState('');
    const [writerTelNo, setWriterTelNo] = useState('');
    const [memo, setMemo] = useState('');

    const [toAddr, setToAddr] = useState('');
    const [fromFacilityId, setFromFacilityId] = useState('');
    const [inOutArea1, setInOutArea1] = useState(null)
    const [inOutArea2, setInOutArea2] = useState(null)
    const [inOutShelter, setInOutShelter] = useState(null)

    const [inOutAreas1, setInOutAreas1] = useState([])
    const [inOutAreas2, setInOutAreas2] = useState([])
    const [inOutShelters, setInOutShelters] = useState([])


    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);


    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D006&codeDepth=1', setRoleCds)
    }, [])

    useEffect(() => {
        if (roleCds.length > 0) setRoleCd(roleCds[0])
    }, [roleCds])




    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setInOutAreas1)
    }, [])

    useEffect(() => {
        if (inOutAreas1.length > 0) setInOutArea1(inOutAreas1[0])
    }, [inOutAreas1])


    useEffect(() => {
        if (!inOutArea1) return
        setInOutArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + inOutArea1?.value, setInOutAreas2)
    }, [inOutArea1])

    useEffect(() => {
        if (!inOutArea2) return
        setInOutShelter(null)

        ApiHelper.loadDropdown('area2Cd=' + inOutArea2?.value + '&typeCd=02', setInOutShelters, 'fac/getFACLst', 'facilityId')
    }, [inOutArea2])


    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            writerId: writerId,
            writerTelNo,
            reqVltrCount,
            facilityId,
            memo,
            roleCd,
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/vltr/regVltrReq', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            });
    };

    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/auth/getUser?userId=${user.userId}`;

    useEffect(() => {
        if (!apiUrl) return;

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                const resultObject = data.resultObject;

                setUserId(resultObject.userId);
                setName(resultObject.name);
            })
            .catch(error => {
                console.error('Error occurred while fetching user details:', error);
            });
    }, [apiUrl]);

    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Shelter</h3>
                <Link to={'/shelter-management'} className="sidebar-link active">Shelter management</Link>
                <Link to={'/shelter-inquiry-board'} className="sidebar-link">Shelter inquiry board</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}
                <form onSubmit={handleSubmit}>
                    <div className="inner-container">
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">Register a volunteer request</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>
                                <button type="submit" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">Register</button>
                            </div>
                        </div>

                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>Details</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Requested By<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                        value={writerId}
                                        placeholder="Please enter a name" disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Assigned Relief Station</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Ulsan'}
                                            isSearchable={false}
                                            onChange={(e) => setToAddr(e.value)}
                                            options={inOutAreas1}
                                        />
                                    </div>
                                    <div className="dms-input-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Citywide'}
                                            isSearchable={false}
                                            value={inOutArea2 ?? null}
                                            onChange={(e) => setInOutArea2(e)}
                                            options={inOutAreas2}
                                        />
                                    </div>
                                    <div className="dms-input-6">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Select the shelter or warehouse'}
                                            isSearchable={false}

                                            onChange={(e) => setFacilityId(e.value)}
                                            options={inOutShelters}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Phone number</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            onChange={(e) => setWriterTelNo(e.target.value)}
                                            value={writerTelNo}
                                            placeholder="Please enter a name" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Number of volunteer request</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <div className="input-group">
                                            <FormControl
                                                onChange={(e) => setReqVltrCount(e.target.value)}
                                                value={reqVltrCount}
                                                placeholder="Enter quantity" />
                                            <span className="input-group-text">EA</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">Request roles</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Ulsan'}
                                            isSearchable={false}
                                            onChange={(e) => setRoleCd(e.value)}
                                            options={roleCds}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label mt-1">Details</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-12">
                                        <FormControl as="textarea" rows="8"
                                            value={memo}
                                            onChange={(e) => setMemo(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <Footer />
            </div>
        </div>
    );
}

export default ShelterVolunteerRegister;