import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetch from "../useFetch";
import ValidatorHelper from "../Helpers/ValidatorHelper";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";
import useFetchWithParameters from "../useFetchWithParameters";

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }

const UserDetails = () => {
    const { id } = useParams();
    
    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/auth/getUser?userId='+id)
    const allListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';
    const historyApiUrl = process.env.REACT_APP_BASE_API_URL+'/vltr/getVltrAsgnlHist'
    
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isVisibleDelete, setIsVisibleDelete] = useState(false);
    const [isVisibleGettingInOutShelter, setIsVisibleGettingInOutShelter] = useState(false)
    
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoadingInOut, setIsLoadingInOut] = useState(false);
    const [successInOut, setSuccessInOut] = useState(null);
    const [errorInOut, setErrorInOut] = useState(null);

    const [years, setYears] = useState([])
    const [allDisasters, setAllDisasters] = useState([])
    const [disasters, setDisasters] = useState([])
    
    const [name, setName] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [birthday, setBirthday] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [affiliation, setAffiliation] = useState('')
    const [updateDt, setUpdateDt] = useState('')    

    const [currentShelterInfo, setCurrentShelterInfo] = useState(null)

    const [birthdayError, setBirthdayError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    
    const [affiliations, setAffiliations] = useState([])

    const [inOutArea1, setInOutArea1] = useState(null)
    const [inOutArea2, setInOutArea2] = useState(null)
    const [inOutShelter, setInOutShelter] = useState(null)
    
    const [inOutAreas1, setInOutAreas1] = useState([])
    const [inOutAreas2, setInOutAreas2] = useState([])
    const [inOutShelters, setInOutShelters] = useState([])

    const [historyParameters, setHistoryParameters] = useState({
        userId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [histories, setHistories] = useState([])
    const [historyTotalCount, setHistoryTotalCount] = useState(null);
    const [historyFilters, setHistoryFilters] = useState({})
    
    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);
    const { fetchResponse: historyFetchResponse } = useFetchWithParameters(historyApiUrl, historyParameters);

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setUserId(fetchResponse?.resultObject?.userId)
            setName(fetchResponse?.resultObject?.name)
            setBirthday(fetchResponse?.resultObject?.bday)
            setZipCode(fetchResponse?.resultObject?.zipCd)
            setMainAddress(fetchResponse?.resultObject?.addr)
            setDetailedAddress(fetchResponse?.resultObject?.addrDtl)
            setPhoneNumber(fetchResponse?.resultObject?.telNo)
            setEmail(fetchResponse?.resultObject?.email)
            setAffiliation(fetchResponse?.resultObject?.affiliationCd)
            setCurrentShelterInfo(fetchResponse?.resultObject?.currentShelterInfo ?? {})

            setUpdateDt(fetchResponse?.resultObject?.updateDt)            
        }
    }, [fetchResponse])

    useEffect(() => {
        if (historyFetchResponse)
        {
            setHistoryTotalCount(historyFetchResponse?.totalCount);
            setHistories(historyFetchResponse?.resultList ?? []);
        }
    }, [historyFetchResponse])

    useEffect(() => {
        if (allListFetchResponse)
        {
            const years = []
            const disasters = []
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt)
                {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt)
                {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }

                if (value?.name)
                {
                    if (!disasters.find(disaster => disaster.value == value.disasterCd)) disasters.push({ value: value.disasterCd, label: value.name, year: value?.startDt?.substring(0, 4) })
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({value: value, label: value})))
            setAllDisasters(disasters)
        }
    }, [allListFetchResponse])

    useEffect(() => {
        if (historyFilters?.year)
        {
            setDisasters(allDisasters.filter(value => value.year == historyFilters?.year))
        }
        else
        {
            setDisasters([])
        }
    }, [historyFilters?.year])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D009&codeDepth=1', setAffiliations)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setInOutAreas1)
    }, [])

    const handleHistoryFilterSubmit = (e) => {
        e.preventDefault();

        setHistoryParameters(current => ({...current, ...historyFilters}))
    }

    const initializeHistoryFilter = () => {
        setHistoryFilters({});
        setHistoryParameters(current => ({userId: id, reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    useEffect(() => {
        ValidatorHelper.validateYYYYMMDD(birthday, setBirthdayError)
    }, [birthday])

    useEffect(() => {
        if(inOutAreas1.length > 0) setInOutArea1(inOutAreas1[0])
    }, [inOutAreas1])

    useEffect(() => {
        if (!inOutArea1) return
        setInOutArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+inOutArea1?.value, setInOutAreas2)
    }, [inOutArea1])

    useEffect(() => {
        if (!inOutArea2) return
        setInOutShelter(null)

        ApiHelper.loadDropdown('area2Cd='+inOutArea2?.value+'&typeCd=02', setInOutShelters, 'fac/getFACLst', 'facilityId')
    }, [inOutArea2])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password != passwordConfirm)
            {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm)
        {
            setPasswordError('Both password must match')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password))
        {
            setError('Password must contain at least 8 characters including alphanumeric and special characters')
            return
        }
        if (password != passwordConfirm)
        {
            setError('Both password must match')
            return
        }
        if (!ValidatorHelper.isValidYYYYMMDD(birthday))
        {
            setError('Enter valid birthday in YYYYMMDD')
            return
        }
        if (!zipCode)
        {
            setError('Enter choose an address')
            return
        }

        setIsLoading(true)
        
        var formData = {
            userId: userId,
            name: name,
            pwd: password,
            bday: birthday,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: email,
            affiliationCd: affiliation
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/updateUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleDelete = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)
        
        var formData = {
            userIdList: [id],
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/delUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setName('')
                setUserId('')
                setPassword('')
                setPasswordConfirm('')
                setBirthday('')
                setZipCode('')
                setMainAddress('')
                setDetailedAddress('')
                setPhoneNumber('')
                setEmail('')
                setAffiliation('')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    const handleGettingInOut = (e) => {
        e.preventDefault()

        setErrorInOut(null)
        setSuccessInOut(null)
        setIsLoadingInOut(true)
        
        var formData = {
            userId: userId,
            facilityId: inOutShelter?.value,
            statusCd: currentShelterInfo?.enterDt ? 'OUT' : 'IN'
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/userInout', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessInOut(data.responseMessage ?? 'Success')
                setInOutArea2(null)
                setInOutShelter(null)
                setInOutShelters([])
                setApiUrl(process.env.REACT_APP_BASE_API_URL+'/auth/getUser?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))
            }
            else
            {
                setErrorInOut(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingInOut(false);
        })
        .catch(error => {
            setErrorInOut(error.message);
            setIsLoadingInOut(false);
        })
    }

    const handleHideInOut = () => {
        setIsVisibleGettingInOutShelter(false)
        setErrorInOut(null)
        setSuccessInOut(null)
    }
    
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}])
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">시스템 관리</h3>
                <Link to={'/statistics'} className="sidebar-link">통계자료</Link>
                <Link to={'/user-management'} className="sidebar-link active">사용자 등록/관리</Link>
                <Link to={'/disaster-management'} className="sidebar-link">재난코드 등록/관리</Link>
                <Link to={'/notices-news'} className="sidebar-link">공지사항</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <form onSubmit={handleSubmit}>
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">사용자 상세보기</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <p className="last-modified text-orange">{updateDt} 수정</p>

                                {/*                                
                                <button type="button" className="dms-btn dms-btn-s dms-btn-outline mr">Close</button> */}
                                <Link to="/user-management" className="dms-btn dms-btn-s dms-btn-outline mr">닫기</Link>

                                <button type="button" onClick={() => setIsVisibleDelete(true)} className={`dms-btn dms-btn-s dms-btn-outline mr ${isLoading ? 'disabled' : ''}`}>삭제</button>
                                <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline ${isLoading ? 'disabled' : ''}`}>수정</button>
                            </div>
                        </div>

                        <div className="white-rounded-container mb-20px">
                            {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                            {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                            <div className="form-heading"><h5>개인정보</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            onChange={e => setName(e.target.value)}
                                            value={name}
                                            placeholder="이름을 입력하세요."
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            value={userId}
                                            placeholder="영문, 숫자, 특수문자 조합"
                                            required={true}
                                            disabled={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container mb-2">
                                <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl
                                            onChange={e => setPassword(e.target.value)}
                                            value={password}
                                            className={passwordError ? 'is-invalid' : ''}
                                            placeholder="영문, 숫자, 특수문자 포함 8글자"
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl
                                            onChange={e => setPasswordConfirm(e.target.value)}
                                            value={passwordConfirm}
                                            className={passwordError ? 'is-invalid' : ''}
                                            placeholder="비밀번호 재확인"
                                            required={true} />
                                        {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">생년월일</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            onChange={e => setBirthday(e.target.value)}
                                            value={birthday}
                                            className={birthdayError ? 'is-invalid' : ''}
                                            placeholder="숫자만 입력하세요.(YYYYMMDD)" />
                                        {birthdayError ? <div className="input-helper-error">{birthdayError}</div> : null}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container mb-2">
                                <div className="dms-form-label align-self-center">주소</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            value={zipCode}
                                            disabled={true} />
                                    </div>
                                    <div className="dms-input-4">
                                        <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container mb-2">
                                <div className="dms-form-label"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl
                                            value={mainAddress}
                                            disabled={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl
                                            onChange={e => setDetailedAddress(e.target.value)}
                                            value={detailedAddress}
                                            placeholder="상세주소를 입력하세요(동, 호수)"
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">연락처<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            onChange={e => setPhoneNumber(e.target.value)}
                                            value={phoneNumber}
                                            placeholder="숫자만 입력하세요."
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">이메일 주소<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl
                                            type="email"
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                            placeholder="이메일 주소를 입력하세요."
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">소속<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            required={true}
                                            placeholder={'소속 선택'}
                                            value={affiliations ? affiliations.find(value => value.value == affiliation) ?? null : null}
                                            onChange={(e) => setAffiliation(e.value)}
                                            options={affiliations}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading d-flex justify-content-between align-items-center">
                            <h5>현재 입소한 구호소</h5>
                        </div>

                        {currentShelterInfo === null ? (
                            <p className="fs-s text-gray-03" style={{ marginTop: -20 }}>Loading data</p>
                        ) : (
                            <table className="table dms-table-1">
                                <thead>
                                    <tr>
                                        <th>시도</th>
                                        <th>시군구</th>
                                        <th>구호소명</th>
                                        <th>입소일시</th>
                                        <th>퇴소일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentShelterInfo?.enterDt ? (
                                        <tr>
                                            <td>{currentShelterInfo?.city}</td>
                                            <td>{currentShelterInfo?.dist}</td>
                                            <td>{currentShelterInfo?.shelterName}</td>
                                            <td>{currentShelterInfo?.enterDt}</td>
                                            <td><button onClick={() => setIsVisibleGettingInOutShelter('out')} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">퇴소하기</button></td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td colSpan={3}>입소한 구호소가 없습니다.</td>
                                            <td><button onClick={() => setIsVisibleGettingInOutShelter('in')} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">입소하기</button></td>
                                            <td><button className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3 disabled">퇴소하기</button></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>  
                        )}
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>구호소 투입 내역</h5></div>
                        
                        <form onSubmit={handleHistoryFilterSubmit} className="mb-20px">
                            <div className="row mb-20px">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <div className="dms-filter-label">년도/재난</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gx-20px">
                                        <div className="col-lg-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                placeholder={'선택'}
                                                value={historyFilters?.year ? {label: historyFilters?.year} : null}
                                                onChange={(e) => setHistoryFilters(current => ({...current, year: e.value}))}
                                                isSearchable={false}
                                                options={years}
                                                noOptionsMessage={() => '데이터 없음'}
                                            />
                                        </div>
                                        <div className="col-lg-9">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                placeholder={'전체'}
                                                value={historyFilters?.disasterCd ? disasters.find(value => value.value == historyFilters.disasterCd) ?? null : null}
                                                onChange={(e) => setHistoryFilters(current => ({...current, disasterCd: e.value}))}
                                                isSearchable={false}
                                                options={disasters}
                                                noOptionsMessage={() => '데이터 없음'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                                <button type="button" onClick={initializeHistoryFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                            </div>
                        </form>

                        {historyTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}
                                
                        { historyTotalCount !== null && historyTotalCount !== 0 ? (
                            <table className="table dms-table-1">
                                <thead>
                                    <tr>
                                        <th>시도</th>
                                        <th>시군구</th>
                                        <th>구호소 주소</th>
                                        <th>구호소명</th>
                                        <th>역할</th>
                                        <th>입소일시</th>
                                        <th>퇴소일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map(value => (
                                        <tr key={value.id}>
                                            <td>Test Test</td>
                                            <td>Test Test</td>
                                            <td>Test</td>
                                            <td>Test Test Test</td>
                                            <td>Test Test Test</td>
                                            <td>2023-07-23 11:21:23</td>
                                            <td>2023-07-23 11:21:23</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}

                        {historyTotalCount !== null && historyTotalCount !== 0 && Math.ceil(historyTotalCount/historyParameters.reqPageSize) > 1 ? (
                            <ReactPaginate
                                onPageChange={(e) => setHistoryParameters(current => ({...current, reqPageNo: e.selected+1}))}
                                forcePage={Math.max(historyParameters.reqPageNo-1, 0)}
                                pageCount={Math.ceil(historyTotalCount/historyParameters.reqPageSize)}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                previousLabel={<LuChevronLeft size="20px" />}
                                nextLabel={<LuChevronRight size="20px" />}
                                breakLabel="..."
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                previousClassName="page-item page-previous"
                                nextClassName="page-item page-next"
                                breakClassName="page-item"
                                pageLinkClassName="page-link"
                                activeClassName="active"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                                breakLinkClassName="page-link"
                            />
                        ) : null}
                    </div>
                </div>
                    
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />

            <DeleteModalWeb handleDelete={handleDelete} isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />

            <Modal size="lg" show={isVisibleGettingInOutShelter ? true : false} onHide={handleHideInOut} centered>
                <form onSubmit={handleGettingInOut}>
                    <Modal.Header closeButton>
                        <Modal.Title> 구호소 입소하기</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {successInOut ? <Alert variant="success">{successInOut ?? 'Success'}</Alert> : null}
                        {errorInOut ? <Alert variant="danger">{errorInOut ?? 'Error'}</Alert> : null}
                        
                        {successInOut ? null : (
                            <div className="row mb-2">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <div className="dms-filter-label">구호소 재설정</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gx-2">
                                        <div className="col-lg-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'Select Province'}
                                                value={inOutArea1 ?? null}
                                                onChange={(e) => setInOutArea1(e)}
                                                options={inOutAreas1}
                                                noOptionsMessage={() => '데이터 없음'}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'시군구'}
                                                value={inOutArea2 ?? null}
                                                onChange={(e) => setInOutArea2(e)}
                                                options={inOutAreas2}
                                                noOptionsMessage={() => '데이터 없음'}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}
                                                required={true}
                                                placeholder={'모든 구호소'}
                                                value={inOutShelter ?? null}
                                                onChange={(e) => setInOutShelter(e)}
                                                options={inOutShelters}
                                                noOptionsMessage={() => '데이터 없음'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {successInOut ? (
                            <button type="button" onClick={handleHideInOut} className="dms-btn dms-btn-xs dms-btn-filled">Close</button>
                        ) : (
                            <>
                                <button type="button" onClick={handleHideInOut} className="dms-btn dms-btn-xs dms-btn-outline me-2">취소</button>
                                <button type="submit" className={`dms-btn dms-btn-xs dms-btn-filled ${isLoadingInOut ? 'disabled' : ''}`}>확인</button>
                            </>
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}
 
export default UserDetails;