import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { Form, FormControl, Modal } from "react-bootstrap";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";

const BringingInReliefSuppliesMobile = () => {
    const { user } = useSelector(state => state.user);
    
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/sply/getSplyLst';

    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [isVisibleFilterModal, setIsVisibleFilterModal] = useState(false)
    
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [itemTypes, setItemTypes] = useState([])    
    const [categories1, setCategories1] = useState([])
    const [categories2, setCategories2] = useState([])
    const [categories3, setCategories3] = useState([])
    const [categories4, setCategories4] = useState([])
    
    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleLoadMore = () => {
        setParameters(current => ({...current, reqPageSize: current.reqPageSize+10}))
    }

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setIsVisibleFilterModal(false)

        setParameters(current => ({
            ...current, 
            reqPageSize: 10,
            ...(filters?.type ? {kindCd: filters?.type} : null),
            ...(filters?.cat1 ? {cat1Cd: filters?.cat1.substring(filters?.type.length)} : null),
            ...(filters?.cat2 ? {cat2Cd: filters?.cat2.substring(filters?.cat1.length)} : null),
            ...(filters?.cat3 ? {cat3Cd: filters?.cat3.substring(filters?.cat2.length)} : null),
            ...(filters?.cat4 ? {cat4Cd: filters?.cat4.substring(filters?.cat3.length)} : null),
        }))
    }

    const initializeFilter = () => {
        setIsVisibleFilterModal(false)
        setFilters({});
        setParameters(current => ({reqPageNo: 1, reqPageSize: 10}))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
    }, [])

    useEffect(() => {
        if (!filters?.type) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setCategories2([])
        setCategories3([])
        setCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1='+filters?.type, setCategories1)
    }, [filters?.type])

    useEffect(() => {
        if (!filters?.cat1) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setCategories3([])
        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1='+filters?.type+'&codeLev2='+cat1, setCategories2)
    }, [filters?.cat1])

    useEffect(() => {
        if (!filters?.cat2) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });
        
        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1='+filters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2, setCategories3)
    }, [filters?.cat2])

    useEffect(() => {
        if (!filters?.cat3) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)
        var cat3 = filters?.cat3.substring(filters?.cat2.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1='+filters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2+'&codeLev4='+cat3, setCategories4)
    }, [filters?.cat3])

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <Link to="/qr-admin-m" className="mobile-navbar-left">
                        <img src="/images/icons/back.svg" />
                    </Link>
                    <div className="mobile-navbar-title">구호물품 불출</div>
                    <div className="mobile-navbar-right">
                        <img src="/images/icons/home.svg" />
                    </div>
                </div>
            </div>
            
            <div className="content-container flex-fill pt-0 pb-2">
                <div className="mobile-filter-box d-flex">
                    <div onClick={() => setIsVisibleFilterModal(true)} className={`filter-item ${filters?.type ? 'active' : ''}`}>
                        <span>물품종류</span>
                        <IoChevronDown size="18px" />
                    </div>
                    <div onClick={() => setIsVisibleFilterModal(true)} className={`filter-item ${filters?.cat1 || filters?.cat2 || filters?.cat3 || filters?.cat4 ? 'active' : ''}`}>
                        <span>물품분류</span>
                        <IoChevronDown size="18px" />
                    </div>
                </div>
                <div className="container pb-3">
                    {totalCount === 0 ? <p className="fs-s text-orange mt-3">더이상 조회할 데이터가 없습니다</p> : null}
                    
                    {items.map(item => (
                        <div key={item.splyId} className="mobile-card">
                            <div className="d-flex align-items-center mb-2">
                                <div>
                                    <p className={`mobile-card-title`}>[{item.kind}]</p>&nbsp;
                                    <p className="mobile-card-title text-black">{item.cat1}</p>
                                </div>
                                <Link to={"/qr-scan-supplies/"+item.splyId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">선택하기</Link>
                            </div>
                            <div>
                                {item.cat2 ? <p className="mobile-card-text">{item.cat2}</p> : null}
                                {item.cat3 ? <p className="mobile-card-text">{item.cat3}</p> : null}
                                {item.cat4 ? <p className="mobile-card-text">{item.cat4}</p> : null}
                                <p className="mobile-card-text">{item.supplyCount}</p>
                            </div>
                        </div>
                    ))}

                    {fetchIsLoading || (totalCount !== null && totalCount > parameters?.reqPageSize) ? <button type="button" onClick={handleLoadMore} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">{fetchIsLoading ? 'Loading data' : 'View more ('+parameters?.reqPageSize+'/'+totalCount+')'}</button> : null}
                </div>
            </div>

            <FooterMobile user={user} active="QR Code" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />

            <Modal className="modal-search-address modal-mobile-filter" fullscreen={true} show={isVisibleFilterModal} onHide={() => setIsVisibleFilterModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>검색조건 설정</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex pt-0">
                    <div className="content-container container flex-fill">
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">물품종류</div>
                            <ReactSelect
                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                classNamePrefix="select"
                                className="dropdown-sm mr"
                                placeholder={"전체"}
                                value={filters?.type ? itemTypes.find(value => value.value == filters?.type) : null}
                                onChange={(e) => setFilters(current => ({...current, type: e.value}))}
                                isSearchable={false}
                                options={itemTypes}
                            />
                        </div>
                        <div className="form-group">
                            <div className="dms-form-label align-self-center">물품분류</div>
                            <div className="row gx-12px">
                                <div className="col-6 mb-12px">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        placeholder={"전체"}
                                        value={filters?.cat1 ? categories1.find(value => value.value == filters?.cat1) : null}
                                        onChange={(e) => setFilters(current => ({...current, cat1: e.value}))}
                                        isSearchable={false}
                                        options={categories1}
                                        noOptionsMessage={() => '데이터 없음'}
                                    />
                                </div>
                                <div className="col-6 mb-12px">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        placeholder={"전체"}
                                        value={filters?.cat2 ? categories2.find(value => value.value == filters?.cat2) : null}
                                        onChange={(e) => setFilters(current => ({...current, cat2: e.value}))}
                                        isSearchable={false}
                                        options={categories2}
                                        noOptionsMessage={() => '데이터 없음'}
                                    />
                                </div>
                                <div className="col-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        placeholder={"전체"}
                                        value={filters?.cat3 ? categories3.find(value => value.value == filters?.cat3) : null}
                                        onChange={(e) => setFilters(current => ({...current, cat3: e.value}))}
                                        isSearchable={false}
                                        options={categories3}
                                        noOptionsMessage={() => '데이터 없음'}
                                    />
                                </div>
                                <div className="col-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        placeholder={"전체"}
                                        value={filters?.cat4 ? categories4.find(value => value.value == filters?.cat4) : null}
                                        onChange={(e) => setFilters(current => ({...current, cat4: e.value}))}
                                        isSearchable={false}
                                        options={categories4}
                                        noOptionsMessage={() => '데이터 없음'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="p-0">
                    <div className="sticky-bottom mobile-bottom-btn-container m-0">
                        <div className="row g-0">
                            <div className="col-6">
                                <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-l dms-btn-filled-white disabled1">초기화</button>
                            </div>
                            <div className="col-6">
                                <button type="button" onClick={handleFilterSubmit} className="dms-btn dms-btn-l dms-btn-filled">검색</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export default BringingInReliefSuppliesMobile;