import { useState } from "react";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import { GoPlusCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { IoIosSearch } from "react-icons/io";
import DaumPostcode from "react-daum-postcode";

const SearchAddressModal = ({isVisible, setIsVisible, setZipCode=null, setAddress=null, fullscreen=false}) => {
    const [items, setItems] = useState([{id: 1}, {id: 2}, {id: 3}])
    const [value, setValue] = useState('')

    const handleSelectAddress = (data) => {
        if (setZipCode) setZipCode(data.zonecode);
        if (setAddress) setAddress(data.address);
        setIsVisible(false)
    }
    
    return (
        <Modal size="lg" className="modal-search-address" fullscreen={fullscreen} show={isVisible} onHide={() => setIsVisible(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>주소 찾기</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DaumPostcode
                    onComplete={handleSelectAddress}
                    autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                    //defaultQuery={mainAddress} // 팝업을 열때 기본적으로 입력되는 검색어
                />
            </Modal.Body>
        </Modal>
    );
}
 
export default SearchAddressModal;