import React, { useState, useEffect } from 'react';
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link, useParams } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import useFetch from '../useFetch';
import { useSelector } from 'react-redux';

const AdminShelterBelongNoticeDetails = () => {
    const { id } = useParams();
    const { user } = useSelector(state => state.user);

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/noti/getNotice?noticeId='+id
    
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [successDelete, setSuccessDelete] = useState(null);
    const [errorDelete, setErrorDelete] = useState(null);

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    
    const [data, setData] = useState(null)

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setData(fetchResponse?.resultObject)
        }
    }, [fetchResponse])

    const handleDelete = () => {
        setErrorDelete(null);
        setSuccessDelete(null);
        setIsLoadingDelete(true);

        var formData = {
            noticeId: id
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/noti/delNotice', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessDelete(data.responseMessage ?? 'Success')
            }
            else
            {
                setErrorDelete(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingDelete(false);
        })
        .catch(error => {
            setErrorDelete(error.message);
            setIsLoadingDelete(false);
        })
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <Link to="/shelter-admin-notices-belong-m" className="mobile-navbar-left plain-link">
                        <img src="/images/icons/back.svg" />
                    </Link>
                    <div className="mobile-navbar-title">공지사항</div>
                    <div className="blank-icon" />
                </div>
            </div>

            {successDelete ? (
                <>
                    <div className="container pt-4 pb-4 flex-fill text-center">
                        <img src="/images/gradient-check.svg" className="image-success-check mt-3" />
                        <p className="fs-m fw-bold mt-3">{successDelete}</p>
                        <p>리스트로 돌아가서 추가된 페이지를<br/>바로 확인하실 수 있습니다.</p>
                        <Link to={'/shelter-admin-notices-belong-m'} className="dms-btn dms-btn-m dms-btn-outline">Go back</Link>
                    </div>
                    <div className="sticky-bottom mobile-bottom-btn-container">
                        <Link to={'/shelter-admin-notices-belong-m'} className="dms-btn dms-btn-l dms-btn-filled">To List</Link>
                    </div>
                </>
            ) : (
                <>
                    <div className="content-container container flex-fill pb-4">
                        <div className="d-flex justify-content-between align-items-center mb-n2">
                            <p className="text-gray-03 fs-xs mb-0">{data?.updateDt ?? data?.createDt}</p>
                            <div className="d-flex align-items-center justify-content-between">
                                <Link to={"/shelter-admin-edit-notice-belong-m/"+data?.noticeId} className="dms-btn dms-btn-s dms-btn-outline mr">수정</Link>
                                <button type="button" onClick={handleDelete} className="dms-btn dms-btn-s dms-btn-outline">삭제</button>
                            </div>
                        </div>
                        <div className="mobile-card">
                            <div className="mb-1"><div dangerouslySetInnerHTML={{ __html: data?.memo }} /></div>
                        </div>
                    </div>

                    <div className="container content-container pt-0">
                        <Link to="/shelter-admin-notices-belong-m" className="dms-btn dms-btn-to-list-mobile dms-btn-outline w-100 px-0 align-self-center">돌아가기</Link>
                    </div>
                </>
            )}
        </div>
    );
}

export default AdminShelterBelongNoticeDetails;