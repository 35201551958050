import { useRef, useEffect, useState } from "react";
import { Alert} from "react-bootstrap";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { TbQrcode } from "react-icons/tb";
import { GoPlusCircle } from "react-icons/go";
import { AiOutlineFileExcel } from "react-icons/ai";
import ValidatorHelper from "../Helpers/ValidatorHelper";
import Footer from "../Components/Footer";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import { useNavigate } from "react-router-dom";

//PAGE-D303
const VolunteerRegister = () => {
    const navigate = useNavigate();

    const [isVisibleAddress, setIsVisibleAddress] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')    
    const [userId, setUserId] = useState('')    
    const [password, setPassword] = useState('')    
    const [passwordConfirm, setPasswordConfirm] = useState('')    
    const [bday, setBday] = useState('')        

    const [zipCode, setZipCode] = useState('')    
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [telNo, setTelNo] = useState('')

    const [email, setEmail] = useState('')
    const [vltrRegCenterNo, setVltrRegCenterNo] = useState('02')    //현장등록 default (1365 포털은 시스템 연계라, 현장등록으로 입력 함. )

    const [typeCd, setTypeCd] = useState('03')    // 03 : 자원봉사자


    const [passwordError, setPasswordError] = useState(null)    
    

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password !== passwordConfirm)
            {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password !== passwordConfirm)
        {
            setPasswordError('Both password must match')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password))
        {
            setError('Password must contain at least 8 characters including alphanumeric and special characters')
            return
        }
        if (password != passwordConfirm)
        {
            setError('Both password must match')
            return
        }
        if (!zipCode)
        {
            setError('Enter choose an address')
            return
        }

        setIsLoadingSubmit(true)
        
        var formData = {

            userId: userId,
            pwd: password,
            name: name,
            bday: bday,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: telNo,
            email: email,
            typeCd: "03"
        }
        console.log('formData -> ', formData); 

        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/regUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setName('')
                setUserId('')
                setPassword('')
                setPasswordConfirm('')
                setBday('')                
                setTelNo('')
                setZipCode('')
                setMainAddress('')
                setDetailedAddress('')
                setEmail('')
                setVltrRegCenterNo('')                
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })


    }    

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Volunteers</h3>
                <Link to={'/volunteer-management'} className="sidebar-link active">자원봉사자 등록/관리</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">자원봉사자 투입 현황</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <form onSubmit={handleSubmit} className="inner-container">
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">자원봉사자 등록하기</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <button className="dms-btn dms-btn-s dms-btn-outline mr" onClick={() => navigate("/volunteer-management")} >목록</button>
                                <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoadingSubmit ? 'disabled' : ''} `}>등록하기</button>
                            </div>
                        </div>

                        <div className="white-rounded-container mb-20px">

                            {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                            {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}


                            <div className="form-heading"><h5>개인정보</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl onChange={e => setName(e.target.value)} value={name} placeholder="이름을 입력하세요" required={true} />
                                    </div>
                                </div>
                            </div>
                            {/*
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">회원번호</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl placeholder="" disabled />
                                    </div>
                                </div>
                            </div>
    */}
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl onChange={e => setUserId(e.target.value)} value={userId} placeholder="아이디를 입력하세요" required={true}  />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container mb-2">
                                <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl onChange={e => setPassword(e.target.value)} value={password} className={passwordError ? 'is-invalid' : ''} placeholder="영문, 숫자, 특수문자 포함 8글자" required={true}  />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl onChange={e => setPasswordConfirm(e.target.value)} value={passwordConfirm} className={passwordError ? 'is-invalid' : ''} placeholder="비밀번호 재확인" required={true} />
                                        {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}                                        
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">생년월일<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl onChange={e => setBday(e.target.value)} value={bday}  placeholder="숫자만 입력하세요(YYYYMMDD)" required={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container mb-2">
                                <div className="dms-form-label align-self-center">주소<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl value={zipCode} placeholder="" disabled={true} />
                                    </div>
                                    <div className="dms-input-4">
                                        <button onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container mb-2">
                                <div className="dms-form-label"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl value={mainAddress} placeholder="" disabled={true}  />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl onChange={e => setDetailedAddress(e.target.value)} value={detailedAddress} placeholder="상세주소를 입력하세요" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">연락처<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl onChange={e => setTelNo(e.target.value)} value={telNo}  placeholder="연락처를 입력하세요" required={true}   />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">이메일 주소</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-4">
                                        <FormControl onChange={e => setEmail(e.target.value)} value={email}  placeholder="이메일 주소를 입력하세요"  />
                                    </div>
                                </div>
                            </div>
                            {/*}
                                <div className="form-group dms-input-container">
                                    <div className="dms-form-label align-self-center">등록센터</div>
                                    <div className="dms-input-row">
                                        <div className="dms-input-4">
                                            <FormControl onChange={e => setVltrRegCenterNo(e.target.value)} value={vltrRegCenterNo} placeholder="Please enter a name"  disabled />
                                        </div>
                                    </div>
                                </div>
                            */}
                        </div>
                    </form>

                    {/*
                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>파일 첨부</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label mt-2">사전 업로드</div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl placeholder="Please enter a name" />
                                    <div className="input-helper">최대 1장의 이미지 업로드 가능. 업로드 가능한 확장자: JPG, JPEG, PNG</div>
                                </div>
                                <div className="dms-input-4">
                                    <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />파일 찾기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                        */}
                </div>
                    
                <Footer />
            </div>

            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />

        </div>
    );
}
 
export default VolunteerRegister;