import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import {Link, useNavigate} from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";
import useFetchWithParameters from "../useFetchWithParameters";
import { format } from "date-fns";

const AdminShelterBelongInquiries = () => {
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getInqLst';

    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [parameters, setParameters] = useState({
        facilityId: user.facilityId,
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    const handleClickBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleLoadMore = () => {
        setParameters(current => ({...current, reqPageSize: current.reqPageSize+10}))
    }

    var lastDate = null

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }

    return (
      <div className="d-flex flex-column min-vh-100">
          <div className="sticky-top">
              <div className="mobile-navbar">
                  <div className="mobile-navbar-left">
                      <Link to="/shelter-admin-belong-m" className="mobile-navbar-left plain-link">
                          <img src="/images/icons/back.svg" />
                      </Link>
                  </div>
                  <div className="mobile-navbar-title">구호소 문의게시판</div>
                  <Link to={'/shelter-admin-register-inquiry-belong-m'} className="mobile-navbar-right plain-link">
                     {/* <img src="/images/icons/plus.svg" />*/}
                  </Link>
              </div>
          </div>

          <div className="content-container container flex-fill pb-4">
              {/*<div>
                  <Link to={'/shelter-victim-register-inquiry-belong-m'} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 align-self-center"><GoPlusCircle size="22px" />문의사항 신규등록</Link>
              </div>*/}
              {items.map(item => {
                  const newDate = format(new Date(item.createDt), 'yyyy년 MM월')
                  const dateToPrint = lastDate != newDate
                  lastDate = newDate

                  return (
                    <div key={item.boardId}>
                        {dateToPrint ? <p className="text-gray-03 fs-xs mb-n2 mt-3">{newDate}</p> : null}

                        <div className="mobile-card">
                            <div className="d-flex align-items-center mb-2">
                                <div>
                                    <p className={`mobile-card-title ${item.statusCd == '01' ? 'text-gray-04' : 'text-green'}`}>[{item.status}]</p>&nbsp;
                                    <p className="mobile-card-title d-block fw-bold text-black">{item.subject ?? 'Subject'}</p>
                                </div>
                                <Link to={
                                        item.statusCd == '01' ?
                                              `/shelter-admin-inquiry-details-belong-1-m/${item.boardId}` :
                                              `/shelter-admin-inquiry-details-belong-2-m/${item.boardId}`
                                        }
                                      className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline ms-auto">
                                    상세보기
                                </Link>
                            </div>
                            <p className="mb-2">{item.questionMemo}</p>
                            <p className="text-gray-04 fs-xxs mb-0">{item.createDt} | {item.writerName}</p>
                        </div>

                    </div>
                  )
              })}

              {fetchIsLoading || (totalCount !== null && totalCount > parameters?.reqPageSize) ? <button type="button" onClick={handleLoadMore} className="dms-btn dms-btn-s dms-btn-outline w-100 px-0 mt-3 align-self-center">{fetchIsLoading ? 'Loading data' : '더보기 ('+parameters?.reqPageSize+'/'+totalCount+')'}</button> : null}
          </div>

          <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
      </div>
    );
}

export default AdminShelterBelongInquiries;