import React, { useRef, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { Alert, FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

const AdminShelterBelongRegisterNotice = () => {
    const { user } = useSelector(state => state.user);

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [memo, setMemo] = useState('');
    const [subject, setSubject] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            memo: memo,
            subject: subject,
            facilityId: user?.facilityId,
            writerId: user?.userId,
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/noti/regNotice', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">공지사항 등록</div>
                    <Link to={"/shelter-admin-notices-belong-m"} className="mobile-navbar-right plain-link">
                        <img src="/images/icons/close.svg" />
                    </Link>
                </div>
            </div>
            {success ? (
                <>
                    <div className="container pt-4 pb-4 flex-fill text-center">
                        <img src="/images/gradient-check.svg" className="image-success-check mt-3" />
                        <p className="fs-m fw-bold mt-3">{success}</p>
                        <p>리스트로 돌아가서 추가된 페이지를<br/>바로 확인하실 수 있습니다.</p>
                       {/* <Link to={'/shelter-admin-notices-belong-m'} className="dms-btn dms-btn-m dms-btn-outline">Go back</Link>*/}
                    </div>
                    <div className="sticky-bottom mobile-bottom-btn-container">
                        <Link to={'/shelter-admin-notices-belong-m'} className="dms-btn dms-btn-l dms-btn-filled">돌아가기</Link>
                    </div>
                </>
            ) : (
                <>
                    <div className="content-container container flex-fill pb-2">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-group">
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">제목</div>
                                <FormControl value={subject} onChange={(e) => {setSubject(e.target.value)}}  />
                            </div>
                            <div className="dms-form-label align-self-center">내용</div>
                            <FormControl 
                                as="textarea"
                                required={true}
                                rows="10"
                                onChange={e => setMemo(e.target.value.substring(0,240))}
                                value={memo} />
                            <div className="input-helper text-end">{memo.length}/240</div>
                        </div>
                    </div>
                    <div className="sticky-bottom mobile-bottom-btn-container">
                        <button type="button" onClick={handleSubmit} className="dms-btn dms-btn-l dms-btn-filled">등록하기</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default AdminShelterBelongRegisterNotice;