/* global kakao */

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/joy';
import { Link, useNavigate } from "react-router-dom";
import useFetchWithParameters from './useFetchWithParameters';
import { FormControl } from 'react-bootstrap';

var prevInfoWindow =false; 

const parameters = {
    typeCd: '02',
    reqPageNo: 1,
    reqPageSize: 100000,
}

const KakaoMap = () => {
    let navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getFACLst';
    
    const [name, setName] = useState('')
    const [searchItems, setSearchItems] = useState([])

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);
    
    useEffect(() => {
        if (name)
        {
            fetch(process.env.REACT_APP_BASE_API_URL+'/fac/getFACLst?typeCd=02&reqPageNo=1&reqPageSize=5&name='+name)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }

                return response.json();
            })
            .then(data => {
                setSearchItems(data?.resultList)
            })
            .catch(error => {
                setSearchItems([])
            })
        }
        else
        {
            setSearchItems([])
        }
    }, [name])

    useEffect(() => {
        if (fetchResponse)
        {
            const locations = (fetchResponse?.resultList ?? [])?.map(value => ({
                facilityId: value.facilityId,
                lat: parseFloat(value.lat),
                lng: parseFloat(value.lot),
                name: value.name,
                currentVictimCount: value.currentVictimCount,
                // maxCapacity: value.maxCapacity,
                // saturation: value.currentVictimCount / value.maxCapacity,
                face: (value.currentVictimCount / value.maxCapacity > .5) ? 'images/marker-1.svg' : 'images/marker-2.svg',
            }))

            const script = document.createElement('script');
            script.async = true;
            script.src = `https://dapi.kakao.com/v2/maps/sdk.js?appkey=72d6e7807d642581357779b72d8ee9b6&libraries=services,drawing&autoload=false`;

            script.onload = () => {
                kakao.maps.load(() => {
                    const container = document.getElementById('map');
                    const options = {
                        center: new kakao.maps.LatLng(35.539847416955965, 129.31157760489216), // 울산광역시청
                        level: 7
                    };
                    const map = new kakao.maps.Map(container, options);
                    kakao.maps.event.addListener(map, 'click', function () {if (prevInfoWindow) { prevInfoWindow.close(); prevInfoWindow = false; }});
                    kakao.maps.event.addListener(map, 'dragstart', function () {if (prevInfoWindow) { prevInfoWindow.close(); prevInfoWindow = false; }});

                    locations.forEach((location, index) => {
                        const markerPosition = new kakao.maps.LatLng(location.lat, location.lng);

                        const customImageSrc = location.face;

                        const markerImage = new kakao.maps.MarkerImage(customImageSrc, new kakao.maps.Size(40, 40));

                        const marker = new kakao.maps.Marker({
                            position: markerPosition,
                            image: markerImage,
                            clickable: true,
                            zIndex: 3000,
                        });

                        const infowindow = new kakao.maps.InfoWindow({
                            content: `<div style="padding:10px; width:150px; font-size:14px; border: none !important; border-radius: 10px; z-index:5000">
                                <p class="text-center fw-bold mb-2">${location.name}</p>
                                <p class="text-center mb-0">현수용인원</p>
                                <p class="text-center fw-bold fs-xs mb-2">${location.currentVictimCount}</p>
                                <a href="/shelter-details/${location.facilityId}" class="w-100 dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active d-none d-md-block">자세히보기</a>
                                <a href="/shelter-details-no-belong-m/${location.facilityId}" class="d-block d-md-none w-100 dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active">자세히보기</a>

                            </div>`,
                            removable: true,
                        });

                        // Bind the click event to the marker
                        kakao.maps.event.addListener(marker, 'click', function () {
                            if (prevInfoWindow) 
                            {
                                prevInfoWindow.close()
                            }

                            prevInfoWindow = infowindow

                            infowindow.open(map, marker);
                        });

                        marker.setMap(map);
                    });
                });
            };

            document.head.appendChild(script);

            return () => {
                // Cleanup script element to avoid memory leaks
                document.head.removeChild(script);
            };
        }
    }, [fetchResponse])

    return (
        <Box>
            <div className="map_wrap">
                <div className="menu_wrap">
                    <div className='map-search-container ms-lg-3'>
                        <div className="map-search-input-container mt-lg-0 mt-3">
                            <FormControl
                                type="email"
                                className='ps-2'
                                autoComplete='off'
                                onChange={e => setName(e.target.value)}
                                value={name}
                                placeholder="구호소명을 입력하세요." />
                            <div className='map-search-input-box-btn'><img src='images/search-icon.png' className='map-searchbox-icon' /></div>
                        </div>
                        {name && searchItems?.length > 0 ? (
                            <div className='map-search-results'>
                                {searchItems.map(item => {
                                    var shelterName = item.name;
                                    var index = shelterName.toLowerCase().indexOf(name.toLowerCase());
                                    if (index >= 0) { 
                                        shelterName = shelterName.substring(0,index) + "<span class='highlight'>" + shelterName.substring(index,index+name.length) + "</span>" + shelterName.substring(index + name.length);
                                    }
                                    return (
                                        <Link to={"/shelter-details-no-belong-m/"+item.facilityId} className='map-search-item plain-link'>
                                            <p dangerouslySetInnerHTML={{ __html: shelterName }}></p>
                                        </Link>
                                    )
                                })}
                            </div>
                        ) : null}
                    </div>
                    <ul id="placesList" />
                    <div id="pagination" />
                </div>

                <div id="map" style={{ width: '100%' }}></div>

            </div>
        </Box>
    );
};

export default KakaoMap;
