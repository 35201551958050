import { useState, useEffect } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";

const OutgoingSupplies = () => {
    const [selectedItems, setSelectedItems] = useState([])

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getSplyReqLst';

    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [isMyRequests, setIsMyRequests] = useState(false)

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [successDelete, setSuccessDelete] = useState(null);
    const [errorDelete, setErrorDelete] = useState(null);

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [facilityType, setFacilityType] = useState(null)

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])

    const [parameters, setParameters] = useState({
        typeCd: '02',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse) {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])


    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }

    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호물품</h3>
                <Link to={'/inventory-management'} className="sidebar-link">구호물품 재고 관리</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">비축창고 등록/관리</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">구호물품 입고 내역</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">구호물품 출고 내역</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">구호물품 요청 현황</h3>
                        <Link to="/register-for-supplies" className="dms-btn dms-btn-s dms-btn-outline">등록</Link>
                    </div>
                    <div className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">상태</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: 'All'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: 'All' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">물품 종류</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-4">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: 'All'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: 'All' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">물품 분류</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: '전체'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: '전체' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: '전체'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: '전체' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: '전체'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: '전체' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: '전체'}}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: '전체' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                            <button className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                        </div>
                    </div>
                    <div className="table-actions-container mb-20px">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <ReactSelect
                                    components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                    classNamePrefix="select"
                                    className="dropdown-sm mr"
                                    value={{label: '10개씩 보기'}}
                                    isSearchable={false}
                                    options={[
                                        { value: 'View By 10', label: '10개씩 보기' },
                                        { value: 'View By 20', label: '20개씩 보기' },
                                        { value: 'View By 30', label: '30개씩 보기' },
                                    ]}
                                />
                                <div 
                                    className={`dms-chip-btn dms-chip-btn-s ${isMyRequests ? 'dms-chip-btn-active' : ''} align-self-center mr`}
                                    onClick={() => setIsMyRequests(current => !current)}>
                                        <FormCheck checked={true} />
                                        내 요청만 보기</div>
                                <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> 엑셀저장</button>
                            </div>
                        </div>
                    </div>
                    <table className="table dms-table-seperate">
                        <thead>
                            <tr>
                                <th>물품 종류</th>
                                <th>물품 분류</th>
                                <th>구호소명</th>
                                <th>등록일시</th>
                                <th>상태</th>
                                <th>수량</th>
                                <th>상세보기</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(value => (
                                <tr key={value.reqId}>
                                    <td>{value.kind}</td>
                                    <td>{value.itemName}</td>
                                    <td>{value.facilityName}</td>
                                    <td>{value.requestedDt}</td>
                                    <td>{value.status}</td>
                                    <td>{value.quantity}</td>
                                    {/* <td className={value.id%2 == 0 ? '' : 'text-green'}>{value.id%2 == 0 ? 'Stand By' : 'Done'}</td> */}
                                    <td style={{ width: "120px" }}><Link to={value.id%2 == 0 ? "/requests-for-supplies-details-1" : "/requests-for-supplies-details-2"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">상세보기</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount / parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                            forcePage={Math.max(parameters.reqPageNo - 1, 0)}
                            pageCount={Math.ceil(totalCount / parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>
                    
                <Footer />
            </div>

            <SaveExcelModal isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}
 
export default OutgoingSupplies;