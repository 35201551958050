import { useState, useEffect } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";

const VolunteerRequests = () => {
    const [selectedItems, setSelectedItems] = useState([])

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/vltr/getVltrReqLst';

    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [successDelete, setSuccessDelete] = useState(null);
    const [errorDelete, setErrorDelete] = useState(null);

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [facilityType, setFacilityType] = useState(null)

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])

    const [parameters, setParameters] = useState({
        typeCd: '02',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse) {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])


    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">Volunteers</h3>
                <Link to={'/volunteer-management'} className="sidebar-link">Volunteers management</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">Volunteers assignment</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">Requests for volunteers</h3>
                        <Link to="/volunteer-requests-register" className="dms-btn dms-btn-s dms-btn-outline">Register</Link>
                    </div>
                    <div className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Select a region/shelter</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Ulsen City'}
                                            isSearchable={false}
                                            isDisabled={true}
                                            options={[
                                                { value: 'Ulsen City', label: 'Ulsen City' },
                                                { value: 'Test', label: 'Test' },
                                                { value: 'Test Test', label: 'Test Test' }
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'전체'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'Entire city', label: 'Entire city' },
                                                { value: 'Area 1', label: 'Area 1' },
                                                { value: 'Area 2', label: 'Area 2' },
                                                { value: 'Area 3', label: 'Area 3' },
                                            ]}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'전체'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All shelter', label: 'All shelter' },
                                                { value: 'Shelter 1', label: 'Shelter 1' },
                                                { value: 'Shelter 2', label: 'Shelter 2' },
                                                { value: 'Shelter 3', label: 'Shelter 3' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Request roles</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            placeholder={'Finding'}
                                            isSearchable={false}
                                            options={[
                                                { value: 'Finding', label: 'Finding' },
                                                { value: 'Shelter 1', label: 'Shelter 1' },
                                                { value: 'Shelter 2', label: 'Shelter 2' },
                                                { value: 'Shelter 3', label: 'Shelter 3' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-xs dms-btn-outline mr">Initialize</button>
                            <button className="dms-btn dms-btn-xs dms-btn-filled">Search</button>
                        </div>
                    </div>
                    <div className="table-actions-container mb-20px">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p className="search-result">Search Result <span>{totalCount}</span> Items</p>
                            </div>
                            <div className="d-flex justify-content-end">
                                <ReactSelect
                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                    classNamePrefix="select"
                                    className="dropdown-sm mr"
                                    value={{ label: 'View By 10' }}
                                    isSearchable={false}
                                    options={[
                                        { value: 'View By 10', label: 'View By 10' },
                                        { value: 'View By 20', label: 'View By 20' },
                                        { value: 'View By 30', label: 'View By 30' },
                                    ]}
                                />
                                <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> Save Excel</button>
                            </div>
                        </div>
                    </div>
                    <table className="table dms-table-seperate">
                        <thead>
                            <tr>
                                <th>City/Province</th>
                                <th>District</th>
                                <th>Request relief station</th>
                                <th>Request role</th>
                                <th>Requested by</th>
                                <th>Phone number</th>
                                <th>First request time</th>
                                <th>Status</th>
                                <th>View Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(value => (
                                <tr key={value.reqId}>
                                    <td>{value.city}</td>
                                    <td>{value.dist}</td>
                                    <td>{value.facName}</td>
                                    <td>{value.reqRolesName}</td>
                                    <td>{value.writerName}</td>
                                    <td>{value.writerTelno}</td>
                                    <td>{value.reqDt}</td>
                                    <td>{value.statusName}</td>
                                    {/* <td className={value.id % 2 == 0 ? 'text-green' : ''}>{value.id % 2 == 0 ? 'Complete assignment' : 'Waiting'}</td> */}
                                    <td style={{ width: "120px" }}><Link to={value.id % 2 == 0 ? "/volunteer-requests-details-2" : "/volunteer-requests-details-1"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount / parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                            forcePage={Math.max(parameters.reqPageNo - 1, 0)}
                            pageCount={Math.ceil(totalCount / parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>

                <Footer />
            </div>

            <SaveExcelModal isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}

export default VolunteerRequests;