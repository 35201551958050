import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";

//PAGE-D301
const VolunteerManagement = () => {

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/vltr/getVltrLst';

    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)

    const [successDelete, setSuccessDelete] = useState(null);
    const [errorDelete, setErrorDelete] = useState(null);

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [shelter, setShelter] = useState(null)

    //search : select box
    const [vltrRegCenterNo, setVltrRegCenterNo] = useState(null)    

    						
    
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [shelters, setShelters] = useState([])
    
    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);    

    useEffect(() => {
        if (fetchResponse)
        {
            console.log("res data->", fetchResponse?.resultList ?? []);

            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...filters,
            ...(vltrRegCenterNo?.value ? {vltrRegCenterNo: vltrRegCenterNo.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
            ...(shelter?.value ? {facilityId: shelter.value} : null),
        }))
    }
    
    const initializeFilter = () => {
        setFilters({});
        setVltrRegCenterNo(null);
        setArea2(null)
        setShelter(null)
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }


    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/vltr/expVltrLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }    
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">자원봉사자</h3>
                <Link to={'/volunteer-management'} className="sidebar-link active">자원봉사자 등록/관리</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">자원봉사자 투입 현황</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">자원봉사자 등록/관리</h3>
                        <Link to="/volunteer-register" className="dms-btn dms-btn-s dms-btn-outline">등록</Link>
                    </div>

                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">

                        <div className="white-rounded-container mb-20px">
                            <div className="row mb-3">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <div className="dms-filter-label">등록센터/성명</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gx-20px">
                                        <div className="col-lg-3">
                                            <ReactSelect
                                                components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                                classNamePrefix="select"
                                                isSearchable={false}                                                
                                                placeholder={'전체'}
                                                value={vltrRegCenterNo ?? null}
                                                onChange={(e) => setVltrRegCenterNo(e)}
                                                options={[
                                                    { value: '01', label: '1365포털' },
                                                    { value: '02', label: '현장등록' },
                                                ]}
                                            />
                                        </div>
                                        <div className="col-lg-5">
                                            <FormControl 
                                            value={filters?.name ?? ''}
                                            onChange={e => setFilters(current => ({...current, name: e.target.value}))}
                                            placeholder="이름을 입력하세요." />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <div className="dms-filter-label">생년월일</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gx-20px">
                                        <div className="col-lg-6">

                                            <FormControl 
                                            value={filters?.bday ?? ''}
                                            onChange={e => setFilters(current => ({...current, bday: e.target.value}))}
                                            placeholder="숫자만 입력하세요.(YYYYMMDD)" />



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-2 d-flex align-items-center">
                                    <div className="dms-filter-label">연락처</div>
                                </div>
                                <div className="col-lg-10">
                                    <div className="row gx-20px">
                                        <div className="col-lg-6">
                                            <FormControl 
                                            value={filters?.telNo ?? ''}
                                            onChange={e => setFilters(current => ({...current, telNo: e.target.value}))}
                                            placeholder="연락처를 입력하세요." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                                <button type="button" onClick={initializeFilter}  className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                            </div>
                        </div>
                    </form>

                    {totalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                    {totalCount !== 0 ? (
                        <div className="table-actions-container mb-20px">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    { fetchError ? <p className="search-result text-orange">{fetchError}</p> : null }
                                    { fetchIsLoading ? <p className="search-result">Loading data</p> : 
                                        totalCount !== null ? <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        value={{label: parameters.reqPageSize + '개씩 보기'}}
                                        onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                        isSearchable={false}
                                        options={[
                                            { value: 10, label: '10개씩 보기' },
                                            { value: 20, label: '20개씩 보기' },
                                            { value: 30, label: '30개씩 보기' },
                                        ]}
                                    />
                                    <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> 엑셀저장</button>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {successDelete ? <Alert variant="success">{successDelete ?? 'Success'}</Alert> : null}
                    {errorDelete ? <Alert variant="danger">{errorDelete ?? 'Error'}</Alert> : null}

                    
                    { totalCount !== null && totalCount !== 0 ? (                                            
                        <table className="table dms-table-seperate">
                            <thead>
                                <tr>
                                    <th>등록센터</th>
                                    <th>성명</th>
                                    <th>생년월일</th>
                                    <th>연락처</th>
                                    <th>이메일주소</th>
                                    <th>상세보기</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={item.userId}>
                                        <td>{item.vltrRegCenterName}</td>
                                        <td>{item.name}</td>
                                        <td>{item.bday}</td>
                                        <td>{item.telNo}</td>
                                        <td>{item.email}</td>
                                        <td style={{ width: "120px" }}><Link to={"/volunteer-details/"+item.userId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">상세보기</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({...current, reqPageNo: e.selected+1}))}
                            forcePage={Math.max(parameters.reqPageNo-1, 0)}
                            pageCount={Math.ceil(totalCount/parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                    </div>
                    
                <Footer />
            </div>

            <SaveExcelModal handleSaveExcel={handleSaveExcel}  isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}
 
export default VolunteerManagement;