import { useEffect, useState } from "react";
import { Alert, FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TbQrcode } from "react-icons/tb";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import DeleteSelectionModal from "./../Components/Modals/DeleteSelectionModal"
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";

const VictimManagement = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/victim/getVCTMLst';
    
    const [selectedItems, setSelectedItems] = useState([])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [isVisibleDelete, setIsVisibleDelete] = useState(false)
    const [isVisiblePrintQr, setIsVisiblePrintQr] = useState(false)
    const [isVisibleErrorDeviceOuput, setIsVisibleErrorDeviceOuput] = useState(false)
    const [isVisibleErrorCenter, setIsVisibleErrorCenter] = useState(false)

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [successDelete, setSuccessDelete] = useState(null);
    const [errorDelete, setErrorDelete] = useState(null);

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [shelter, setShelter] = useState(null)
    
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [shelters, setShelters] = useState([])

    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...filters,
            ...(area1?.value ? {area1Cd: area1.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
            ...(shelter?.value ? {facilityId: shelter.value} : null),
        }))
    }

    const initializeFilter = () => {
        setFilters({});
        setArea2(null)
        setShelter(null)
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if(areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!area2) return
        setShelter(null)

        ApiHelper.loadDropdown('area2Cd='+area2?.value+'&typeCd=02', setShelters, 'fac/getFACLst', 'facilityId')
    }, [area2])

    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id))
            {
                return current.filter(value => value != id)
            }
            else
            {
                return [...current, id]
            }
        })
    }

    const handleDelete = () => {
        if (selectedItems.length == 0) return
        
        setErrorDelete(null)
        setSuccessDelete(null)
        setIsLoadingDelete(true)
        
        var formData = {
            userIdList: selectedItems,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/delWIVCTM', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccessDelete(data.responseMessage ?? 'Success')
                setParameters(current => ({...current, deleteRefresh: Math.random().toString(36).substring(2,10)}))
                setSelectedItems([])
            }
            else
            {
                setErrorDelete(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingDelete(false);
        })
        .catch(error => {
            setErrorDelete(error.message);
            setIsLoadingDelete(false);
        })
    }
    
    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/expVctmLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">이재민</h3>
                <Link to={'/victim-management'} className="sidebar-link active">이재민 등록/관리</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">이재민 등록/관리</h3>
                        <Link to="/victim-register" className="dms-btn dms-btn-s dms-btn-outline">등록</Link>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">재난</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'선택'}
                                            value={area1 ?? null}
                                            onChange={(e) => setArea1(e)}
                                            options={areas1}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'전체'}
                                            value={area2 ?? null}
                                            onChange={(e) => setArea2(e)}
                                            options={areas2}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'전체'}
                                            value={shelter ?? null}
                                            onChange={(e) => setShelter(e)}
                                            options={shelters}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">성명</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-5">
                                        <FormControl 
                                            value={filters?.name ?? ''}
                                            onChange={e => setFilters(current => ({...current, name: e.target.value}))}
                                            placeholder="성명을 입력하시오." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                        </div>
                    </form>

                    {totalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                    {totalCount !== 0 ? (
                        <div className="table-actions-container mb-20px">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    { fetchError ? <p className="search-result text-orange">{fetchError}</p> : null }
                                    { fetchIsLoading ? <p className="search-result">Loading data</p> : 
                                        totalCount !== null ? <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        value={{label: parameters.reqPageSize + '개씩 보기'}}
                                        onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                        isSearchable={false}
                                        options={[
                                            { value: 10, label: '10개씩 보기' },
                                            { value: 20, label: '20개씩 보기' },
                                            { value: 30, label: '30개씩 보기' },
                                        ]}
                                    />
                                    {selectedItems.length > 0 ? (
                                        <>
                                            <button className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr">데이터 이관</button>
                                            <button type="button" onClick={() => setIsVisiblePrintQr(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><TbQrcode  size="16px" /> QR 코드</button>
                                        </>
                                    ) : null}
                                    <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" />엑셀저장</button>
                                    {/* <button type="button" onClick={() => setIsVisibleDelete(true)}  className={`dms-btn dms-btn-s dms-btn-sm dms-btn-outline ${selectedItems.length == 0 || isLoadingDelete ? 'disabled' : ''}`}>선택삭제</button> */}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {successDelete ? <Alert variant="success">{successDelete ?? 'Success'}</Alert> : null}
                    {errorDelete ? <Alert variant="danger">{errorDelete ?? 'Error'}</Alert> : null}

                    { totalCount !== null && totalCount !== 0 ? (
                        <table className="table dms-table-seperate">
                            <thead>
                                <tr>
                                    {/* <th><FormCheck checked={selectedItems.length == items.length} onChange={() => setSelectedItems(selectedItems.length == items.length ? [] : items.map(value => value.userId))} /></th> */}
                                    <th>성명</th>
                                    <th>주민등록번호</th>
                                    <th>거주지 주소</th>
                                    <th>연락처</th>
                                    <th>영유아</th>
                                    <th>입소 구호소</th>
                                    <th>상세보기</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={item.userId} className={selectedItems.includes(item.userId) ? 'row-checked' : ''}>
                                        {/* <td><FormCheck checked={selectedItems.includes(item.userId)} onChange={() => handleCheckItem(item.userId)} /></td> */}
                                        <td>{item.name}</td>
                                        <td>{item.regNo}</td>
                                        <td>{item.wholeAddress}</td>
                                        <td>{item.telNo}</td>
                                        <td>{item.infantListString}</td>
                                        <td>{item.shelterName}</td>
                                        <td style={{ width: "120px" }}><Link to={"/victim-details/"+item.userId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">상세보기</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({...current, reqPageNo: e.selected+1}))}
                            forcePage={Math.max(parameters.reqPageNo-1, 0)}
                            pageCount={Math.ceil(totalCount/parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>
                    
                <Footer />
            </div>
            
            <SaveExcelModal handleSaveExcel={handleSaveExcel} isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
            
            <DeleteSelectionModal handleDelete={handleDelete} isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />

            <Modal show={isVisiblePrintQr} onHide={() => setIsVisiblePrintQr(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Print QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Do you want to Print a QR code for the selected items?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisiblePrintQr(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={() => setIsVisiblePrintQr(false)} className="dms-btn dms-btn-xs dms-btn-filled">Print</button>
                </Modal.Footer>
            </Modal>

            <Modal show={isVisibleErrorDeviceOuput} onHide={() => setIsVisibleErrorDeviceOuput(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>No devices capable of output are detected.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleErrorDeviceOuput(false)} className="dms-btn dms-btn-xs dms-btn-outline">OK</button>
                </Modal.Footer>
            </Modal>

            <Modal show={isVisibleErrorCenter} onHide={() => setIsVisibleErrorCenter(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please select a center</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleErrorCenter(false)} className="dms-btn dms-btn-xs dms-btn-outline">OK</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
 
export default VictimManagement;