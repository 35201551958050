import { useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import DeleteSelectionModal from "../Components/Modals/DeleteSelectionModal";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";

const optionsStatus = [
    { value: '01', label: '예정' },
    { value: '02', label: '진행중' },
    { value: '03', label: '상황종료' },
]

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }

const DisasterManagement = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';
    const allListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';
    
    const [selectedItems, setSelectedItems] = useState([])
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    const [isVisibleDelete, setIsVisibleDelete] = useState(false)

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [years, setYears] = useState([])
    
    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);

    useEffect(() => {
        if (allListFetchResponse)
        {
            const years = []
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt)
                {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt)
                {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({value: value, label: value})))
        }
    }, [allListFetchResponse])

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({...current, reqPageNo: 1, ...filters}))
    }

    const initializeFilter = () => {
        setFilters({});
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/cfg/expDisasterCd?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">시스템 관리</h3>
                <Link to={'/statistics'} className="sidebar-link">통계자료</Link>
                <Link to={'/user-management'} className="sidebar-link">사용자 등록/관리</Link>
                <Link to={'/disaster-management'} className="sidebar-link active">재난코드 등록/관리</Link>
                <Link to={'/notices-news'} className="sidebar-link">공지사항</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">재난코드 등록/관리</h3>
                        <Link to="/disaster-register" className="dms-btn dms-btn-s dms-btn-outline">등록</Link>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">년도/재난</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'전체'}
                                            value={filters?.year ? {label: filters?.year} : null}
                                            onChange={(e) => setFilters(current => ({...current, year: e.value}))}
                                            isSearchable={false}
                                            options={years}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">상태</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"전체"}
                                            value={filters?.status ? optionsStatus.find(value => value.value == filters?.status) : null}
                                            onChange={(e) => setFilters(current => ({...current, status: e.value}))}
                                            isSearchable={false}
                                            options={optionsStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                        </div>
                    </form>

                    {totalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                    {totalCount !== 0 ? (
                        <div className="table-actions-container mb-20px">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    { fetchError ? <p className="search-result text-orange">{fetchError}</p> : null }
                                    { fetchIsLoading ? <p className="search-result">Loading data</p> : 
                                        totalCount !== null ? <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        value={{label: parameters.reqPageSize + '개씩 보기'}}
                                        onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                        isSearchable={false}
                                        options={[
                                            { value: 10, label: '10개씩 보기' },
                                            { value: 20, label: '20개씩 보기' },
                                            { value: 30, label: '30개씩 보기' },
                                        ]}
                                    />
                                    <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> 엑셀저장</button>
                                    {/* <button type="button" onClick={() => setIsVisibleDelete(true)}  className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">Delete Selection</button> */}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    { totalCount !== null && totalCount !== 0 ? (
                        <table className="table dms-table-seperate">
                            <thead>
                                <tr>
                                    {/* <th><FormCheck checked={items.length > 0 && selectedItems.length == items.length} onClick={() => setSelectedItems(selectedItems.length == items.length ? [] : items.map(value => value.disasterCd))} /></th> */}
                                    <th>재난명</th>
                                    <th>설명</th>
                                    <th>발생일</th>
                                    <th>종료일</th>
                                    <th>상태</th>
                                    <th>상세보기</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={item.disasterCd} className={selectedItems.includes(item.disasterCd) ? 'row-checked' : ''}>
                                        {/* <td><FormCheck checked={selectedItems.includes(item.disasterCd)} onChange={() => handleCheckItem(item.disasterCd)} /></td> */}
                                        <td>{item.name}</td>
                                        <td>{item.memo}</td>
                                        <td>{item.startDt}</td>
                                        <td>{item.endDt}</td>
                                        <td className={item.status == '03' ? '' : item.status == '02' ? 'text-orange' : 'text-green'}>{item.statusName}</td>
                                        <td style={{ width: "120px" }}><Link to={"/disaster-details/"+item.disasterCd} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">상세보기</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({...current, reqPageNo: e.selected+1}))}
                            forcePage={Math.max(parameters.reqPageNo-1, 0)}
                            pageCount={Math.ceil(totalCount/parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>
                    
                <Footer />
            </div>

            <SaveExcelModal handleSaveExcel={handleSaveExcel} isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />

            <DeleteSelectionModal isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />
        </div>
    );
}
 
export default DisasterManagement;