import { useEffect, useState } from "react";
import { Alert, FormControl, FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const VictimRegister = () => {
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [socialNumber, setSocialNumber] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const [headHousehold, setHeadHousehold] = useState('')
    const [specialNotes, setSpecialNotes] = useState([])
    const [infants, setInfants] = useState([])

    const [specialNoteCategory, setSpecialNoteCategory] = useState(null)
    const [specialNoteSymptom, setSpecialNoteSymptom] = useState('')
    const [infantName, setInfantName] = useState('')
    const [infantSocialNumber, setInfantSocialNumber] = useState('')
    
    const [passwordError, setPasswordError] = useState(null)
    
    const [specialNoteCategories, setSpecialNoteCategories] = useState([])
    
    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D004&codeDepth=1', setSpecialNoteCategories)
    }, [])

    useEffect(() => {
        if(isHeadHousehold)
        {
            setHeadHousehold(name)
        }
    }, [name])

    useEffect(() => {
        if(isHeadHousehold)
        {
            setHeadHousehold(name)
        }
    }, [isHeadHousehold])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password != passwordConfirm)
            {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm)
        {
            setPasswordError('Both password must match')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleAddSpecialNotes = () => {
        if (specialNoteCategory && specialNoteSymptom)
        {
            setSpecialNotes(current => [...current, {id: Math.random().toString(36).substring(2,10), noteCd: specialNoteCategory.value, note: specialNoteCategory.label, symptom: specialNoteSymptom}])
            setSpecialNoteCategory(null)
            setSpecialNoteSymptom('')
        }
    }

    const handleRemoveSpecialNotes = (item) => {
        setSpecialNotes(current => current.filter(value => value.id != item.id))
    }

    const handleAddInfant = () => {
        if (infantName && infantSocialNumber)
        {
            setInfants(current => [...current, {id: Math.random().toString(36).substring(2,10), name: infantName, regNo: infantSocialNumber}])
            setInfantName('')
            setInfantSocialNumber('')
        }
    }

    const handleRemoveInfant = (item) => {
        setInfants(current => current.filter(value => value.id != item.id))
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password))
        {
            setError('비밀번호는 영문 대소문자, 숫자, 특수문자 조합 8자리 이상이어야 합니다.')
            return
        }
        if (password != passwordConfirm)
        {
            setError('비밀번호가 일치하지 않습니다.')
            return
        }
        if (!zipCode)
        {
            setError('주소를 입력하세요.')
            return
        }

        setIsLoadingSubmit(true)
        
        var infantsList = infants.map(value => ({name: value.name, regNo: value.regNo}))
        var victimNoteList = specialNotes.map(value => ({noteCd: value.noteCd, symptom: value.symptom}))
        
        var formData = {
            userId: userId,
            pwd: password,
            name: name,
            bday: "",
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: "",
            typeCd: "02",
            regNo: socialNumber,
            hshdName: headHousehold,
            hshdYn: isHeadHousehold ? 'Y' : 'N',
            outYn: "N",
            delYn: "N",
            infantsList: infantsList,
            victimNoteList: victimNoteList,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/regVCTM', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setName('')
                setUserId('')
                setPassword('')
                setPasswordConfirm('')
                setSocialNumber('')
                setPhoneNumber('')
                setZipCode('')
                setMainAddress('')
                setDetailedAddress('')
                setHeadHousehold('')
                setSpecialNotes([])
                setInfants([])
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">이재민</h3>
                <Link to={'/victim-management'} className="sidebar-link active">이재민 등록/관리</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">이재민 등록</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <Link to="/victim-management" className="dms-btn dms-btn-s dms-btn-outline mr">닫기</Link>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoadingSubmit ? 'disabled' : ''}`}>등록</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>개인정보</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        placeholder="이름을 입력하세요."
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setUserId(e.target.value)}
                                        value={userId}
                                        placeholder="영문, 숫자, 특수문자 조합"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        className={passwordError ? 'is-invalid' : ''}
                                        placeholder="특수문자, 영문을 사용한 6~12 자리"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setPasswordConfirm(e.target.value)}
                                        value={passwordConfirm}
                                        className={passwordError ? 'is-invalid' : ''}
                                        placeholder="비밀번호 확인"
                                        required={true} />
                                    {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">주민등록번호<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setSocialNumber(e.target.value)}
                                        value={socialNumber}
                                        placeholder="숫자만 입력하세요."
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">연락처<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        value={phoneNumber}
                                        placeholder="숫자만 입력하세요."
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">주소</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        value={zipCode}
                                        // placeholder="Please choose address"
                                        disabled={true} />
                                </div>
                                <div className="dms-input-4">
                                    <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        value={mainAddress}
                                        //placeholder=""
                                        disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setDetailedAddress(e.target.value)}
                                        value={detailedAddress}
                                        placeholder="상세주소를 입력하세요.(동, 호수)"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">세대주 성명</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setHeadHousehold(e.target.value)}
                                        value={headHousehold}
                                        placeholder="세대주 성명을 입력하세요."
                                        required={true}
                                        disabled={isHeadHousehold} />
                                </div>
                                <div className="dms-input-4">
                                    <div 
                                        className={`dms-chip-btn dms-chip-btn-s ${isHeadHousehold ? 'dms-chip-btn-active' : ''} align-self-center`}
                                        onClick={() => setIsHeadHousehold(current => !current)}>
                                            <FormCheck checked={true} />
                                            본인 세대주</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading">
                            <h5>특이사항 입력사항</h5>
                            <p>질병 혹은 임신 사실을 적어 두시면, 보다 원활한 케어가 가능합니다.</p>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">특이사항/설명</div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        placeholder={'선택'}
                                        value={specialNoteCategory ?? null}
                                        onChange={(e) => setSpecialNoteCategory(e)}
                                        options={specialNoteCategories}
                                    />
                                </div>
                                <div className="dms-input-7">
                                    <FormControl
                                        onChange={e => setSpecialNoteSymptom(e.target.value)}
                                        value={specialNoteSymptom}
                                        placeholder="입력하신 특이사항 증상에 대해 입력해주세요." />
                                </div>
                                <div className="dms-input-2">
                                    <button type="button" onClick={handleAddSpecialNotes} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />추가</button>
                                </div>
                            </div>
                        </div>

                        {specialNotes.length > 0 ? (
                            <table className="table dms-table-1">
                                <thead>
                                    <tr>
                                        <th>특이사항</th>
                                        <th>증상</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {specialNotes.map(specialNote => (
                                        <tr key={specialNote.id}>
                                            <td>{specialNote.note}</td>
                                            <td>{specialNote.symptom}</td>
                                            <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveSpecialNotes(specialNote)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">삭제</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading">
                            <h5>영유아, 어린이 추가</h5>
                            <p>자신의 의사표현이 불가능한 영유아/어린이 정보를 입력하여, 부모와 같이 검색되도록 추가등록합니다.(ex. 현재년도 기준 6년 이전 출생자, 만 6세까지만 등록이 가능합니다.)</p>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">영유아 성함</div>
                            <div className="dms-input-row">
                                <div className="dms-input-3">
                                    <FormControl
                                        onChange={e => setInfantName(e.target.value)}
                                        value={infantName}
                                        placeholder="성함을 입력해주세요." />
                                </div>
                                <div className="dms-input-2 d-flex">
                                    <div className="dms-form-label align-self-center">주민등록번호</div>
                                </div>
                                <div className="dms-input-5">
                                    <FormControl
                                        onChange={e => setInfantSocialNumber(e.target.value)}
                                        value={infantSocialNumber}
                                        placeholder="주민등록번호를 입력해주세요." />
                                </div>
                                <div className="dms-input-2">
                                    <button type="button" onClick={handleAddInfant} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />추가</button>
                                </div>
                            </div>
                        </div>

                        {infants.length > 0 ? (
                            <table className="table dms-table-1">
                                <thead>
                                    <tr>
                                        <th>영유아 성함</th>
                                        <th>주민등록번호</th>
                                        <th>관리</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {infants.map(infant => (
                                        <tr key={infant.id}>
                                            <td>{infant.name}</td>
                                            <td>{infant.regNo}</td>
                                            <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveInfant(infant)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">삭제</button></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : null}
                    </div>
                </form>
                    
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />
        </div>
    );
}
 
export default VictimRegister;