import React, { useState, useEffect } from 'react';
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link, useParams } from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import { useSelector } from 'react-redux';
import useFetch from '../useFetch';

const VictimShelterBelongNoticeDetails = () => {
    const { id } = useParams();
    const { user } = useSelector(state => state.user);

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/noti/getNotice?noticeId='+id
    
    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    
    const [data, setData] = useState(null)

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setData(fetchResponse?.resultObject)
        }
    }, [fetchResponse])

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <Link to="/shelter-victim-notices-belong-m" className="mobile-navbar-left plain-link">
                        <img src="/images/icons/back.svg" />
                    </Link>
                    <div className="mobile-navbar-title">공지사항</div>
                    <div className="blank-icon" />
                </div>
            </div>

            <div className="content-container container flex-fill pb-4">
                <p className="text-gray-03 fs-xs mb-n2">{data?.updateDt ?? data?.createDt}</p>
                <div className="mobile-card">
                    <p className="mb-1">{data?.subject}</p>
                    <div className="mb-1"><div dangerouslySetInnerHTML={{ __html: data?.memo }} /></div>
                    <p className="mb-1">{data?.writerName}</p>
                    <p className="mb-1">{data?.facilityName}</p>
                </div>
            </div>

            <div className="container content-container pt-0">
                <Link to="/shelter-victim-notices-belong-m" className="dms-btn dms-btn-to-list-mobile dms-btn-outline w-100 px-0 align-self-center">To List</Link>
            </div>
        </div>
    );
}

export default VictimShelterBelongNoticeDetails;