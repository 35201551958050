const ValidatorHelper = {
    isValidYYYYMMDD: function (dateString) {
        if (!/^\d{8}$/.test(dateString)) {
            return false;
        }

        var year = parseInt(dateString.substring(0, 4), 10);
        var month = parseInt(dateString.substring(4, 6), 10) - 1;
        var day = parseInt(dateString.substring(6, 8), 10);

        var date = new Date(year, month, day);
        return (
            date.getFullYear() === year &&
            date.getMonth() === month &&
            date.getDate() === day
        );
    },
    isValidPassword: function (value)  {
        if (value.trim().length < 8) return false
        if (!/[a-z]/.test(value.trim())) return false
        if (!/[A-Z]/.test(value.trim())) return false
        if (!/[0-9]/.test(value.trim())) return false
        if (!/[@$!%*?&]/.test(value.trim())) return false

        return true
    },
    validateYYYYMMDD: function (value, setError) {
        if (value && !this.isValidYYYYMMDD(value))
        {
            setError('날짜는 YYYYMMDD 형식으로 입력해야 합니다.')
            return false
        }
        else
        {
            setError(null)
            return true
        }
    },
    validatePassword: function (value, setError) {
        if (value && !this.isValidPassword(value))
        {
            setError('비밀번호는 영문(대소문자), 숫자, 특수문자 조합 8자리 이상이어야 합니다.')
            return false
        }
        else
        {
            setError(null)
            return true
        }
    }
}

export default ValidatorHelper;