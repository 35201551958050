import { useEffect, useState } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";

const OutgoingSupplies = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/sply/getIncomSplyLst';
    
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [shelter, setShelter] = useState(null)
    
    const [itemTypes, setItemTypes] = useState([])    
    const [categories1, setCategories1] = useState([])
    const [categories2, setCategories2] = useState([])
    const [categories3, setCategories3] = useState([])
    const [categories4, setCategories4] = useState([])
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [shelters, setShelters] = useState([])

    const [parameters, setParameters] = useState({
        status: 'OUT',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...filters,
            ...(area1?.value ? {area1Cd: area1.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
            ...(shelter?.value ? {facilityId: shelter.value} : null),
            ...(filters?.type ? {kindCd: filters?.type} : null),
            ...(filters?.cat1 ? {cat1Cd: filters?.cat1.substring(filters?.type.length)} : null),
            ...(filters?.cat2 ? {cat2Cd: filters?.cat2.substring(filters?.cat1.length)} : null),
            ...(filters?.cat3 ? {cat3Cd: filters?.cat3.substring(filters?.cat2.length)} : null),
            ...(filters?.cat4 ? {cat4Cd: filters?.cat4.substring(filters?.cat3.length)} : null),
        }))
    }

    const initializeFilter = () => {
        setFilters({});
        setArea2(null)
        setShelter(null)
        setParameters(current => ({status: 'OUT', reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if(areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!area2) return
        setShelter(null)

        ApiHelper.loadDropdown('area2Cd='+area2?.value+'&typeCd=02', setShelters, 'fac/getFACLst', 'facilityId')
    }, [area2])

    useEffect(() => {
        if (!filters?.type) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            return filter
        });

        setCategories2([])
        setCategories3([])
        setCategories4([])

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=2&codeLev1='+filters?.type, setCategories1)
    }, [filters?.type])

    useEffect(() => {
        if (!filters?.cat1) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            return filter
        });

        setCategories3([])
        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)

        ApiHelper.loadDropdown('groupCode=D002&codeDepth=3&codeLev1='+filters?.type+'&codeLev2='+cat1, setCategories2)
    }, [filters?.cat1])

    useEffect(() => {
        if (!filters?.cat2) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            return filter
        });
        
        setCategories4([])

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=4&codeLev1='+filters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2, setCategories3)
    }, [filters?.cat2])

    useEffect(() => {
        if (!filters?.cat3) return
        setFilters(current => {
            var filter = {}
            if (current?.type) filter['type'] = current.type
            if (current?.cat1) filter['cat1'] = current.cat1
            if (current?.cat2) filter['cat2'] = current.cat2
            if (current?.cat3) filter['cat3'] = current.cat3
            return filter
        });

        var cat1 = filters?.cat1.substring(filters?.type.length)
        var cat2 = filters?.cat2.substring(filters?.cat1.length)
        var cat3 = filters?.cat3.substring(filters?.cat2.length)
        
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=5&codeLev1='+filters?.type+'&codeLev2='+cat1+'&codeLev3='+cat2+'&codeLev4='+cat3, setCategories4)
    }, [filters?.cat3])
    
    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expIncomSplyLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호물품</h3>
                <Link to={'/inventory-management'} className="sidebar-link">구호물품 재고 관리</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">비축창고 등록/관리</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">구호물품 입고 내역</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link active">구호물품 출고 내역</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">구호물품 출고 내역</h3>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">물품 종류</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-4">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"전체"}
                                            value={filters?.type ? itemTypes.find(value => value.value == filters?.type) : null}
                                            onChange={(e) => setFilters(current => ({...current, type: e.value}))}
                                            isSearchable={false}
                                            options={itemTypes}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">물품 분류</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"전체"}
                                            value={filters?.cat1 ? categories1.find(value => value.value == filters?.cat1) : null}
                                            onChange={(e) => setFilters(current => ({...current, cat1: e.value}))}
                                            isSearchable={false}
                                            options={categories1}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"전체"}
                                            value={filters?.cat2 ? categories2.find(value => value.value == filters?.cat2) : null}
                                            onChange={(e) => setFilters(current => ({...current, cat2: e.value}))}
                                            isSearchable={false}
                                            options={categories2}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"전체"}
                                            value={filters?.cat3 ? categories3.find(value => value.value == filters?.cat3) : null}
                                            onChange={(e) => setFilters(current => ({...current, cat3: e.value}))}
                                            isSearchable={false}
                                            options={categories3}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            placeholder={"전체"}
                                            value={filters?.cat4 ? categories4.find(value => value.value == filters?.cat4) : null}
                                            onChange={(e) => setFilters(current => ({...current, cat4: e.value}))}
                                            isSearchable={false}
                                            options={categories4}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">보낸 곳</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'선택'}
                                            value={area1 ?? null}
                                            onChange={(e) => setArea1(e)}
                                            options={areas1}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'전체'}
                                            value={area2 ?? null}
                                            onChange={(e) => setArea2(e)}
                                            options={areas2}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'전체'}
                                            value={shelter ?? null}
                                            onChange={(e) => setShelter(e)}
                                            options={shelters}
                                            noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                        </div>
                    </form>

                    {totalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                    {totalCount !== 0 ? (
                        <div className="table-actions-container mb-20px">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    { fetchError ? <p className="search-result text-orange">{fetchError}</p> : null }
                                    { fetchIsLoading ? <p className="search-result">Loading data</p> : 
                                        totalCount !== null ? <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                    }
                                </div>
                                <div className="d-flex justify-content-end">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        className="dropdown-sm mr"
                                        value={{label: parameters.reqPageSize + '개씩 보기'}}
                                        onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                        isSearchable={false}
                                        options={[
                                            { value: 10, label: '10개씩 보기' },
                                            { value: 20, label: '20개씩 보기' },
                                            { value: 30, label: '30개씩 보기' },
                                        ]}
                                    />
                                    <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> 엑셀 저장</button>
                                </div>
                            </div>
                        </div>
                    ) : null}

                    { totalCount !== null && totalCount !== 0 ? (
                        <table className="table dms-table-seperate">
                            <thead>
                                <tr>
                                    <th>처리일시</th>
                                    <th>물품종류</th>
                                    <th>물품분류</th>
                                    <th>보낸 곳</th>
                                    <th>보낸 곳 주소</th>
                                    <th>받는 곳</th>
                                    <th>받는 곳 주소</th>
                                    <th>상태</th>
                                    <th>수량</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map(item => (
                                    <tr key={item.eventDt+item.recvFacAddress}>
                                        <td>{item.eventDt}</td>
                                        <td>{item.itemTypeName}</td>
                                        <td>{item.itemName}</td>
                                        <td>{item.sendFacName}</td>
                                        <td>{item.sendFacAddress}</td>
                                        <td>{item.recvFacName}</td>
                                        <td>{item.recvFacAddress}</td>
                                        <td className={item.status == '입고' ? 'text-orange' : 'text-green'}>{item.status}</td>
                                        <td className={item.status == '입고' ? 'text-orange' : 'text-green'}>{item.status == '입고' ? '+' : '-'}{item.quantity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : null}

                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({...current, reqPageNo: e.selected+1}))}
                            forcePage={Math.max(parameters.reqPageNo-1, 0)}
                            pageCount={Math.ceil(totalCount/parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>
                    
                <Footer />
            </div>

            <SaveExcelModal handleSaveExcel={handleSaveExcel} isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}
 
export default OutgoingSupplies;