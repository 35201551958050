import {useEffect, useState} from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import {Link, useNavigate, useParams} from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import useFetch from "../useFetch";

const AdminShelterBelongInquiriesDetails2 = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getInq?boardId='+id

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);

    const handleClickBack = () => {
        navigate(-1);
    }


    const [type, setType] = useState(null)
    const [description, setDescription] = useState('')
    const [subject, setSubject] = useState('')
    const [answer, setAnswer] = useState('')
    const [status, setStatus] = useState('')
    const [facilityName, setFacilityName] = useState('')
    const [writerName, setWriterName] = useState('')
    const [updateDt, setUpdateDt] = useState('')

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setType(fetchResponse?.resultObject?.typeCd)
            setDescription(fetchResponse?.resultObject?.questionMemo)
            setSubject(fetchResponse?.resultObject?.subject)
            setAnswer(fetchResponse?.resultObject?.answerMemo)
            setStatus(fetchResponse?.resultObject?.statusCd)
            setFacilityName(fetchResponse?.resultObject?.facilityName)
            setWriterName(fetchResponse?.resultObject?.writerName)
            setUpdateDt(fetchResponse?.resultObject?.updateDt ?? fetchResponse?.resultObject?.createDt)
        }
    }, [fetchResponse])

    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="mobile-navbar-left" onClick={handleClickBack}>
                        <img src="/images/icons/back.svg" />
                    </div>
                    <div className="mobile-navbar-title">구호소 문의게시판</div>
                    <div className="blank-icon" />
                </div>
            </div>
            
            <div className="content-container container flex-fill pb-4">
                <div className="page-heading-container mb-20px mt-0">
                    <h3 className="page-heading mb-0">{subject}</h3>
                    <p className="fs-xs mb-2">- {writerName}</p>
                    <div className="d-flex justify-content-between align-items-center">
                        <p className="last-modified">{updateDt}</p>
                        <div className="table-actions-container d-flex justify-content-between">
                            <button type="button" className="dms-btn dms-btn-s dms-btn-outline">삭제하기</button>
                        </div>
                    </div>
                </div>
                <div className="mobile-card">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <p className="d-inline-block bg-cool-gray text-gray-04 px-3 py-1 mb-2">문의</p>
                    </div>
                    {description}
                </div>
                <div className="mobile-card">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <p className="d-inline-block bg-cool-gray text-green px-3 py-1 mb-0">답변</p>
                       {/* <Link to="/shelter-admin-inquiry-answer-belong-m" className="dms-btn dms-btn-s dms-btn-outline mr">Modify</Link>*/}
                    </div>
                    {answer}
                </div>
            </div>
            
            <div className="container content-container pt-0">
                <Link to="/shelter-admin-inquiries-belong-m" className="dms-btn dms-btn-to-list-mobile dms-btn-outline w-100 px-0 align-self-center">돌아가기</Link>
            </div>
        </div>
    );
}
 
export default AdminShelterBelongInquiriesDetails2;