import { useEffect, useState } from "react";
import { Alert, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const UserRegister = () => {
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [birthday, setBirthday] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [affiliation, setAffiliation] = useState('')

    const [birthdayError, setBirthdayError] = useState(null)
    const [passwordError, setPasswordError] = useState(null)
    
    const [affiliations, setAffiliations] = useState([])

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D009&codeDepth=1', setAffiliations)
    }, [])

    useEffect(() => {
        ValidatorHelper.validateYYYYMMDD(birthday, setBirthdayError)
    }, [birthday])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password != passwordConfirm)
            {
                setPasswordError('비밀번호가 일치하지 않습니다.')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm)
        {
            setPasswordError('비밀번호가 일치하지 않습니다.')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password))
        {
            setError('비밀번호는 영문 대소문자, 숫자, 특수문자 조합 8자리 이상이어야 합니다.')
            return
        }
        if (password != passwordConfirm)
        {
            setError('비밀번호가 일치하지 않습니다.')
            return
        }
        if (!ValidatorHelper.isValidYYYYMMDD(birthday))
        {
            setError('생년월일은 YYYYMMDD 형식으로 입력해야 합니다.')
            return
        }
        if (!zipCode)
        {
            setError('주소를 입력하세요.')
            return
        }

        setIsLoadingSubmit(true)
        
        var formData = {
            typeCd: "01",       //admin : type_cd 01
            userId: userId,
            name: name,
            pwd: password,
            bday: birthday,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: email,
            affiliationCd: affiliation
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/regUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')

                setName('')
                setUserId('')
                setPassword('')
                setPasswordConfirm('')
                setBirthday('')
                setZipCode('')
                setMainAddress('')
                setDetailedAddress('')
                setPhoneNumber('')
                setEmail('')
                setAffiliation('')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">시스템 관리</h3>
                <Link to={'/statistics'} className="sidebar-link">통계자료</Link>
                <Link to={'/user-management'} className="sidebar-link active">사용자 등록/관리</Link>
                <Link to={'/disaster-management'} className="sidebar-link">재난코드 등록/관리</Link>
                <Link to={'/notices-news'} className="sidebar-link">공지사항</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <form onSubmit={handleSubmit} className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">사용자 등록/관리</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            {/*
                            <button to="/user-management" type="button" className="dms-btn dms-btn-s dms-btn-outline mr">Close</button> */}
                            <Link to="/user-management" className="dms-btn dms-btn-s dms-btn-outline mr">닫기</Link>
                            <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2 ${isLoadingSubmit ? 'disabled' : ''}`}>등록</button>
                        </div>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                        <div className="form-heading"><h5>개인정보</h5></div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setName(e.target.value)}
                                        value={name}
                                        placeholder="이름을 입력하세요."
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setUserId(e.target.value)}
                                        value={userId}
                                        placeholder="영문, 숫자, 특수문자 조합"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setPassword(e.target.value)}
                                        value={password}
                                        className={passwordError ? 'is-invalid' : ''}
                                        placeholder="영문, 숫자, 특수문자 포함 8글자"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setPasswordConfirm(e.target.value)}
                                        value={passwordConfirm}
                                        className={passwordError ? 'is-invalid' : ''}
                                        placeholder="비밀번호 재확인"
                                        required={true} />
                                    {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">생년월일</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setBirthday(e.target.value)}
                                        value={birthday}
                                        className={birthdayError ? 'is-invalid' : ''}
                                        placeholder="숫자만 입력하세요.(YYYYMMDD)" />
                                    {birthdayError ? <div className="input-helper-error">{birthdayError}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label align-self-center">주소</div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        value={zipCode}
                                        disabled={true} />
                                </div>
                                <div className="dms-input-4">
                                    <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소찾기</button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container mb-2">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        value={mainAddress}
                                        disabled={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label"></div>
                            <div className="dms-input-row">
                                <div className="dms-input-8">
                                    <FormControl
                                        onChange={e => setDetailedAddress(e.target.value)}
                                        value={detailedAddress}
                                        placeholder="상세주소를 입력하세요(동, 호수)"
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">연락처<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        onChange={e => setPhoneNumber(e.target.value)}
                                        value={phoneNumber}
                                        placeholder="숫자만 입력하세요."
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">이메일 주소<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <FormControl
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        placeholder="이메일 주소를 입력하세요."
                                        required={true} />
                                </div>
                            </div>
                        </div>
                        <div className="form-group dms-input-container">
                            <div className="dms-form-label align-self-center">소속<span className="label-ast">*</span></div>
                            <div className="dms-input-row">
                                <div className="dms-input-4">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        isSearchable={false}
                                        required={true}
                                        placeholder={'소속 선택'}
                                        value={affiliations ? affiliations.find(value => value.value == affiliation) ?? null : null}
                                        onChange={(e) => setAffiliation(e.value)}
                                        options={affiliations}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                    
                <Footer />
            </div>
            
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />
        </div>
    );
}
 
export default UserRegister;