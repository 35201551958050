import { useRef, useEffect, useState } from "react";
import { Alert} from "react-bootstrap";
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams,useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { TbQrcode } from "react-icons/tb";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import ValidatorHelper from "../Helpers/ValidatorHelper";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }

//PAGE-D302
const VolunteerDetails = () => {
    const history = useNavigate();
    const [isVisibleDelete, setIsVisibleDelete] = useState(false);

    const { id } = useParams();
    const uploadFileRef = useRef()
    const navigate = useNavigate();

    const [apiUrl, setApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/auth/getUser?userId='+id)    
    const allListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';
    const [vltrAsgnlHistApiUrl, setVltrAsgnlHistApiUrl] = useState(process.env.REACT_APP_BASE_API_URL+'/vltr/getVltrAsgnlHist?userId='+id);        


    
    //const fileListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cmn/getFileList?channelCd=05'+id+'&reqPageSize=10000&reqPageNo=1'

    const [selectedItems, setSelectedItems] = useState([])    

    const [isVisibleAddress, setIsVisibleAddress] = useState(false)

    const [isVisibleGettingInOutShelter, setIsVisibleGettingInOutShelter] = useState(false)    

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    
    const [isLoadingInOut, setIsLoadingInOut] = useState(false);
    const [successInOut, setSuccessInOut] = useState(null);
    const [errorInOut, setErrorInOut] = useState(null);
    

    const [isLoadingImage, setIsLoadingImage] = useState(false);


    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)
    //const [isVisibleMoveShelter, setIsVisibleMoveShelter] = useState(false)
    const [isVisibleGettingInShelter, setIsVisibleGettingInShelter] = useState(false)
    const [isVisibleGettingOutShelter, setIsVisibleGettingOutShelter] = useState(false)
    const [image, setImage] = useState(false)


    const [name, setName] = useState('')    
    const [userId, setUserId] = useState('')    
    const [password, setPassword] = useState('')    
    const [passwordConfirm, setPasswordConfirm] = useState('')    
    const [bday, setBday] = useState('')        
    const [zipCode, setZipCode] = useState('')    
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [telNo, setTelNo] = useState('')
    const [email, setEmail] = useState('')
    const [vltrRegCenterName, setVltrRegCenterName] = useState('')    
    const [idx, setIdx] = useState('')    //현장등록 default (1365 포털은 시스템 연계라, 현장등록으로 입력 함. )    
    const [vltrRegCenterNo, setVltrRegCenterNo] = useState('02')    //현장등록 default (1365 포털은 시스템 연계라, 현장등록으로 입력 함. )
    const [typeCd, setTypeCd] = useState('03')    // 03 : 자원봉사자
    const [updateDt, setUpdateDt] = useState('')    // 03 : 자원봉사자    
    const [photoFileId, setPhotoFileId] = useState(null)  
    const [currentShelterInfo, setCurrentShelterInfo] = useState({})      
    const [roleCd, setRoleCd] = useState({})          
    const [passwordError, setPasswordError] = useState(null)


    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [shelter, setShelter] = useState(null)
    
    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [shelters, setShelters] = useState([])

    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const [inOutArea1, setInOutArea1] = useState(null)
    const [inOutArea2, setInOutArea2] = useState(null)
    const [inOutShelter, setInOutShelter] = useState(null)
    const [inOutRoleCd, setInOutRoleCd] = useState({ value: '01', label: '인원 관리'})

    
    const [inOutAreas1, setInOutAreas1] = useState([])
    const [inOutAreas2, setInOutAreas2] = useState([])
    const [inOutShelters, setInOutShelters] = useState([])

    //TODO : DB 처리 
    const options = [
        { value: '01', label: '인원 관리'},
        { value: '02', label: '물품 관리'},
        { value: '03', label: '간호'},
        { value: '04', label: '식사'},
        { value: '05', label: '기타'},
    ]

    const [usageHistoryParameters, setUsageHistoryParameters] = useState({
        userId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [usageHistories, setUsageHistories] = useState([])
    const [usageHistoryTotalCount, setUsageHistoryTotalCount] = useState(null);
    const [usageHistoryFilters, setUsageHistoryFilters] = useState({})

    

    const [years, setYears] = useState([])
    const [disasters, setDisasters] = useState([])


    // 초기 데이터 셋팅
    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);    
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);
    const { fetchResponse: vltrAsgnlHistResponse } = useFetch(vltrAsgnlHistApiUrl);    

    

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setInOutAreas1)
    }, [])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError))
        {
            if (password !== passwordConfirm)
            {
                setPasswordError('Both password must match')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password !== passwordConfirm)
        {
            setPasswordError('Both password must match')
        }
        else
        {
            setPasswordError(null)
        }
    }, [passwordConfirm])


    useEffect(() => {
        if(inOutAreas1.length > 0) setInOutArea1(inOutAreas1[0])
    }, [inOutAreas1])

    useEffect(() => {
        if (!inOutArea1) return
        setInOutArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+inOutArea1?.value, setInOutAreas2)
    }, [inOutArea1])

    useEffect(() => {
        if (!inOutArea2) return
        setInOutShelter(null)

        ApiHelper.loadDropdown('area2Cd='+inOutArea2?.value+'&typeCd=02', setInOutShelters, 'fac/getFACLst', 'facilityId')
    }, [inOutArea2])

    /*
    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])
    */

    /*
    const handleFilterSubmit = (e) => {

        console.log('aaaa');
        e.preventDefault();
        setIsVisibleMoveShelter(false);

        var formData = {
            userIdList: selectedItems,
        }
   
        

        setParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...filters,
            ...(area1?.value ? {area1Cd: area1.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
            ...(shelter?.value ? {facilityId: shelter.value} : null),
        }))
    }
    */

    const initializeFilter = () => {
        setFilters({});
        setArea2(null)
        setShelter(null)
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if(areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!area2) return
        setShelter(null)

        ApiHelper.loadDropdown('area2Cd='+area2?.value+'&typeCd=02', setShelters, 'fac/getFACLst', 'facilityId')
    }, [area2])

    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id))
            {
                return current.filter(value => value != id)
            }
            else
            {
                return [...current, id]
            }
        })
    }    

    //프로파일 이미지 변경 처리
    const handleImageChange = (e) => {
        var file = e.target.files[0];
        if (!file) return

        setIsLoadingImage(true)

        var formData = new FormData();
        formData.append("channelCd", '06');
        formData.append("channelId", idx);
        formData.append("uploaderId", "admin_001");
        formData.append("addFile", file);
        
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/cmn/addFile', { 
            method: 'POST',
            body: formData,
            headers: { 
                //"Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            console.log('data->', data);
            if (data.responseCode === 0)
            {
                var reader = new FileReader();
                var url = reader.readAsDataURL(file);
                reader.onloadend = function (e) {
                    setImage(reader.result)
                    if (uploadFileRef?.current)
                    {
                        uploadFileRef.current.value = '';
                    }
                }
            }
            else
            {
                if (uploadFileRef?.current)
                {
                    uploadFileRef.current.value = '';
                }
            }
            setIsLoadingImage(false);
        })
        .catch(error => {
            setIsLoadingImage(false);
            if (uploadFileRef?.current)
            {
                uploadFileRef.current.value = '';
            }
        })
    }





    //자원봉사자 상세 데이터 셋팅
    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setUserId(fetchResponse?.resultObject?.userId)            
            setName(fetchResponse?.resultObject?.name)
            setTelNo(fetchResponse?.resultObject?.telNo)            
            setTypeCd(fetchResponse?.resultObject?.typeCd)                        
            setBday(fetchResponse?.resultObject?.bday)                                    
            setEmail(fetchResponse?.resultObject?.email)                                                
            setIdx(fetchResponse?.resultObject?.idx)                                                                                                                      
                        
            setVltrRegCenterName(fetchResponse?.resultObject?.vltrRegCenterName)                                      
            
            setZipCode(fetchResponse?.resultObject?.zipCd)                                                                                              
            setMainAddress(fetchResponse?.resultObject?.addr)                                                                                              
            setDetailedAddress(fetchResponse?.resultObject?.addrDtl)

            setUpdateDt(fetchResponse?.resultObject?.updateDt)

            setPhotoFileId(fetchResponse?.resultObject?.photoFileId)

            setCurrentShelterInfo(fetchResponse?.resultObject?.currentShelterInfo)
        }
    }, [fetchResponse])  
    

    
    useEffect(() => {
        if (vltrAsgnlHistResponse)
        {
            console.log('vltrAsgnlHistResponse?.resultList->', fetchResponse?.resultList);

            setItems(vltrAsgnlHistResponse?.resultList)
        }
    }, [vltrAsgnlHistResponse])  



    

    useEffect(() => {
        if (allListFetchResponse)
        {
            const years = []
            const disasters = []

            console.log('allListFetchResponse?.resultList ->', allListFetchResponse?.resultList);
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt)
                {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt)
                {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }

                if (value?.name)
                {
                    if (!disasters.find(disaster => disaster.value === value.disasterCd)) disasters.push({value: value.disasterCd, label: value.name})
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({value: value, label: value})))
            setDisasters(disasters)
        }
    }, [allListFetchResponse])

    //구호소 투입 내역 > 재난 연도 선택 시, 해당 재난이 출력되도록!
    useEffect( () => {
        //console.log('usageHistoryFilters -->',usageHistoryFilters);
        var myYear = usageHistoryFilters.year;

        const disasters = [];

        allListFetchResponse?.resultList?.map(value => {

            let year = value?.startDt?.substring(0, 4);
            if(myYear === year){
                disasters.push({value: value.disasterCd, label: value.name})
            }
        })
        setDisasters(disasters)
    }, [usageHistoryFilters])



    //프로파일 이미지 셋팅
    useEffect(() => {
        if(photoFileId != null){
            fetch(process.env.REACT_APP_BASE_API_URL+'/cmn/getImage?fileId='+photoFileId)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }

                console.log(response)

                return response.blob();
            })
            .then(data => {
                setImage(window.URL.createObjectURL(new Blob([data])))
            })
            .catch(error => {
            })
        }
    }, [photoFileId])


    //자원봉사자 상세 수정 처리
    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        console.log('password->', password);
        console.log('passwordConfirm->', passwordConfirm);        
        if(password !== '' || passwordConfirm !== '' ){
            if (!ValidatorHelper.isValidPassword(password))
            {
                setError('Password must contain at least 8 characters including alphanumeric and special characters')
                return
            }
            if (password !== passwordConfirm)
            {
                setError('Both password must match')
                return
            }
        }
        if (!zipCode)
        {
            setError('Enter choose an address')
            return
        }

        setIsLoading(true)
        
        var formData = {
            userId: userId,
            pwd: password,
            name: name,
            bday: bday,
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: telNo,
            email: email,
            vltrRegCenterNo: "02"
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/updateUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }    

    const handleGettingCancelButton = (params, e) => {
        e.preventDefault();
        if(params === 'IN'){
            console.log('setIsVisibleGettingInShelter > false');
            setIsVisibleGettingInShelter(false);
        } else if(params === 'OUT'){
            console.log('setIsVisibleGettingOutShelter > false');
            setIsVisibleGettingOutShelter(false);
        }
    }

    //입소 처리
    const handleGettingIn = (e) => {
        e.preventDefault();

        if(inOutShelter != null){
            console.log('in progress -->', inOutShelter, inOutRoleCd);

            setErrorInOut(null)
            setSuccessInOut(null)
            setIsLoadingInOut(true)

            var formData = {
                userId: userId,
                facilityId: inOutShelter?.value,
                statusCd: 'IN',
                roleCd : inOutRoleCd?.value
            }
            
            fetch(process.env.REACT_APP_BASE_API_URL+'/vltr/asgnVltr', { 
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 
                    "Content-Type": "application/json",
                    //"Authorization": "Bearer "+localStorage.getItem("token"),
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }
    
                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0)
                {
                    setSuccessInOut(data.responseMessage ?? 'Success')
                    setInOutArea2(null)
                    setInOutShelter(null)
                    setInOutShelters([])
                    setApiUrl(process.env.REACT_APP_BASE_API_URL+'/auth/getUser?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))

                    setVltrAsgnlHistApiUrl(process.env.REACT_APP_BASE_API_URL+'/vltr/getVltrAsgnlHist?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))
                }
                else
                {
                    setErrorInOut(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingInOut(false);
                setInOutRoleCd(options[0]);
            })
            .catch(error => {
                setErrorInOut(error.message);
                setIsLoadingInOut(false);
            })            
        }
    }

    //퇴소 처리
    const handleGettingOut = (e) => {
        e.preventDefault();

        if(currentShelterInfo != null){
            console.log('out progress -->', currentShelterInfo);

            setErrorInOut(null)
            setSuccessInOut(null)
            setIsLoadingInOut(true)

            var formData = {
                userId: userId,
                facilityId: currentShelterInfo?.facilityId,
                statusCd: 'OUT'
            }
            
            fetch(process.env.REACT_APP_BASE_API_URL+'/vltr/asgnVltr', { 
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 
                    "Content-Type": "application/json",
                    //"Authorization": "Bearer "+localStorage.getItem("token"),
                }
            })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }
    
                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0)
                {
                    setSuccessInOut(data.responseMessage ?? 'Success')
                    setInOutArea2(null)
                    setInOutShelter(null)
                    setInOutShelters([])
                    setApiUrl(process.env.REACT_APP_BASE_API_URL+'/auth/getUser?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))

                    setVltrAsgnlHistApiUrl(process.env.REACT_APP_BASE_API_URL+'/vltr/getVltrAsgnlHist?userId='+id+'&forceRefresh='+Math.random().toString(36).substring(2,10))                    
                }
                else
                {
                    setErrorInOut(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingInOut(false);
            })
            .catch(error => {
                setErrorInOut(error.message);
                setIsLoadingInOut(false);
            })            
        }
    }

    const handleHideInOut = () => {
        setIsVisibleGettingInOutShelter(false)
        setErrorInOut(null)
        setSuccessInOut(null)
    }

    const handleDelete = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)
        
        var formData = {
            userId: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/auth/delUser', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
                history('/volunteer-management')
                /*
                setDisasterType1(null)
                setDisasterType2(null)
                setName('')
                setMemo('')
                setStartDate('')
                setOccurBasisUrl('')
                setSelectedAreas([])
                */
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">자원봉사자</h3>
                <Link to={'/volunteer-management'} className="sidebar-link active">자원봉사자 등록/관리</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link">자원봉사자 투입 현황</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <form onSubmit={handleSubmit}>
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">자원봉사자 상세 보기</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <p className="last-modified text-orange">{updateDt} 수정</p>
                                <button className="dms-btn dms-btn-s dms-btn-outline mr" onClick={handleDelete}>삭제</button>
                                <button className="dms-btn dms-btn-s dms-btn-outline mr" onClick={() => navigate("/volunteer-management")}>  목록</button>
                                {/* <button className="dms-btn dms-btn-s dms-btn-outline mr"><TbQrcode  size="16px" /> QR 출력</button>  */}
                                <button type="submit" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">수정</button>
                            </div>
                        </div>

                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}


                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>개인정보</h5></div>
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">성명<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl  onChange={e => setName(e.target.value)} value={name} placeholder="Please enter a name" defaultValue="Test" />
                                            </div>
                                        </div>
                                    </div>
                                    {/*
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">회원번호</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl  value={name} placeholder="Please enter a name" defaultValue="Test" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    */}
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl  value={userId}  disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">비밀번호</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-12">
                                                <FormControl onChange={e => setPassword(e.target.value)}
                                                        value={password}
                                                        className={passwordError ? 'is-invalid' : ''}
                                                        placeholder="영문, 숫자, 특수문자 포함 8글자" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-12">
                                                <FormControl onChange={e => setPasswordConfirm(e.target.value)} value={passwordConfirm} className={passwordError ? 'is-invalid' : ''} placeholder="비밀번호 재확인" />
                                                {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}                                        
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">생년월일<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl onChange={e => setBday(e.target.value)} value={bday}  placeholder="숫자만 입력하세요(YYYYMMDD)" required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label align-self-center">주소<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl value={zipCode}  disabled />
                                            </div>
                                            <div className="dms-input-6">
                                                <button onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">주소 찾기</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container mb-2">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-12">
                                                <FormControl value={mainAddress} placeholder="Please enter value"  disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label"></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-12">
                                                <FormControl onChange={e => setDetailedAddress(e.target.value)} value={detailedAddress} placeholder="상세주소를 입력하세요"  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">연락처<span className="label-ast">*</span></div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl onChange={e => setTelNo(e.target.value)} value={telNo}  placeholder="연락처를 입력하세요" required={true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">이메일 주소</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl onChange={e => setEmail(e.target.value)} value={email}  placeholder="이메일 주소를 입력하세요"  />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">등록 센터</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-6">
                                                <FormControl  value={vltrRegCenterName}  disabled />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="picture-preview-box">
                                        {image ? <img src={image} /> : <span>NO PICTURE</span>}
                                        <div className="overlay">
                                            <label htmlFor="upload-file" className="dms-btn dms-btn-s dms-btn-outline align-self-center">
                                                {image ? 'Photo change' : 'Upload'}
                                                <input ref={uploadFileRef} type="file" disabled={isLoadingImage}  accept="image/jpg,image/jpeg,image/png" id="upload-file" hidden onChange={handleImageChange}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading d-flex justify-content-between align-items-center">
                            <h5>현재 배정된 구호소</h5>
                            {/* <button type="button" onClick={() => setIsVisibleMoveShelter(true)} className="dms-btn dms-btn-xs dms-btn-outline">Move Shelter</button> */}
                        </div>

                        <table className="table dms-table-1">
                            <thead>
                                <tr>
                                    <th>시도명</th>
                                    <th>시도군</th>
                                    <th>투입 구호소</th>
                                    <th>역할</th>
                                    <th>입소일시</th>
                                    <th>퇴소일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentShelterInfo?.enterDt ? (
                                    <tr>
                                        <td>{currentShelterInfo?.city}</td>
                                        <td>{currentShelterInfo?.dist}</td>
                                        <td>{currentShelterInfo?.shelterName}</td>
                                        <td>{currentShelterInfo?.roleCdName}</td>
                                        <td>{currentShelterInfo?.enterDt}</td>
                                        <td><button onClick={() => setIsVisibleGettingOutShelter(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">퇴소하기</button></td>
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={4}>배정된 구호소가 없습니다.</td>
                                        <td><button onClick={() => setIsVisibleGettingInShelter(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3">입소하기</button></td>
                                        <td>{/*<button onClick={() => setIsVisibleGettingOutShelter(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline d-inline-flex px-3 disabled">퇴소하기</button>*/}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="white-rounded-container mb-20px">
                        <div className="form-heading"><h5>구호소 투입 내역</h5></div>
                        
                        {/*
                        <div className="row mb-20px">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">재난 선택</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect 
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'Select year'}
                                            isSearchable={false}
                                            value={usageHistoryFilters?.year ? {label: usageHistoryFilters?.year} : null}
                                            onChange={(e)=>setUsageHistoryFilters(current => ({...current, year: e.value}))}
                                            options={years}
                            />
                                    </div>
                                    <div className="col-lg-9">
                                        <ReactSelect
                                            components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                            classNamePrefix="select"
                                            placeholder={'All Disasters'}
                                            value={usageHistoryFilters?.disasterCd ? disasters.find(value => value.value === usageHistoryFilters.disasterCd) ?? null : null}
                                            onChange={(e) => setUsageHistoryFilters(current => ({...current, disasterCd: e.value}))}
                                            isSearchable={false}
                                            options={disasters}
                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}

                        <table className="table dms-table-1">
                            <thead>
                                <tr>
                                    <th>시도</th>
                                    <th>시군구</th>
                                    <th>구호소 주소</th>
                                    <th>투입 구호소</th>
                                    <th>역할</th>
                                    <th>입소일시</th>
                                    <th>퇴소일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items?.map(value => (
                                    <tr key={value.enterId}>
                                        <td>{value.city}</td>
                                        <td>{value.dist}</td>
                                        <td>{value.addr}</td>
                                        <td>{value.name}</td>
                                        <td>{value.role}</td>
                                        <td>{value.startSvcDt}</td>
                                        <td>{value.endSvcDt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        
                        {/*
                        <ReactPaginate
                            //onPageChange={handlePageClick}
                            forcePage={0}
                            pageCount={5}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                        */}
                    </div>
                </div>
                <DeleteModalWeb handleDelete={handleDelete} isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />
                <Footer />
            </div>

            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress} 
                setIsVisible={setIsVisibleAddress} />

            {/*
            <Modal size="lg" show={isVisibleMoveShelter} onHide={() => setIsVisibleMoveShelter(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Move Shelter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-lg-2 d-flex align-items-center">
                            <div className="dms-filter-label">Move shelter</div>
                        </div>
                        <div className="col-lg-10">
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Ulsen City'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        value={area1 ?? null}
                                        onChange={(e) => setArea1(e)}
                                        options={areas1}

                                    />
                                </div>
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Entire City'}
                                        isSearchable={false}
                                        value={area2 ?? null}
                                        onChange={(e) => setArea2(e)}
                                        options={areas2}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'All shelter'}
                                        isSearchable={false}
                                        value={shelter ?? null}
                                        onChange={(e) => setShelter(e)}
                                        options={shelters}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => setIsVisibleMoveShelter(false)} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={handleFilterSubmit} className="dms-btn dms-btn-xs dms-btn-filled">OK</button>
                </Modal.Footer>
            </Modal>*/}

            <Modal size="lg" show={isVisibleGettingInShelter} onHide={() => setIsVisibleGettingInShelter(false)} centered>
            <form onSubmit={handleGettingIn}>
                <Modal.Header closeButton>
                    <Modal.Title>구호소 입소 처리</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row mb-2">
                        <div className="col-lg-2 d-flex align-items-center">
                            <div className="dms-filter-label">배정 구호소</div>
                        </div>
                        <div className="col-lg-10">
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'Ulsen City'}
                                        isSearchable={false}
                                        isDisabled={true}
                                        value={inOutArea1 ?? null}
                                        onChange={(e) => setInOutArea1(e)}
                                        options={inOutAreas1}
                                        noOptionsMessage={() => '데이터 없음'}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'시군구'}
                                        isSearchable={false}
                                        value={inOutArea2 ?? null}
                                        onChange={(e) => setInOutArea2(e)}
                                        options={inOutAreas2}
                                        noOptionsMessage={() => '데이터 없음'}

                                    />
                                </div>
                                <div className="col-lg-6">
                                    <ReactSelect
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'모든 구호소'}
                                        isSearchable={false}
                                        value={inOutShelter ?? null}
                                        onChange={(e) => setInOutShelter(e)}
                                        options={inOutShelters}
                                        noOptionsMessage={() => '데이터 없음'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-lg-2 d-flex align-items-center">
                            <div className="dms-filter-label">역할 구분</div>
                        </div>
                        <div className="col-lg-10">
                            <div className="row gx-2">
                                <div className="col-lg-3">
                                    <ReactSelect defaultValue={options[0]}
                                        components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}} 
                                        classNamePrefix="select"
                                        placeholder={'역할'}
                                        isSearchable={false}
                                        value={inOutRoleCd ?? null}
                                        options={options}
                                        onChange={(e) => setInOutRoleCd(e)}
                                        
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={(e)=>{handleGettingCancelButton('IN', e)}}  className="dms-btn dms-btn-xs dms-btn-outline me-2">취소</button>
                    <button type="submit" onClick={() => setIsVisibleGettingInShelter(false)} className="dms-btn dms-btn-xs dms-btn-filled">확인</button>
                </Modal.Footer>
            </form>
            </Modal>

            <Modal show={isVisibleGettingOutShelter} onHide={() => setIsVisibleGettingOutShelter(false)} centered>
            <form onSubmit={handleGettingOut}>
                <Modal.Header closeButton>
                    <Modal.Title>구호소 퇴소 처리</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>해당 구호소에서 퇴소 처리를 진행하시겠습니까?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={(e)=>{handleGettingCancelButton('OUT', e)}} className="dms-btn dms-btn-xs dms-btn-outline me-2">Cancel</button>
                    <button onClick={() => setIsVisibleGettingOutShelter(false)} className="dms-btn dms-btn-xs dms-btn-filled">OK</button>
                </Modal.Footer>
            </form>
            </Modal>
        </div>
    );
}
 
export default VolunteerDetails;