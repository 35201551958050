import { useState, useEffect } from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import ApiHelper from "../Helpers/ApiHelper";
import useFetchWithParameters from "../useFetchWithParameters";

const ShelterInquiryBoard = () => {

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/fac/getInqLst'; //?facilityId=' + id;
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)    

    const [inqueryTypes, setinqueryTypes] = useState([])    // 문의 타입
    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D007&codeDepth=1', setinqueryTypes)
    }, [])
    
    console.log('inqueryTypes->', inqueryTypes);

    
    const [filters, setFilters] = useState({})
    console.log('filters->', filters);    


    const [selectedItems, setSelectedItems] = useState([])
    
    //const { id } = useParams('');




    const [totalCount, setTotalCount] = useState(0);
    const [inquiries, setInquiries] = useState([]);

    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })


    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);

    
    useEffect(() => {

        if (fetchResponse) {
            setTotalCount(fetchResponse?.totalCount);
            setInquiries(fetchResponse?.resultList ?? []);
        }

        /*
        // Fetch inquiries
        fetch(fetchResponse)
            .then(response => response.json())
            .then(data => {
                setInquiries(data.resultList);
                setTotalCount(data.totalCount);

            })
            .catch(error => {
                // Handle error if needed
                console.error('Error fetching inquiries:', error);
            });
        */
    }, [fetchResponse]);



    const handleCheckItem = (id) => {
        setSelectedItems(current => {
            if (current.includes(id)) {
                return current.filter(value => value != id)
            }
            else {
                return [...current, id]
            }
        })
    }


    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current, 
            reqPageNo: 1, 
            ...filters,
            ...(filters?.type ? {inquiryTypeCd: filters?.type} : null),
            ...(filters?.name ? {writerName: filters?.name} : null),            
            
            //...(filters?.cat1 ? {cat1Cd: filters?.cat1.substring(filters?.type.length)} : null),
            //...(filters?.cat2 ? {cat2Cd: filters?.cat2.substring(filters?.cat1.length)} : null),
            //...(filters?.cat3 ? {cat3Cd: filters?.cat3.substring(filters?.cat2.length)} : null),
            //...(filters?.cat4 ? {cat4Cd: filters?.cat4.substring(filters?.cat3.length)} : null),
        }))
    }

    const initializeFilter = () => {
        setFilters({});
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }


    const handleSaveExcel = () => {
        var getParameters = parameters ? new URLSearchParams(parameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/fac/expInqLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }    

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호소</h3>
                <Link to={'/shelter-management'} className="sidebar-link">구호소 등록/관리</Link>
                <Link to={'/shelter-inquiry-board'} className="sidebar-link active">구호소 문의게시판</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">문의게시판</h3>
                    </div>
                    {/*
                    <div className="py-5 text-center text-gray-03 white-rounded-container mb-20px">There is no shelter that the victim entered.</div>
                    */}
                    {/*<div className="white-rounded-container mb-20px"> */}
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">문의종류</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={filters?.type ? inqueryTypes.find(value => value.value == filters?.type) : null}
                                            onChange={(e) => setFilters(current => ({...current, type: e.value}))}
                                            isSearchable={false}
                                            options={inqueryTypes}
                                            placeholder={'전체'}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">Status</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{ label: 'All' }}
                                            isSearchable={false}
                                            options={[
                                                { value: 'All', label: 'All' },
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">성명</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-4">
                                        <FormControl 
                                            value={filters?.name ?? ''}
                                            onChange={e => setFilters(current => ({...current, name: e.target.value}))}
                                            placeholder="이름을 입력하세요." />


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button type="button" onClick={initializeFilter}  className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                            <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                        </div>
                    </form>

                    {totalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                    {totalCount !== 0 ? (

                            <div className="table-actions-container mb-20px">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            className="dropdown-sm mr"
                                            value={{label: parameters.reqPageSize + '개씩 보기'}}
                                            onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                            isSearchable={false}
                                            options={[
                                                { value: 10, label: '10개씩 보기' },
                                                { value: 20, label: '20개씩 보기' },
                                                { value: 30, label: '30개씩 보기' },
                                            ]}
                                        />
                                        <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> 엑셀저장</button>
                                    </div>
                                </div>
                            </div>

                    ) : null}


                    { totalCount !== null && totalCount !== 0 ? (

                        <table className="table dms-table-seperate">
                            <thead>
                                <tr>
                                    <th>문의종류</th>
                                    <th>요청내용</th>
                                    <th>작성자</th>
                                    <th>담당자</th>
                                    <th>재난</th>
                                    <th>최종 작성시간</th>
                                    <th>상태</th>
                                    <th>상세보기</th>
                                </tr>                        </thead>
                            <tbody>
                                {inquiries?.map(notice => (
                                    <tr key={notice.id}>
                                        <td>{notice.typeName}</td>
                                        <td>{notice.questionMemo}</td>
                                        <td>{notice.writerName}</td>
                                        <td>{notice.answererName}</td>
                                        <td>{notice.subject}</td>
                                        <td>{notice.updateDt}</td>
                                        <td>{notice.status}</td>
                                        <td style={{ width: "120px" }}><Link to={notice.statusCd === '01' ? "/shelter-inquiry-details-2/" + notice.boardId : "/shelter-inquiry-details-2/" + notice.boardId} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">상세보기</Link></td>
                                        {/* <td className={notice.facilityId % 2 == 0 ? 'text-green' : ''}>{notice.facilityId % 2 == 0 ? 'Answered' : 'Wait for an answer'}</td> */}
                                        {/* <td style={{ width: "120px" }}><Link to={notice.id % 2 == 0 ? "/shelter-inquiry-details-2" : "/shelter-inquiry-details-1"} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline">View Details</Link></td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    ) : null}                        

                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                        <ReactPaginate
                            onPageChange={(e) => setParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                            forcePage={Math.max(parameters.reqPageNo - 1, 0)}
                            pageCount={Math.ceil(totalCount / parameters.reqPageSize)}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            previousLabel={<LuChevronLeft size="20px" />}
                            nextLabel={<LuChevronRight size="20px" />}
                            breakLabel="..."
                            containerClassName="pagination justify-content-center"
                            pageClassName="page-item"
                            previousClassName="page-item page-previous"
                            nextClassName="page-item page-next"
                            breakClassName="page-item"
                            pageLinkClassName="page-link"
                            activeClassName="active"
                            previousLinkClassName="page-link"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                        />
                    ) : null}
                </div>

                <Footer />
            </div>

            <SaveExcelModal handleSaveExcel={handleSaveExcel} isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}

export default ShelterInquiryBoard;