import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { SlBell } from "react-icons/sl";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div className="row">
                <div className="col-lg-auto me-auto col-lg-logo1">
                    <img className="footer-logo" src="/images/logo.png"/>
                </div>
                <div className="col-lg-auto col-lg-links1">
                    <div className="row row-cols-lg-auto">
                        <div className="col">
                            <p className="footer-heading">이재민</p>
                            <Link to={'/victim-management'} className="footer-link">이재민 등록/관리</Link>
                            {/*<Link to={'/find-missing-children'} className="footer-link">미아/부모찾기</Link>*/}
                        </div>
                        <div className="col">
                            <p className="footer-heading">구호소</p>
                            <Link to={'/shelter-management'} className="footer-link">구호소 등록/관리</Link>
                            <Link to={'/shelter-inquiry-board'} className="footer-link">구호소 문의게시판</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">구호물품</p>
                            <Link to={'/inventory-management'} className="footer-link">구호물품 재고 관리</Link>
                            <Link to={'/warehouse-management'} className="footer-link">비축창고 등록/관리</Link>
                            <Link to={'/incoming-supplies'} className="footer-link">구호물품 입고내역</Link>
                            <Link to={'/outgoing-supplies'} className="footer-link">구호물품 출고내역</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">자원봉사자</p>
                            <Link to={'/volunteer-management'} className="footer-link">자원봉사자 등록/관리</Link>
                            <Link to={'/volunteer-assignment'} className="footer-link">자원봉사자 투입 현황</Link>
                        </div>
                        <div className="col">
                            <p className="footer-heading">시스템 관리</p>
                            <Link to={'/statistics'} className="footer-link">통계자료</Link>
                            <Link to={'/user-management'} className="footer-link">사용자 등록/관리</Link>
                            <Link to={'/disaster-management'} className="footer-link">재난코드 등록/관리</Link>
                            <Link to={'/notices-news'} className="footer-link">공지사항</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
