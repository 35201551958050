import {useEffect, useState} from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoChevronUp, IoClose, IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import {Link, useNavigate, useParams} from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import useFetch from "../useFetch";
import {useSelector} from "react-redux";

const AdminShelterBelongInquiriesAnswer = () => {
  const { user } = useSelector(state => state.user);
  const { id } = useParams()
  const navigate = useNavigate();

  const [isVisibleFullText, setIsVisibleFullText] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [inquiry, setInquiry] = useState('');
  const [author, setAuthor] = useState('');
  const [answer, setAnswer] = useState('');

  const apiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getInq?boardId='+id

  const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);

  const handleClickBack = () => {
    navigate('/shelter-admin-inquiries-belong-m');
  }

  const handleClickRegisterAnswer = () => {

    var formData = {
      boardId: id,
      answerMemo: answer,
      answererId: user.userId
    }

    fetch(process.env.REACT_APP_BASE_API_URL+'/fac/updateInq', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        //"Authorization": "Bearer "+localStorage.getItem("token"),
      }
    })
      .then(response => {
        if (!response.ok) {
          throw Error('Error in submitting data');
        }

        return response.json();
      })
      .then(data => {
        if (data.responseCode === 0)
        {
          setIsSuccess(true)
        }
      })
  }

  useEffect(() => {
    if (fetchResponse?.resultObject)
    {
      setInquiry(fetchResponse?.resultObject?.questionMemo)
      setAuthor(user.userName)
    }
  }, [fetchResponse]);


  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="sticky-top">
        <div className="mobile-navbar">
          <div className="blank-icon" />
          <div className="mobile-navbar-title">문의사항 답변</div>
          <div className="mobile-navbar-right" onClick={handleClickBack}>
            <img src="/images/icons/close.svg" />
          </div>
        </div>
      </div>

      {!isSuccess ? (
        <div className="content-container container flex-fill pt-0 pb-4">
          <div className="mobile-card form-group">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <p className="d-inline-block bg-cool-gray text-gray-04 px-3 py-1 mb-0">문의</p>
              <span onClick={() => setIsVisibleFullText(current => !current)} className="text-gray-04">{isVisibleFullText ? <IoChevronUp size="20px" /> : <IoChevronDown size="20px" />}</span>
            </div>
            <div className={`${isVisibleFullText ? '' : 'text-truncate-3'}`}>
              {inquiry}
            </div>
          </div>
          <div className="form-group">
            <div className="dms-form-label align-self-center">작성자</div>
            <FormControl value={author} disabled />
          </div>
          <div className="form-group">
            <div className="dms-form-label align-self-center">답변내용</div>
            <FormControl as="textarea" rows="5" value={answer} onChange={(e) => {setAnswer(e.target.value)}} />
            {/* <div className="input-helper text-end">0/240</div>*/}
          </div>
        </div>
      ) : (
        <>
          <div className="container pt-4 pb-4 flex-fill text-center">
            <img src="/images/gradient-check.svg" className="image-success-check mt-3" />
            <p className="fs-m fw-bold mt-3">성공하였습니다.</p>
            <p>"나의 구호소" 페이지로 가서 답변이 등록된 문의사항을 확인하실 수 있습니다.</p>
           {/* <button className="dms-btn dms-btn-m dms-btn-outline" onClick={handleClickBack}>Go page</button>*/}
          </div>
        </>
      )}

      <div className="sticky-bottom mobile-bottom-btn-container">
        {!isSuccess ? (
          <button type="button" onClick={handleClickRegisterAnswer} className="dms-btn dms-btn-l dms-btn-filled">등록하기</button>
        ) : (
          <button type="button" className="dms-btn dms-btn-l dms-btn-filled" onClick={handleClickBack}>확인</button>
        )}
      </div>
    </div>
  );
}

export default AdminShelterBelongInquiriesAnswer;