import { useEffect, useState } from "react";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import {Link, useNavigate} from "react-router-dom";
import { FormControl, Modal } from "react-bootstrap";
import { HiSpeakerWave } from "react-icons/hi2";
import MenuMobile from "../MenuMobile";
import { useSelector } from "react-redux";
import useFetch from "../useFetch";

const VictimShelterBelong = () => {
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate();
    
    const [isVisibleMenu, setIsVisibleMenu] = useState(false)

    const inquriesApiUrl = process.env.REACT_APP_BASE_API_URL+'/fac/getInqLst?facilityId='+user.facilityId+'&reqPageSize=5&reqPageNo=1'

    const [inquries, setInquries] = useState([])
    const [inquriesTotalCount, setInquriesTotalCount] = useState(null);

    const { fetchResponse: inquriesFetchResponse } = useFetch(inquriesApiUrl);

    const handleClickInquiryItem = (boardId) => {
        navigate(`/shelter-victim-inquiry-details-belong-m/${boardId}`)
    }

    useEffect(() => {
        if (inquriesFetchResponse)
        {
            setInquriesTotalCount(inquriesFetchResponse?.totalCount);
            setInquries(inquriesFetchResponse?.resultList ?? []);
        }
    }, [inquriesFetchResponse])

    if (isVisibleMenu)
    {
        return <MenuMobile setIsVisibleMenu={setIsVisibleMenu} />
    }
    
    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="container bg-white d-flex align-items-center py-4">
                    <div className="flex-fill">
                        <h4 className="mb-2">{user.userName}</h4>
                        {user?.resultObject?.currentShelterInfo ? <p className="fs-xs mb-0"><b>{user?.resultObject?.currentShelterInfo?.shelterName}</b></p> : null}
                    </div>
                    {/*<div>
                        <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active ms-2">Go to shelter</button>
                    </div>*/}
                </div>
            </div>
            
            <div className="content-container container flex-fill pt-0 pb-3">
                <div className="mobile-card">
                    <Link to={'/shelter-victim-notices-belong-m'} className="d-flex align-items-center plain-link">
                        <p className="text-green mb-0" style={{ lineHeight: '18px', position: 'relative', top: '-1px' }}><HiSpeakerWave className="me-2" size="22px" /></p>
                        <p className="flex-fill mb-0 text-truncate">공지사항</p>
                        <div className="text-gray-03 me-2"><IoChevronForward size="24px" /></div>
                    </Link>
                </div>
                <div className="mobile-card">
                    <Link to={'/shelter-victim-inquiries-belong-m'} className="d-flex align-items-center plain-link">
                        <h4 className="flex-fill fs-m mb-0 text-truncate">문의게시판</h4>
                        <div className="text-gray-03 me-2"><IoChevronForward size="24px" /></div>
                    </Link>
                    {inquries.map(item => (
                        <div key={item.boardId} className="d-flex align-items-center mt-3" onClick={() => handleClickInquiryItem(item.boardId)}>
                            <p className={`mobile-card-title ${item.statusCd == '01' ? 'text-gray-04' : 'text-green'}`}>[{item.status}]</p>&nbsp;
                            <p className="mobile-card-title text-truncate">{item.questionMemo}</p>
                        </div>
                    ))}
                </div>
                <div className="mobile-card">
                    <Link to="/shelter-list-no-belong-m" className="d-flex align-items-center plain-link">
                        <h4 className="flex-fill fs-m mb-0 text-truncate">다른 구호소 찾기</h4>
                        <div className="text-gray-03 me-2"><IoChevronForward size="24px" /></div>
                    </Link>
                </div>
            </div>

            <FooterMobile user={user} active="Shelter" isVisibleMenu={isVisibleMenu} setIsVisibleMenu={setIsVisibleMenu} />
        </div>
    );
}
 
export default VictimShelterBelong;