import React, { useState, useEffect, useRef } from 'react';
import { Alert, FormCheck, FormControl, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import { Editor } from '@tinymce/tinymce-react';
import { FiDownload } from "react-icons/fi";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";

const NoticesNewsPreview = () => {

    const history = useNavigate()
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const editorRef = useRef(null);
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);    
    const [isVisibleDelete, setIsVisibleDelete] = useState(false);

    const [fetchResponse, setFetchResponse] = React.useState(null);
    const [fetchIsLoading, setFetchIsLoading] = React.useState(false);
    const [fetchError, setFetchError] = React.useState(null);
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/noti/getNotice?noticeId=' + id;

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);


    useEffect(() => {
        if (!apiUrl) return;

        setFetchIsLoading(true);
        setFetchError(null);

        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw Error('Failed to load data');
                }
                return response.json();
            })
            .then(data => {
                setFetchResponse(data);
                setFetchIsLoading(false);
            })
            .catch(error => {
                setFetchError(error.message);
                setFetchIsLoading(false);
            });
    }, [apiUrl]);
    if (fetchIsLoading) {
        return <p>Loading...</p>;
    }

    if (fetchError) {
        return <p>Error: {fetchError}</p>;
    }

    if (!fetchResponse) {
        return null;
    }

    const noticeData = fetchResponse.resultObject;

    const formatDateString = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
    };



    const handleDelete = () => {
        setError(null)
        setSuccess(null)
        setIsLoading(true)
        
        var formData = {
            noticeId: id,
        }
        
        fetch(process.env.REACT_APP_BASE_API_URL+'/noti/delNotice', { 
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
                history('/notices-news')
                /*
                setDisasterType1(null)
                setDisasterType2(null)
                setName('')
                setMemo('')
                setStartDate('')
                setOccurBasisUrl('')
                setSelectedAreas([])
                */
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoading(false);
        })
    }    

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">시스템 관리</h3>
                <Link to={'/statistics'} className="sidebar-link">통계자료</Link>
                <Link to={'/user-management'} className="sidebar-link">사용자 등록/관리</Link>
                <Link to={'/disaster-management'} className="sidebar-link">재난코드 등록/관리</Link>
                <Link to={'/notices-news'} className="sidebar-link active">공지사항</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                                <h3 className="page-heading">{noticeData?.subject}</h3>
                            </div>
                            <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                                <div className="d-flex justify-content-end align-items-center">
                                    <p className="last-modified text-black">{noticeData?.facilityName} | {formatDateString(noticeData?.createDt)}
</p>
                                </div>
                                <div className="flex-fill d-flex justify-content-end align-items-center">
                                    
                                    {/*<button className="dms-btn dms-btn-s dms-btn-outline mr">Close</button>*/}

                                    <Link to="/notices-news" className="dms-btn dms-btn-s dms-btn-outline mr">닫기</Link>

                                    {/*<button className="dms-btn dms-btn-s dms-btn-outline mr">Delete</button>*/}
                                    <button type="button" onClick={() => setIsVisibleDelete(true)} className={`dms-btn dms-btn-s dms-btn-outline mr ${isLoading ? 'disabled' : ''}`}>삭제</button>

                                    <Link to={`/notices-news-details/${noticeData?.noticeId}`} className="dms-btn dms-btn-s dms-btn-outline">수정</Link>
                                </div>
                            </div>

                            <div className="white-rounded-container mb-20px">

                            {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                            {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                                
                            <div dangerouslySetInnerHTML={{ __html: noticeData?.memo }} />


                                {/*
                                <p className="stats-heading mt-4">Attachments <span className="text-green">4</span></p>
                                <div>
                                    <div className="notice-attachment-item">
                                        <p>2023-08-08 Test Test Test Test Test Test.pdf</p>
                                        <Link to={"/todo"}><FiDownload size="20px" /></Link>
                                    </div>
                                    <div className="notice-attachment-item">
                                        <p>2023-08-08 Test Test Test Test Test Test.pdf</p>
                                        <Link to={"/todo"}><FiDownload size="20px" /></Link>
                                    </div>
                                    <div className="notice-attachment-item">
                                        <p>2023-08-08 Test Test Test Test Test Test.pdf</p>
                                        <Link to={"/todo"}><FiDownload size="20px" /></Link>
                                    </div>
                                    <div className="notice-attachment-item">
                                        <p>2023-08-08 Test Test Test Test Test Test.pdf</p>
                                        <Link to={"/todo"}><FiDownload size="20px" /></Link>
                                    </div>
                                </div>
                                */}

                            </div>
                        </div>
                        {/*
                        <div className="col-lg-3">
                            <div>
                                <div className="notice-related-items">
                                    <p className="notice-related-heading">GUNDAM GUNDAM GUNDAM GUNDAM GUNDAM GUNDAM</p>
                                    <p className="notice-related-text">Ulsan City Hall | 2023-12-12</p>
                                </div>
                                <div className="notice-related-items">
                                    <p className="notice-related-heading">GUNDAM GUNDAM GUNDAM GUNDAM</p>
                                    <p className="notice-related-text">Ulsan City Hall | 2023-12-12</p>
                                </div>
                                <div className="notice-related-items">
                                    <p className="notice-related-heading">GUNDAM GUNDAM GUNDAM</p>
                                    <p className="notice-related-text">Ulsan City Hall | 2023-12-12</p>
                                </div>
                                <div className="notice-related-items">
                                    <p className="notice-related-heading">GUNDAM GUNDAM GUNDAM GUNDAM GUNDAM</p>
                                    <p className="notice-related-text">Ulsan City Hall | 2023-12-12</p>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>

                <DeleteModalWeb handleDelete={handleDelete} isVisible={isVisibleDelete} setIsVisible={setIsVisibleDelete} />

                <Footer />
            </div>
        </div>
    );
}

export default NoticesNewsPreview;