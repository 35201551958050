import { useRef, useState } from "react";
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import { Editor } from '@tinymce/tinymce-react';

const NoticesNewsRegister = () => {
    const editorRef = useRef(null);

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [memo, setMemo] = useState('');
    const [subject, setSubject] = useState('');

    // Default values for facilityId and writerId
    const [facilityId] = useState(14);
    const [writerId] = useState('admin_001');

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            memo,
            subject,
            facilityId,
            writerId,
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/noti/regNotice', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            });
    };

    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">시스템 관리</h3>
                <Link to={'/statistics'} className="sidebar-link">통계자료</Link>
                <Link to={'/user-management'} className="sidebar-link">사용자 등록/관리</Link>
                <Link to={'/disaster-management'} className="sidebar-link">재난코드 등록/관리</Link>
                <Link to={'/notices-news'} className="sidebar-link active">공지사항</Link>
            </div>
            <div className="content-container has-sidebar has-footer">

                <form onSubmit={handleSubmit} className="inner-container1">
                    <div className="inner-container">
                        <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                            <h3 className="page-heading">공지사항 작성</h3>
                            <div className="flex-fill d-flex justify-content-end align-items-center">
                                <Link to="/notices-news" className="dms-btn dms-btn-s dms-btn-outline mr">닫기</Link>
                                <button type="submit" className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active-2">등록</button>
                            </div>
                        </div>

                        {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                        {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}


                        <div className="white-rounded-container mb-20px">

                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">게시물 제목<span className="label-ast">*</span></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl
                                            onChange={e => setSubject(e.target.value)}
                                            value={subject}
                                            required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <Editor
                                    apiKey='ds9a45q8iocswd1yxliywcofzfu6bh1ywbla5pway156xhtn'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    // /initialValue="<p>This is the initial content of the editor.</p>"
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                            'bold italic forecolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'

                                    }}
                                    onEditorChange={content => setMemo(content)}

                                />
                            </div>
                        </div>

                        {/*
                        <div className="white-rounded-container mb-20px">
                            <div className="form-heading"><h5>File attachments</h5></div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label mt-2">Uploading files</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-8">
                                        <FormControl placeholder="Please enter a name" />
                                        <div className="input-helper">Upload up to 4 files.</div>
                                    </div>
                                    <div className="dms-input-4">
                                        <button className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active"><GoPlusCircle size="22px" />Find File</button>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center"></div>
                                <div className="dms-input-row">
                                    <div className="dms-input-100">
                                        <table className="table dms-table-1">
                                            <thead>
                                                <tr>
                                                    <th>Filename</th>
                                                    <th>Preview</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-start">
                                                        012371923712.jpg
                                                        <button className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex ms-2"><IoClose size="16px" /> Delete</button>
                                                    </td>
                                                    <td style={{ width: "100px" }}>
                                                        <Link className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">Preview</Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                                */}
                                



                    </div>
                </form>

                <Footer />
            </div>
        </div>
    );
}

export default NoticesNewsRegister;