import React, { useState, useEffect, useRef } from 'react';
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { GoPlusCircle } from "react-icons/go";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import { GoArrowLeft } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import SearchAddressModal from "../Components/Modals/SearchAddressModal";
import { useSelector } from "react-redux";
import { store } from "../redux/store";
import { setUser } from "../redux/userSlice";
import ApiHelper from "../Helpers/ApiHelper";
import ValidatorHelper from "../Helpers/ValidatorHelper";

const EditInformationVictim = () => {
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate();

    const [currentStep, setCurrentStep] = useState(1)
    const [isHeadHousehold, setIsHeadHousehold] = useState(true)
    const [isVisibleAddress, setIsVisibleAddress] = useState(false)
    const [isAdd1, setIsAdd1] = useState(false)
    const [isAdd2, setIsAdd2] = useState(false)

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [name, setName] = useState('')
    const [noteCdText, setNotCdText] = useState('')
    const [userId, setUserId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [socialNumber, setSocialNumber] = useState('')
    const [socialNumber2, setSocialNumber2] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [mainAddress, setMainAddress] = useState('')
    const [detailedAddress, setDetailedAddress] = useState('')
    const [headHousehold, setHeadHousehold] = useState('')
    const [specialNotes, setSpecialNotes] = useState([])
    const [infants, setInfants] = useState([])

    const [specialNoteCategory, setSpecialNoteCategory] = useState(null)
    const [specialNoteCategory1, setSpecialNoteCategory1] = useState(null)
    const [specialNoteSymptom, setSpecialNoteSymptom] = useState('')
    const [infantName, setInfantName] = useState('')
    const [infantSocialNumber, setInfantSocialNumber] = useState('')

    const [passwordError, setPasswordError] = useState(null)

    const [specialNoteCategories, setSpecialNoteCategories] = useState([])


    const apiUrl = `${process.env.REACT_APP_BASE_API_URL}/victim/getVCTMInfo?userId=${user.userId}`;

    const getUserInfo = () => {
        if (!apiUrl) return;

        fetch(apiUrl)
          .then(response => {
              if (!response.ok) {
                  throw Error('Failed to load data');
              }
              return response.json();
          })
          .then(data => {
              const resultObject = data.resultObject;

              setUserId(resultObject.userId);
              setName(resultObject.name);
              setSocialNumber(resultObject.regNo.substring(0, 6));
              setSocialNumber2(resultObject.regNo.substring(6, 13));
              setPhoneNumber(resultObject.telNo);
              setZipCode(resultObject.zipCd);
              setMainAddress(resultObject.addr);
              setDetailedAddress(resultObject.addrDtl);
              setHeadHousehold(resultObject.hshdName);
              const existingSpecialNotes = resultObject.victimNoteList.map(note => ({
                  id: note.noteId,
                  noteCd: note.noteCd,
                  note: note.noteCdText,
                  symptom: note.symptom
              }));
              setSpecialNotes(existingSpecialNotes);
              const existingInfants = resultObject.infantsList.map(infant => ({
                  id: infant.infantId,
                  name: infant.name,
                  regNo: infant.regNo
              }));
              setInfants(existingInfants);
          })
          .catch(error => {
              console.error('Error occurred while fetching user details:', error);
          });
    }

    useEffect(() => {
        getUserInfo();
    }, [apiUrl]);

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D004&codeDepth=1', setSpecialNoteCategories)
    }, [])

    useEffect(() => {
        if (isHeadHousehold) {
            setHeadHousehold(name)
        }
    }, [name])

    useEffect(() => {
        if (isHeadHousehold) {
            setHeadHousehold(name)
        }
    }, [isHeadHousehold])

    useEffect(() => {
        if (ValidatorHelper.validatePassword(password, setPasswordError)) {
            if (password != passwordConfirm) {
                setPasswordError('비밀번호가 일치하지 않습니다.')
            }
        }
    }, [password])

    useEffect(() => {
        if (!ValidatorHelper.isValidPassword(password)) return
        if (password != passwordConfirm) {
            setPasswordError('비밀번호가 일치하지 않습니다.')
        }
        else {
            setPasswordError(null)
        }
    }, [passwordConfirm])

    const handleAddSpecialNotes = () => {
        if (specialNoteCategory && specialNoteSymptom) {
            const formData = {
                noteCd: specialNoteCategory.value,
                symptom: specialNoteSymptom,
                userId: user.userId
            }

            fetch(process.env.REACT_APP_BASE_API_URL+'/victim/regVCTMNote', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {
                    "Content-Type": "application/json",
                }
            })
              .then(response => {
                  if (!response.ok) {
                      throw Error('Error in submitting data');
                  }

                  return response.json();
              })
              .then(data => {
                  if (data.responseCode === 0)
                  {
                      getUserInfo();
                      setSpecialNoteCategory(null)
                      setSpecialNoteSymptom('')
                  }
              })
              .catch(error => {
                  console.error(error);
              })
        }
    }

    const handleRemoveSpecialNotes = (item) => {
        const formData = {
            noteId: item.id
        }

        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/delVCTMNote', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json"
            }
            })
          .then(response => {
              getUserInfo();
          })
          .catch(error => {
              console.error(error);
          })
    }

    const handleAddInfant = () => {
        const formData = {
            name: infantName,
            regNo: infantSocialNumber,
            parentId: user.userId
        }

        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/regVCTMINF', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json"
            }
        })
          .then(response => {
              if (!response.ok) {
                  throw Error('Error in submitting data');
              }

              return response.json();
          })
          .then(data => {
              if (data.responseCode === 0)
              {
                  getUserInfo();
                  setInfantName('')
                  setInfantSocialNumber('')
              }
          })
          .catch(error => {
              console.error(error);
          })
    }

    const handleClickClose = () => {
        navigate(-1);
    }

    const handleRemoveInfant = (item) => {
        const formData = {
            parentId: user.userId,
            infantId: item.id
        }

        fetch(process.env.REACT_APP_BASE_API_URL+'/victim/delVCTMINF', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json"
            }
        })
          .then(response => {
              getUserInfo();
          })
          .catch(error => {
              console.error(error);
          })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        setError(null)
        setSuccess(null)

        if (!ValidatorHelper.isValidPassword(password)) {
            setError('비밀번호는 영문(대소문자), 숫자, 특수문자 조합 8자리 이상이어야 합니다.')
            return
        }
        if (password != passwordConfirm) {
            setError('비밀번호가 일치하지 않습니다.')
            return
        }
        if (!zipCode) {
            setError('주소를 입력하세요.')
            return
        }

        setIsLoadingSubmit(true)

        var infantsList = infants.map(value => ({ name: value.name, regNo: value.regNo }))
        var victimNoteList = specialNotes.map(value => ({ noteCd: value.noteCd, symptom: value.symptom }))

        var formData = {
            userId: userId,
            pwd: password,
            name: name,
            bday: "",
            zipCd: zipCode,
            addr: mainAddress,
            addrDtl: detailedAddress,
            telNo: phoneNumber,
            email: "",
            typeCd: "02",
            regNo: socialNumber + socialNumber2,
            hshdName: headHousehold,
            hshdYn: isHeadHousehold ? 'Y' : 'N',
            outYn: "N",
            delYn: "N"
        }

        fetch(process.env.REACT_APP_BASE_API_URL + '/victim/updateVCTM', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')



                    // setName('')
                    // setUserId('')
                    // setPassword('')
                    // setPasswordConfirm('')
                    // setSocialNumber('')
                    // setPhoneNumber('')
                    // setZipCode('')
                    // setMainAddress('')
                    // setDetailedAddress('')
                    // setHeadHousehold('')
                    // setSpecialNotes([])
                    // setInfants([])
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');

                }
                setIsLoadingSubmit(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoadingSubmit(false);
            })
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <form onSubmit={handleSubmit}>
                <div className="sticky-top">
                    <div className="mobile-navbar">
                        <div className="blank-icon" />
                        <div className="mobile-navbar-title">개인정보 수정</div>
                        <div className="mobile-navbar-right">
                            <img src="/images/icons/close.svg" onClick={handleClickClose} />
                        </div>
                    </div>
                </div>

                <div className="content-container container flex-fill pb-4">
                    <div className="form-heading"><h5>개인정보</h5></div>

                    <div className="content-container container flex-fill pb-4">
                        <div>
                            {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                            {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">성함<span className="label-ast">*</span></div>
                                <FormControl
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    required={true} />
                            </div>

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">아이디<span className="label-ast">*</span></div>
                                <FormControl
                                    onChange={e => setUserId(e.target.value)}
                                    value={userId}
                                    required={true} />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">비밀번호<span className="label-ast">*</span></div>
                                <FormControl
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    className={passwordError ? 'is-invalid' : ''}
                                    placeholder="영문(대소문자), 숫자, 특수문자 조합 8자리 이상"
                                    required={true} />
                            </div>
                            <div className="form-group">
                                <FormControl
                                    onChange={e => setPasswordConfirm(e.target.value)}
                                    value={passwordConfirm}
                                    className={passwordError ? 'is-invalid' : ''}
                                    placeholder="비밀번호를 다시 입력하세요."
                                    required={true} />
                                {passwordError ? <div className="input-helper-error">{passwordError}</div> : null}
                            </div>

                            <div className="form-group">
                                <div className="dms-form-label align-self-center">주민등록번호<span className="label-ast">*</span></div>
                                <div className="d-flex align-items-center">
                                    <div className="flex-fill">
                                        <FormControl
                                            onChange={e => setSocialNumber(e.target.value)}
                                            value={socialNumber}
                                            required={true} />

                                    </div>
                                    <div className="px-2">-</div>
                                    <div className="flex-fill">
                                        <FormControl
                                          onChange={e => setSocialNumber2(e.target.value)}
                                          value={socialNumber2}
                                          required={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">연락처</div>
                                <FormControl
                                    onChange={e => setPhoneNumber(e.target.value)}
                                    value={phoneNumber}
                                    required={true} />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label align-self-center">주소<span className="label-ast">*</span></div>
                                <div className="d-flex">
                                    <div className="flex-fill me-2">
                                        <FormControl
                                            value={zipCode}
                                            disabled={true} />
                                    </div>
                                    <button type="button" onClick={() => setIsVisibleAddress(true)} className="dms-btn dms-btn-s dms-btn-outline align-self-center">찾기</button>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl
                                    value={mainAddress}
                                    disabled={true} />
                            </div>
                            <div className="form-group">
                                <div className="dms-form-label"></div>
                                <FormControl
                                    onChange={e => setDetailedAddress(e.target.value)}
                                    value={detailedAddress}
                                    required={true} />
                            </div>
                            <div className="form-group mb-0">
                                <div className="dms-form-label align-self-center">세대주 성명</div>
                                <div className="d-flex align-items-center">
                                    <div className="flex-fill me-2">
                                        <FormControl
                                            onChange={e => setHeadHousehold(e.target.value)}
                                            value={headHousehold}
                                            required={true}
                                            disabled={isHeadHousehold} />
                                    </div>
                                    <div className="dms-input-4">
                                        <div
                                            className={`dms-chip-btn dms-chip-btn-s ${isHeadHousehold ? 'dms-chip-btn-active' : ''} align-self-center`}
                                            onClick={() => setIsHeadHousehold(current => !current)}>
                                            <FormCheck checked={true} />
                                            본인<br/>세대주</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="mobile-section-separator" />
                        <div className="form-heading">
                            <h5>특이사항 입력</h5>
                            <p>질병 혹은 임신 사실을 적어 보다 원활한 케어를 지원합니다.</p>
                        </div>
                        <div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">특이사항</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <ReactSelect
                                            components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                            classNamePrefix="select"
                                            isSearchable={false}
                                            placeholder={'선택'}
                                            value={specialNoteCategory ?? null}
                                            onChange={(e) => setSpecialNoteCategory(e)}
                                            options={specialNoteCategories}
                                        />
                                    </div>
                                    <div className="dms-input-7 mt-2">
                                        <FormControl
                                            onChange={e => setSpecialNoteSymptom(e.target.value)}
                                            value={specialNoteSymptom}
                                            placeholder="증상을 입력하세요." />
                                    </div>
                                    <div className="dms-input-2 mt-2">
                                        <button type="button" onClick={handleAddSpecialNotes} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />추가하기</button>
                                    </div>
                                </div>
                            </div>

                            {specialNotes.length > 0 ? (
                                <table className="table dms-table-1">
                                    <thead>
                                        <tr>
                                            <th>특이사항</th>
                                            <th>증상</th>
                                            <th>관리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {specialNotes.map(specialNote => (
                                            <tr key={specialNote.id}>
                                                <td>{specialNote.note}</td>
                                                <td>{specialNote.symptom}</td>
                                                <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveSpecialNotes(specialNote)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">삭제</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>

                        <div className="mobile-section-separator" />

                        <div className="form-heading">
                            <h5>영유아 추가</h5>
                        </div>
                        <div>
                            <div className="form-group dms-input-container">
                                <div className="dms-form-label align-self-center">영유아 이름</div>
                                <div className="dms-input-row">
                                    <div className="dms-input-3">
                                        <FormControl
                                            onChange={e => setInfantName(e.target.value)}
                                            value={infantName} />
                                    </div>
                                    <div className="dms-input-2 d-flex mt-2">
                                        <div className="dms-form-label align-self-center">주민등록번호</div>
                                    </div>
                                    <div className="dms-input-5">
                                        <FormControl
                                            onChange={e => setInfantSocialNumber(e.target.value)}
                                            value={infantSocialNumber} />
                                    </div>
                                    <div className="dms-input-2 mt-2">
                                        <button type="button" onClick={handleAddInfant} className="dms-btn dms-btn-s dms-btn-outline dms-btn-outline-active w-100 px-0 align-self-center"><GoPlusCircle size="22px" />추가하기</button>
                                    </div>
                                </div>
                            </div>

                            {infants.length > 0 ? (
                                <table className="table dms-table-1">
                                    <thead>
                                        <tr>
                                            <th>영유아 이름</th>
                                            <th>주민등록번호</th>
                                            <th>관리</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {infants.map(infant => (
                                            <tr key={infant.id}>
                                                <td>{infant.name}</td>
                                                <td>{infant.regNo}</td>
                                                <td style={{ width: "90px" }}><button type="button" onClick={() => handleRemoveInfant(infant)} className="dms-btn dms-btn-xs dms-btn-sm dms-btn-outline d-inline-flex px-3">삭제</button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                        </div>
                        <div className="sticky-bottom mobile-bottom-btn-container">
                            {/* <button type="submit" className={`dms-btn dms-btn-l dms-btn-filled ${isLoadingSubmit ? 'disabled' : ''}`}>Finish</button> */}
                            {/* <button type="submit"  className="dms-btn dms-btn-l dms-btn-filled" >Finish</button> */}
                        </div>
                    </div>

                    <div className="sticky-bottom mobile-bottom-btn-container">

                    </div>
                </div>

                <div className="sticky-bottom mobile-bottom-btn-container">
                    <button type="submit" className="dms-btn dms-btn-l dms-btn-filled">수정하기</button>
                </div>

            </form>
            <SearchAddressModal
                setZipCode={setZipCode}
                setAddress={setMainAddress}
                isVisible={isVisibleAddress}
                setIsVisible={setIsVisibleAddress}
                fullscreen={true} />
        </div>
    );
}

export default EditInformationVictim;