import React, { useState, useEffect, useRef } from 'react';
import { Alert, FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import FooterMobile from "../Components/FooterMobile";
import { GoArrowLeft } from "react-icons/go";
import { IoHomeOutline } from "react-icons/io5";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import ReactSelect from "react-select";
import { Link, useParams } from "react-router-dom";
import { HiSpeakerWave } from "react-icons/hi2";
import { GoPlusCircle } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { useSelector } from 'react-redux';
import useFetch from '../useFetch';

const AdminShelterBelongEditNotice = () => {
    const { id } = useParams()
    const { user } = useSelector(state => state.user);
    
    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/noti/getNotice?noticeId=' + id;

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);
    
    const [memo, setMemo] = useState('');
    const [subject, setSubject] = useState('');

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setMemo(fetchResponse?.resultObject?.memo)
            setSubject(fetchResponse?.resultObject?.subject)
        }
    }, [fetchResponse])

    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);

        setIsLoadingSubmit(true);

        var formData = {
            memo: memo,
            subject: subject,
            noticeId: id
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/noti/updateNotice', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 
                "Content-Type": "application/json",
                //"Authorization": "Bearer "+localStorage.getItem("token"),
            }
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Error in submitting data');
            }

            return response.json();
        })
        .then(data => {
            if (data.responseCode === 0)
            {
                setSuccess(data.responseMessage ?? 'Success')
            }
            else
            {
                setError(data.responseMessage ?? 'Error in submitting data');
            }
            setIsLoadingSubmit(false);
        })
        .catch(error => {
            setError(error.message);
            setIsLoadingSubmit(false);
        })
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            <div className="sticky-top">
                <div className="mobile-navbar">
                    <div className="blank-icon" />
                    <div className="mobile-navbar-title">Edit Shelter Notice</div>
                    <Link to={"/shelter-admin-notice-details-belong-m/"+id} className="mobile-navbar-right plain-link">
                        <img src="/images/icons/close.svg" />
                    </Link>
                </div>
            </div>

            <div className="content-container container flex-fill pb-2">
                {success ? <Alert variant="success">{success ?? 'Success'}</Alert> : null}
                {error ? <Alert variant="danger">{error ?? 'Error'}</Alert> : null}

                <div className="form-group">
                    <div className="form-group">
                        <div className="dms-form-label align-self-center">제목</div>
                        <FormControl value={subject} onChange={(e) => {setSubject(e.target.value)}}  />
                    </div>
                    <div className="dms-form-label align-self-center">Notice Text</div>
                    <FormControl 
                        as="textarea"
                        required={true}
                        rows="10"
                        onChange={e => setMemo(e.target.value.substring(0,240))}
                        value={memo} />
                    <div className="input-helper text-end">{memo.length}/240</div>
                </div>
            </div>

            <div className="sticky-bottom mobile-bottom-btn-container">
                <button type="button" onClick={handleSubmit} className="dms-btn dms-btn-l dms-btn-filled">Modify</button>
            </div>
        </div >
    );
}

export default AdminShelterBelongEditNotice;