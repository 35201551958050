import React, { useState, useEffect, useRef } from 'react';
import { FormCheck, FormControl, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { AiOutlineFileExcel } from "react-icons/ai";
import Footer from "../Components/Footer";
import ReactSelect from "react-select";
import useFetch from "../useFetch";
import useFetchWithParameters from "../useFetchWithParameters";
import ApiHelper from "../Helpers/ApiHelper";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import DeleteModalWeb from "../Components/Modals/DeleteModalWeb";


const InventoryDetails = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    
    const { id } = useParams();

    const [isVisibleSaveExcelWarehouse, setIsVisibleSaveExcelWarehouse] = useState(false);
    const [isVisibleSaveExcelShelter, setIsVisibleSaveExcelShelter] = useState(false);
    const [isVisibleSaveExcelDistribution, setIsVisibleSaveExcelDistribution] = useState(false);

    const apiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getSply?splyId=' + id;
    const warehousesApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getFacSplyLst';
    const sheltersApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getFacSplyLst';
    const distributionsApiUrl = process.env.REACT_APP_BASE_API_URL + '/sply/getPayoutHist';

    const [kind, setKind] = useState({})
    const [cat1, setCat1] = useState({})
    const [cat2, setCat2] = useState({})
    const [cat3, setCat3] = useState({})
    const [cat4, setCat4] = useState({})
    const [memo, setMemo] = useState({})
    const [quantity, setQuantity] = useState({})

    const [area1Warehouse, setArea1Warehouse] = useState(null)
    const [area2Warehouse, setArea2Warehouse] = useState(null)
    const [facilityTypeWarehouse, setFacilityTypeWarehouse] = useState(null)

    const [area1Shelter, setArea1Shelter] = useState(null)
    const [area2Shelter, setArea2Shelter] = useState(null)
    const [facilityTypeShelter, setFacilityTypeShelter] = useState(null)

    const [area1Distribution, setArea1Distribution] = useState(null)
    const [area2Distribution, setArea2Distribution] = useState(null)
    const [facilityTypeDistribution, setFacilityTypeDistribution] = useState(null)

    const [areas1, setAreas1] = useState([])
    const [areas2Warehouse, setAreas2Warehouse] = useState([])
    const [areas2Shelter, setAreas2Shelter] = useState([])
    const [areas2Distribution, setAreas2Distribution] = useState([])
    const [facilityTypes, setFacilityTypes] = useState([])

    const [updateDt, setUpdateDt] = useState(null)    

    const [warehousesParameters, setWarehousesParameters] = useState({
        splyId: id,
        typeCd: '01',
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const [warehouses, setWarehouses] = useState([])
    const [warehousesTotalCount, setWarehousesTotalCount] = useState(null);
    const [filtersWarehouse, setFiltersWarehouse] = useState({})

    const [sheltersParameters, setSheltersParameters] = useState({
        splyId: id,
        typeCd: '02',
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [shelters, setShelters] = useState([])
    const [sheltersTotalCount, setSheltersTotalCount] = useState(null);
    const [filtersShelter, setFiltersShelter] = useState({})

    const [distributionsParameters, setDistributionsParameters] = useState({
        splyId: id,
        reqPageNo: 1,
        reqPageSize: 10,
    })
    const [distributions, setDistributions] = useState([])
    const [distributionsTotalCount, setDistributionsTotalCount] = useState(null);
    const [filtersDistribution, setFiltersDistribution] = useState({})

    const { fetchResponse, fetchIsLoading, fetchError } = useFetch(apiUrl);

    useEffect(() => {
        if (fetchResponse?.resultObject)
        {
            setCat1(fetchResponse?.resultObject?.cat1);
            setCat2(fetchResponse?.resultObject?.cat2);
            setCat3(fetchResponse?.resultObject?.cat3);
            setCat4(fetchResponse?.resultObject?.cat4);
            setKind(fetchResponse?.resultObject?.kind);
            setQuantity(fetchResponse?.resultObject?.quantity);
            setMemo(fetchResponse?.resultObject?.memo);
            setUpdateDt(fetchResponse?.resultObject?.updateDt);
        }
    }, [fetchResponse])

    const { fetchResponse: warehousesFetchResponse, fetchIsLoading: warehousesFetchIsLoading, fetchError: warehousesFetchError } = useFetchWithParameters(warehousesApiUrl, warehousesParameters);
    const { fetchResponse: sheltersFetchResponse, fetchIsLoading: sheltersFetchIsLoading, fetchError: sheltersFetchError } = useFetchWithParameters(sheltersApiUrl, sheltersParameters);
    const { fetchResponse: distributionsFetchResponse, fetchIsLoading: distributionsFetchIsLoading, fetchError: distributionsFetchError } = useFetchWithParameters(distributionsApiUrl, distributionsParameters);

    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
        ApiHelper.loadDropdown('groupCode=D003&codeDepth=1', setFacilityTypes)
    }, [])

    useEffect(() => {
        if (warehousesFetchResponse) {
            setWarehousesTotalCount(warehousesFetchResponse?.totalCount);
            setWarehouses(warehousesFetchResponse?.resultList ?? []);
        }
    }, [warehousesFetchResponse])

    useEffect(() => {
        if (sheltersFetchResponse) {
            setSheltersTotalCount(sheltersFetchResponse?.totalCount);
            setShelters(sheltersFetchResponse?.resultList ?? []);
        }
    }, [sheltersFetchResponse])

    useEffect(() => {
        if (distributionsFetchResponse) {
            setDistributionsTotalCount(distributionsFetchResponse?.totalCount);
            setDistributions(distributionsFetchResponse?.resultList ?? []);
        }
    }, [distributionsFetchResponse])

    useEffect(() => {
        if (areas1.length > 0)
        {
            setArea1Warehouse(areas1[0])
            setArea1Shelter(areas1[0])
            setArea1Distribution(areas1[0])
        }
    }, [areas1])

    useEffect(() => {
        if (!area1Warehouse) return
        setArea2Warehouse(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + area1Warehouse?.value, setAreas2Warehouse)
    }, [area1Warehouse])

    useEffect(() => {
        if (!area1Shelter) return
        setArea2Shelter(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + area1Shelter?.value, setAreas2Shelter)
    }, [area1Shelter])

    useEffect(() => {
        if (!area1Distribution) return
        setArea2Distribution(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1=' + area1Distribution?.value, setAreas2Distribution)
    }, [area1Distribution])

    const handleFilterSubmitWarehouse = (e) => {
        e.preventDefault();

        setWarehousesParameters(current => ({
            ...current,
            reqPageNo: 1,
            ...filtersWarehouse,
            ...(area1Warehouse?.value ? { area1Cd: area1Warehouse.value } : null),
            ...(area2Warehouse?.value ? { area2Cd: area2Warehouse.value } : null),
            ...(facilityTypeWarehouse?.value ? { facilityType: facilityTypeWarehouse.value } : null),
        }))
    }

    const initializeFilterWarehouse = () => {
        setFiltersWarehouse({});
        setArea2Warehouse(null)
        setFacilityTypeWarehouse(null)
        setWarehousesParameters(current => ({ splyId: id, typeCd: '01', reqPageNo: 1, reqPageSize: 10 }))
    }

    const handleFilterSubmitShelter = (e) => {
        e.preventDefault();

        setSheltersParameters(current => ({
            ...current,
            reqPageNo: 1,
            ...filtersShelter,
            ...(area1Shelter?.value ? { area1Cd: area1Shelter.value } : null),
            ...(area2Shelter?.value ? { area2Cd: area2Shelter.value } : null),
            ...(facilityTypeShelter?.value ? { facilityType: facilityTypeShelter.value } : null),
        }))
    }

    const initializeFilterShelter = () => {
        setFiltersShelter({});
        setArea2Shelter(null)
        setFacilityTypeShelter(null)
        setSheltersParameters(current => ({ splyId: id, typeCd: '02', reqPageNo: 1, reqPageSize: 10 }))
    }

    const handleFilterSubmitDistribution = (e) => {
        e.preventDefault();

        setDistributionsParameters(current => ({
            ...current,
            reqPageNo: 1,
            ...filtersDistribution,
            ...(area1Distribution?.value ? { area1Cd: area1Distribution.value } : null),
            ...(area2Distribution?.value ? { area2Cd: area2Distribution.value } : null),
            ...(facilityTypeDistribution?.value ? { facilityType: facilityTypeDistribution.value } : null),
        }))
    }

    const initializeFilterDistribution = () => {
        setFiltersDistribution({});
        setArea2Distribution(null)
        setFacilityTypeDistribution(null)
        setDistributionsParameters(current => ({ splyId: id, reqPageNo: 1, reqPageSize: 10 }))
    }

    const handleSaveExcelWarehouse = () => {
        var getParameters = warehousesParameters ? new URLSearchParams(warehousesParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expFacLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const handleSaveExcelShelter = () => {
        var getParameters = sheltersParameters ? new URLSearchParams(sheltersParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expFacLstXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }

    const handleSaveExcelDistribution = () => {
        var getParameters = distributionsParameters ? new URLSearchParams(distributionsParameters) : ''

        fetch(process.env.REACT_APP_BASE_API_URL+'/sply/expPayoutHistXlsx?'+getParameters, { 
            method: 'GET'
        })
        .then(response => {
            if (!response.ok) {
                throw Error('Failed to load data');
            }

            return response.blob();
        })
        .then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'download.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove()
        })
        .catch(error => {
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        setError(null);
        setSuccess(null);
        setIsLoading(true);

        var formData = {
            memo,
            splyId: id
        };

        fetch(process.env.REACT_APP_BASE_API_URL + '/sply/updateSply', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw Error('Error in submitting data');
                }

                return response.json();
            })
            .then(data => {
                if (data.responseCode === 0) {
                    setSuccess(data.responseMessage ?? 'Success')
                }
                else {
                    setError(data.responseMessage ?? 'Error in submitting data');
                }
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    };


    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">구호물품</h3>
                <Link to={'/inventory-management'} className="sidebar-link active">구호물품 재고 관리</Link>
                <Link to={'/warehouse-management'} className="sidebar-link">비축창고 등록/관리</Link>
                <Link to={'/incoming-supplies'} className="sidebar-link">구호물품 입고 내역</Link>
                <Link to={'/outgoing-supplies'} className="sidebar-link">구호물품 출고 내역</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">구호물품 상세보기</h3>
                        <div className="flex-fill d-flex justify-content-end align-items-center">
                            <p className="last-modified text-orange">{updateDt} 수정</p>
                            <Link to="/inventory-management" className="dms-btn dms-btn-s dms-btn-outline">닫기</Link>
                        </div>
                    </div>

                    <Tabs defaultActiveKey="tab1" id="tabs-example" fill>
                        <Tab eventKey="tab1" title="기본정보">
                            <form onSubmit={handleSubmit} >
                                <div className="d-flex justify-content-end align-items-center mb-20px">
                                    <button type="submit" className={`dms-btn dms-btn-s dms-btn-outline ${isLoading ? 'disabled' : ''}`}>수정</button>
                                </div>

                                <div className="white-rounded-container mb-20px">
                                    <div className="form-heading"><h5>상세정보</h5></div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">물품 종류</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={kind}
                                                    value={kind}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    options={[
                                                        { value: 'General', label: 'General' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">물품 분류</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={cat1}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    value={cat1}
                                                    options={[
                                                        { value: 'Category 1', label: 'Category 1' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                            <div className="dms-input-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={cat2}
                                                    value={cat2}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    options={[
                                                        { value: 'Category 2', label: 'Category 2' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                            <div className="dms-input-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={cat3}
                                                    value={cat3}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    options={[
                                                        { value: 'Category 3', label: 'Category 3' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                            <div className="dms-input-3">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    placeholder={cat4}
                                                    value={cat4}
                                                    isSearchable={false}
                                                    isDisabled={true}
                                                    options={[
                                                        { value: 'Category 4', label: 'Category 4' },
                                                        { value: 'Test', label: 'Test' },
                                                        { value: 'Test Test', label: 'Test Test' }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label align-self-center">재고 수량</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-3">
                                                <div className="input-group disabled">
                                                    <FormControl placeholder="Enter quantity" value={quantity} disabled />
                                                    <span className="input-group-text">개</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group dms-input-container">
                                        <div className="dms-form-label mt-1">구호물품 설명</div>
                                        <div className="dms-input-row">
                                            <div className="dms-input-9">
                                                <FormControl as="textarea" rows="8"
                                                    value={memo}
                                                    onChange={e => setMemo(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey="tab2" title="비축창고 재고 현황">
                            <form onSubmit={handleFilterSubmitWarehouse} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">지역/비축창고</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'선택'}
                                                    value={area1Warehouse ?? null}
                                                    onChange={(e) => setArea1Warehouse(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'전체'}
                                                    value={area2Warehouse ?? null}
                                                    onChange={(e) => setArea2Warehouse(e)}
                                                    options={areas2Warehouse}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">시설 종류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'전체'}
                                                    value={facilityTypeWarehouse ?? null}
                                                    onChange={(e) => setFacilityTypeWarehouse(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeFilterWarehouse} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                </div>
                            </form>

                            {warehousesTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {warehousesTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {sheltersFetchError ? <p className="search-result text-orange">{sheltersFetchError}</p> : null}
                                            {sheltersFetchIsLoading ? <p className="search-result">Loading data</p> :
                                                warehousesTotalCount !== null ? <p className="search-result">검색 결과 총 <span>{warehousesTotalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{ label: warehousesParameters.reqPageSize + '개씩 보기' }}
                                                onChange={(e) => setWarehousesParameters(current => ({ ...current, reqPageSize: e.value }))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: '10개씩 보기' },
                                                    { value: 20, label: '20개씩 보기' },
                                                    { value: 30, label: '30개씩 보기' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcelWarehouse(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline mr"><AiOutlineFileExcel size="16px" /> 엑셀 저장</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {warehousesTotalCount !== null && warehousesTotalCount !== 0 ? (

                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>창고종류</th>
                                            <th>시도</th>
                                            <th>시군구</th>
                                            <th>비축창고명</th>
                                            <th>재고</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {warehouses.map(item => (

                                            <tr key={item.id}>
                                                <td>{item.classCdName}</td>
                                                <td>{item.area1}</td>
                                                <td>{item.area2}</td>
                                                <td>{item.name}</td>
                                                <td>{item.splyCnt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {warehousesTotalCount !== null && warehousesTotalCount !== 0 && Math.ceil(warehousesTotalCount / warehousesParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setWarehousesParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(warehousesParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(warehousesTotalCount / warehousesParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab3" title="구호소별 재고 현황">
                            <form onSubmit={handleFilterSubmitShelter} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">지역/구호소명</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'선택'}
                                                    value={area1Shelter ?? null}
                                                    onChange={(e) => setArea1Shelter(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'전체'}
                                                    value={area2Shelter ?? null}
                                                    onChange={(e) => setArea2Shelter(e)}
                                                    options={areas2Shelter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">시설 종류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'전체'}
                                                    value={facilityTypeShelter ?? null}
                                                    onChange={(e) => setFacilityTypeShelter(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeFilterShelter} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                </div>
                            </form>

                            {sheltersTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {sheltersTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { sheltersFetchError ? <p className="search-result text-orange">{sheltersFetchError}</p> : null }
                                            { sheltersFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                sheltersTotalCount !== null ? <p className="search-result">검색 결과 총 <span>{sheltersTotalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{ label: sheltersParameters.reqPageSize + '개씩 보기' }}
                                                onChange={(e) => setSheltersParameters(current => ({ ...current, reqPageSize: e.value }))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: '10개씩 보기' },
                                                    { value: 20, label: '20개씩 보기' },
                                                    { value: 30, label: '30개씩 보기' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcelShelter(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> 엑셀 저장</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            
                            {sheltersTotalCount !== null && sheltersTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>시도</th>
                                            <th>시군구</th>
                                            <th>구호소 주소</th>
                                            <th>구호소명</th>
                                            <th>재고</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shelters.map(item => (

                                            <tr key={item.id}>
                                                <td>{item.classCdName}</td>
                                                <td>{item.area1}</td>
                                                <td>{item.area2}</td>
                                                <td>{item.name}</td>
                                                <td>{item.splyCnt}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}

                            {sheltersTotalCount !== null && sheltersTotalCount !== 0 && Math.ceil(sheltersTotalCount / sheltersParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setSheltersParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(sheltersParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(sheltersTotalCount / sheltersParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                        <Tab eventKey="tab4" title="배급현황">
                            <form onSubmit={handleFilterSubmitDistribution} className="white-rounded-container mb-20px">
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">지역/구소호명</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'선택'}
                                                    value={area1Distribution ?? null}
                                                    onChange={(e) => setArea1Distribution(e)}
                                                    options={areas1}
                                                />
                                            </div>
                                            <div className="col-lg-2">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'전체'}
                                                    value={area2Distribution ?? null}
                                                    onChange={(e) => setArea2Distribution(e)}
                                                    options={areas2Distribution}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-2 d-flex align-items-center">
                                        <div className="dms-filter-label">시설 종류</div>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row gx-20px">
                                            <div className="col-lg-4">
                                                <ReactSelect
                                                    components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                    classNamePrefix="select"
                                                    isSearchable={false}
                                                    placeholder={'전체'}
                                                    value={facilityTypeDistribution ?? null}
                                                    onChange={(e) => setFacilityTypeDistribution(e)}
                                                    options={facilityTypes}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <button type="button" onClick={initializeFilterDistribution} className="dms-btn dms-btn-xs dms-btn-outline mr">초기화</button>
                                    <button type="submit" className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                                </div>
                            </form>

                            {distributionsTotalCount === 0 ? <p className="fs-s text-orange">더이상 조회할 데이터가 없습니다</p> : null}

                            {distributionsTotalCount !== 0 ? (
                                <div className="table-actions-container mb-20px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            { distributionsFetchError ? <p className="search-result text-orange">{distributionsFetchError}</p> : null }
                                            { distributionsFetchIsLoading ? <p className="search-result">Loading data</p> : 
                                                distributionsTotalCount !== null ? <p className="search-result">검색 결과 총 <span>{distributionsTotalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                            }
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <ReactSelect
                                                components={{ DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6" /></svg> }}
                                                classNamePrefix="select"
                                                className="dropdown-sm mr"
                                                value={{ label: distributionsParameters.reqPageSize + '개씩 보기' }}
                                                onChange={(e) => setDistributionsParameters(current => ({ ...current, reqPageSize: e.value }))}
                                                isSearchable={false}
                                                options={[
                                                    { value: 10, label: '10개씩 보기' },
                                                    { value: 20, label: '20개씩 보기' },
                                                    { value: 30, label: '30개씩 보기' },
                                                ]}
                                            />
                                            <button type="button" onClick={() => setIsVisibleSaveExcelDistribution(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> 엑셀 저장</button>
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {distributionsTotalCount !== null && distributionsTotalCount !== 0 ? (
                                <table className="table dms-table-seperate">
                                    <thead>
                                        <tr>
                                            <th>처리일자</th>
                                            <th>재난명</th>
                                            <th>시도</th>
                                            <th>시군구</th>
                                            <th>구호소</th>
                                            <th>반출 담당자</th>
                                            <th>수령자</th>
                                            <th>수량</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {distributions.map(item => (

                                            <tr key={item.id}>
                                                <td>{item.splyDistDt}</td>
                                                <td>{item.disasterName}</td>
                                                <td>{item.area1}</td>
                                                <td>{item.area2}</td>
                                                <td>{item.facName}</td>
                                                <td>{item.chargeName}</td>
                                                <td>{item.recvName}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : null}
                            
                            {distributionsTotalCount !== null && distributionsTotalCount !== 0 && Math.ceil(distributionsTotalCount / distributionsParameters.reqPageSize) > 1 ? (
                                <ReactPaginate
                                    onPageChange={(e) => setDistributionsParameters(current => ({ ...current, reqPageNo: e.selected + 1 }))}
                                    forcePage={Math.max(distributionsParameters.reqPageNo - 1, 0)}
                                    pageCount={Math.ceil(distributionsTotalCount / distributionsParameters.reqPageSize)}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    previousLabel={<LuChevronLeft size="20px" />}
                                    nextLabel={<LuChevronRight size="20px" />}
                                    breakLabel="..."
                                    containerClassName="pagination justify-content-center"
                                    pageClassName="page-item"
                                    previousClassName="page-item page-previous"
                                    nextClassName="page-item page-next"
                                    breakClassName="page-item"
                                    pageLinkClassName="page-link"
                                    activeClassName="active"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    breakLinkClassName="page-link"
                                />
                            ) : null}
                        </Tab>
                    </Tabs>
                </div>

                <Footer />
            </div>
            
            <SaveExcelModal handleSaveExcel={handleSaveExcelWarehouse} isVisible={isVisibleSaveExcelWarehouse} setIsVisible={setIsVisibleSaveExcelWarehouse} />
            <SaveExcelModal handleSaveExcel={handleSaveExcelShelter} isVisible={isVisibleSaveExcelShelter} setIsVisible={setIsVisibleSaveExcelShelter} />
            <SaveExcelModal handleSaveExcel={handleSaveExcelDistribution} isVisible={isVisibleSaveExcelDistribution} setIsVisible={setIsVisibleSaveExcelDistribution} />
        </div>
    );
}

export default InventoryDetails;