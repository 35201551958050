import {useEffect, useState} from "react";
import { FormCheck, FormControl, FormSelect, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineFileExcel } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import Footer from "../Components/Footer";
import SaveExcelModal from "../Components/Modals/SaveExcelModal";
import ReactSelect from "react-select";
import useFetchWithParameters from "../useFetchWithParameters";
import ApiHelper from "../Helpers/ApiHelper";

const allListPparameters = { reqPageNo: 1, reqPageSize: 10000 }

const VolunteerAssignment = () => {
    const apiUrl = process.env.REACT_APP_BASE_API_URL+'/vltr/getVltrAsgnlHist';
    
    const [isVisibleSaveExcel, setIsVisibleSaveExcel] = useState(false)

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [filters, setFilters] = useState({})

    const [years, setYears] = useState([])
    const [allDisasters, setAllDisasters] = useState([])
    const [disasters, setDisasters] = useState([])

    const [areas1, setAreas1] = useState([])
    const [areas2, setAreas2] = useState([])
    const [shelters, setShelters] = useState([])

    const [area1, setArea1] = useState(null)
    const [area2, setArea2] = useState(null)
    const [shelter, setShelter] = useState(null)

    const [vltrRegCenterNo, setVltrRegCenterNo] = useState(null)

    const [itemTypes, setItemTypes] = useState([])

    const [parameters, setParameters] = useState({
        reqPageNo: 1,
        reqPageSize: 10,
    })

    const allListApiUrl = process.env.REACT_APP_BASE_API_URL+'/cfg/getDisasterCdLst';

    const { fetchResponse, fetchIsLoading, fetchError } = useFetchWithParameters(apiUrl, parameters);
    const { fetchResponse: allListFetchResponse } = useFetchWithParameters(allListApiUrl, allListPparameters);


    useEffect(() => {
        ApiHelper.loadDropdown('groupCode=D002&codeDepth=1', setItemTypes)
        ApiHelper.loadDropdown('groupCode=D008&codeDepth=1', setAreas1)
    }, [])

    useEffect(() => {
        if (fetchResponse)
        {
            setTotalCount(fetchResponse?.totalCount);
            setItems(fetchResponse?.resultList ?? []);
        }
    }, [fetchResponse])


    useEffect(() => {
        if(areas1.length > 0) setArea1(areas1[0])
    }, [areas1])

    useEffect(() => {
        if (!area1) return
        setArea2(null)

        ApiHelper.loadDropdown('groupCode=D008&codeDepth=2&codeLev1='+area1?.value, setAreas2)
    }, [area1])

    useEffect(() => {
        if (!area2) return
        setShelter(null)

        ApiHelper.loadDropdown('area2Cd='+area2?.value+'&typeCd=02', setShelters, 'fac/getFACLst', 'facilityId')
    }, [area2])

    useEffect(() => {
        if (allListFetchResponse)
        {
            const years = []
            const disasters = []
            allListFetchResponse?.resultList?.map(value => {
                if (value?.startDt)
                {
                    var year = value?.startDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }
                if (value?.endDt)
                {
                    var year = value?.endDt?.substring(0, 4)
                    if (!years.includes(year)) years.push(year)
                }

                if (value?.name)
                {
                    if (!disasters.find(disaster => disaster.value == value.disasterCd)) disasters.push({ value: value.disasterCd, label: value.name, year: value?.startDt?.substring(0, 4) })
                }
            })

            setYears(years?.sort()?.reverse()?.map(value => ({value: value, label: value})))
            setAllDisasters(disasters)
        }
    }, [allListFetchResponse])

    useEffect(() => {
        if (filters?.year)
        {
            setDisasters(allDisasters.filter(value => value.year == filters?.year))
        }
        else
        {
            setDisasters([])
        }
    }, [filters?.year])

    const handleFilterSubmit = (e) => {
        e.preventDefault();

        setParameters(current => ({
            ...current,
            reqPageNo: 1,
            ...filters,
            ...(area1?.value ? {area1Cd: area1.value} : null),
            ...(area2?.value ? {area2Cd: area2.value} : null),
            ...(shelter?.value ? {facilityId: shelter.value} : null),
            ...(vltrRegCenterNo?.value ? {vltrRegCenterNo: vltrRegCenterNo.value} : null),
        }))
    }

    const initializeFilter = () => {
        setFilters({});
        setArea2(null)
        setShelter(null)
        setParameters(current => ({reqPageNo: 1, reqPageSize: current?.reqPageSize}))
    }
    
    return (
        <div className="container-has-sidebar">
            <div className="sidebar sticky-top">
                <h3 className="sidebar-heading">자원봉사자</h3>
                <Link to={'/volunteer-management'} className="sidebar-link">자원봉사자 등록/관리</Link>
                <Link to={'/volunteer-assignment'} className="sidebar-link active">자원봉사자 투입 현황</Link>
            </div>
            <div className="content-container has-sidebar has-footer">
                <div className="inner-container">
                    <div className="page-heading-container d-flex justify-content-between align-items-center mb-20px">
                        <h3 className="page-heading">자원봉사자 투입 현황</h3>
                    </div>
                    <form onSubmit={handleFilterSubmit} className="white-rounded-container mb-20px">
                        <div className="row mb-20px">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">년도/재난</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                          components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                          classNamePrefix="select"
                                          placeholder={'전체'}
                                          value={filters?.year ? {label: filters?.year} : null}
                                          onChange={(e) => setFilters(current => ({...current, year: e.value}))}
                                          isSearchable={false}
                                          options={years}
                                        />
                                    </div>
                                    <div className="col-lg-9">
                                        <ReactSelect
                                          components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                          classNamePrefix="select"
                                          placeholder={'전체'}
                                          value={filters?.disasterCd ? disasters.find(value => value.value == filters.disasterCd) ?? null : null}
                                          onChange={(e) => setFilters(current => ({...current, disasterCd: e.value}))}
                                          isSearchable={false}
                                          options={disasters}
                                          noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">구호소</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-2">
                                        <ReactSelect
                                          components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                          classNamePrefix="select"
                                          isSearchable={false}
                                          placeholder={'선택'}
                                          value={area1 ?? null}
                                          onChange={(e) => setArea1(e)}
                                          options={areas1}
                                        />
                                    </div>
                                    <div className="col-lg-2">
                                        <ReactSelect
                                          components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                          classNamePrefix="select"
                                          isSearchable={false}
                                          placeholder={'전체'}
                                          value={area2 ?? null}
                                          onChange={(e) => setArea2(e)}
                                          options={areas2}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <ReactSelect
                                          components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                          classNamePrefix="select"
                                          isSearchable={false}
                                          placeholder={'전체'}
                                          value={shelter ?? null}
                                          onChange={(e) => setShelter(e)}
                                          options={shelters}
                                          noOptionsMessage={() => '데이터 없음'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-lg-2 d-flex align-items-center">
                                <div className="dms-filter-label">등록센터/성명</div>
                            </div>
                            <div className="col-lg-10">
                                <div className="row gx-20px">
                                    <div className="col-lg-3">
                                        <ReactSelect
                                          components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                          classNamePrefix="select"
                                          isSearchable={false}
                                          placeholder={'전체'}
                                          value={vltrRegCenterNo ?? null}
                                          onChange={(e) => setVltrRegCenterNo(e)}
                                          options={[
                                              { value: '01', label: '1365포털' },
                                              { value: '02', label: '현장등록' },
                                          ]}
                                        />
                                    </div>
                                    <div className="col-lg-5">
                                        <FormControl
                                          value={filters?.name ?? ''}
                                          onChange={e => setFilters(current => ({...current, name: e.target.value}))}
                                          placeholder="이름을 입력하세요." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <button className="dms-btn dms-btn-xs dms-btn-outline mr" onClick={initializeFilter} >초기화</button>
                            <button className="dms-btn dms-btn-xs dms-btn-filled">검색</button>
                        </div>
                    </form>
                    <div className="table-actions-container mb-20px">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                { fetchError ? <p className="search-result text-orange">{fetchError}</p> : null }
                                { fetchIsLoading ? <p className="search-result">검색 중</p> :
                                  totalCount !== null ? <p className="search-result">검색 결과 총 <span>{totalCount}</span> 건</p> : <p className="search-result">&nbsp;</p>
                                }
                            </div>
                            <div className="d-flex justify-content-end">
                                <ReactSelect
                                  components={{DropdownIndicator: () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="#343a40" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2 5 6 6 6-6"/></svg>}}
                                  classNamePrefix="select"
                                  className="dropdown-sm mr"
                                  value={{label: parameters.reqPageSize + '개씩 보기'}}
                                  onChange={(e) => setParameters(current => ({...current, reqPageSize: e.value}))}
                                  isSearchable={false}
                                  options={[
                                      { value: 10, label: '10개씩 보기' },
                                      { value: 20, label: '20개씩 보기' },
                                      { value: 30, label: '30개씩 보기' },
                                  ]}
                                />
                                <button type="button" onClick={() => setIsVisibleSaveExcel(true)} className="dms-btn dms-btn-s dms-btn-sm dms-btn-outline"><AiOutlineFileExcel size="16px" /> 엑셀 저장</button>
                            </div>
                        </div>
                    </div>
                    <table className="table dms-table-seperate">
                        <thead>
                            <tr>
                                <th>등록센터</th>
                                <th>성명</th>
                                <th>연락처</th>
                                <th>시도</th>
                                <th>시군구</th>
                                <th>구호소명</th>
                                <th>재난명</th>
                                <th>입소일시</th>
                                <th>퇴소일시</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={item.enterId}>
                                    <td>{item.vltrRegCenterName}</td>
                                    <td>{item.userName}</td>
                                    <td>{item.userTelNo}</td>
                                    <td>{item.city}</td>
                                    <td>{item.dist}</td>
                                    <td>{item.name}</td>
                                    <td>{item.disasterName}</td>
                                    <td>{item.startSvcDt}</td>
                                    <td>{item.endSvcDt}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {totalCount !== null && totalCount !== 0 && Math.ceil(totalCount/parameters.reqPageSize) > 1 ? (
                      <ReactPaginate
                        onPageChange={(e) => setParameters(current => ({...current, reqPageNo: e.selected+1}))}
                        forcePage={Math.max(parameters.reqPageNo-1, 0)}
                        pageCount={Math.ceil(totalCount/parameters.reqPageSize)}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        previousLabel={<LuChevronLeft size="20px" />}
                        nextLabel={<LuChevronRight size="20px" />}
                        breakLabel="..."
                        containerClassName="pagination justify-content-center"
                        pageClassName="page-item"
                        previousClassName="page-item page-previous"
                        nextClassName="page-item page-next"
                        breakClassName="page-item"
                        pageLinkClassName="page-link"
                        activeClassName="active"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        breakLinkClassName="page-link"
                      />
                    ) : null}
                </div>
                    
                <Footer />
            </div>

            <SaveExcelModal isVisible={isVisibleSaveExcel} setIsVisible={setIsVisibleSaveExcel} />
        </div>
    );
}
 
export default VolunteerAssignment;