import { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import VictimManagement from "./Victims/VictimManagement";
import './bootstrap.css';
import './styles.css';
import VictimDetails from "./Victims/VictimDetails";
import VictimRegister from "./Victims/VictimRegister";
import FindMissingChild from "./Victims/FindMissingChild";
import RegisterMissingChild from "./Victims/RegisterMissingChild";
import FindMissingChildDetails from "./Victims/FindMissingChildDetails";
import FindMissingParentDetails from "./Victims/FindMissingParentDetails";
import EditMissingChild from "./Victims/EditMissingChild";
import EditMissingParent from "./Victims/EditMissingParent";
import InventoryManagement from "./Relief/InventoryManagement";
import TransferSupplies from "./Relief/TransferSupplies";
import InventoryDetails from "./Relief/InventoryDetails";
import WarehouseManagement from "./Relief/WarehouseManagement";
import WarehouseDetails from "./Relief/WarehouseDetails";
import WarehouseRegister from "./Relief/WarehouseRegister";
import IncomingSupplies from "./Relief/IncomingSupplies";
import OutgoingSupplies from "./Relief/OutgoingSupplies";
import RequestsForSupplies from "./Relief/RequestsForSupplies";
import RegisterForSupplies from "./Relief/RegisterForSupplies";
import RequestsForSuppliesDetails from "./Relief/RequestsForSuppliesDetails";
import RequestsForSuppliesDetails2 from "./Relief/RequestsForSuppliesDetails2";
import ShelterManagement from "./Shelter/ShelterManagement";
import ShelterDetails from "./Shelter/ShelterDetails";
import ShelterRegister from "./Shelter/ShelterRegister";
import ShelterSuppliesRegister from "./Shelter/ShelterSuppliesRegister";
import ShelterVolunteerRegister from "./Shelter/ShelterVolunteerRegister";
import ShelterInquiryBoard from "./Shelter/ShelterInquiryBoard";
import ShelterInquiryDetails1 from "./Shelter/ShelterInquiryDetails1";
import ShelterInquiryDetails2 from "./Shelter/ShelterInquiryDetails2";
import ShelterInquiryDetails3 from "./Shelter/ShelterInquiryDetails3";
import VolunteerManagement from "./Volunteer/VolunteerManagement";
import VolunteerDetails from "./Volunteer/VolunteerDetails";
import VolunteerRegister from "./Volunteer/VolunteerRegister";
import VolunteerAssignment from "./Volunteer/VolunteerAssignment";
import VolunteerRequests from "./Volunteer/VolunteerRequests";
import VolunteerRequestsDetails1 from "./Volunteer/VolunteerRequestsDetails1";
import VolunteerRequestsDetails2 from "./Volunteer/VolunteerRequestsDetails2";
import VolunteerRequestsRegister from "./Volunteer/VolunteerRequestsRegister";
import Statistics from "./System/Statistics";
import UserManagement from "./System/UserManagement";
import UserRegister from "./System/UserRegister";
import UserDetails from "./System/UserDetails";
import DisasterManagement from "./System/DisasterManagement";
import DisasterRegister from "./System/DisasterRegister";
import DisasterDetails from "./System/DisasterDetails";
import NoticesNews from "./System/NoticesNews";
import NoticesNewsRegister from "./System/NoticesNewsRegister";
import NoticesNewsDetails from "./System/NoticesNewsDetails";
import NoticesNewsPreview from "./System/NoticesNewsPreview";
import Login from "./Login";
import VictimRegisterMobile from "./Victims/VictimRegisterMobile";
import Dashboard from "./Dashboard";
import EditInformationAdmin from "./EditInformation/EditInformationAdmin";
import EditInformationVolunteer from "./EditInformation/EditInformationVolunteer";
import EditInformationVictim from "./EditInformation/EditInformationVictim";
import FindMissingChildMobile from "./Victims/FindMissingChildMobile";
import FindMissingChildDetailsMobile from "./Victims/FindMissingChildDetailsMobile";
import FindMissingParentDetailsMobile from "./Victims/FindMissingParentDetailsMobile";
import RegisterMissingChildMobile from "./Victims/RegisterMissingChildMobile";
import EditMissingChildMobile from "./Victims/EditMissingChildMobile";
import EditMissingParentMobile from "./Victims/EditMissingParentMobile";
import MenuMobile from "./MenuMobile";
import QrVictimMobile1 from "./QRVictim/QrVictimMobile1";
import QrAdminMobile from "./QrAdmin/QrAdminMobile";
import BringingInReliefSuppliesMobile from "./QrAdmin/BringingInReliefSuppliesMobile";
import QrScanMealMobile from "./QrAdmin/QrScanMealMobile";
import VictimShelterBelong from "./VictimShelterBelongs/VictimShelterBelong";
import VictimShelterBelongNotices from "./VictimShelterBelongs/VictimShelterBelongNotices";
import VictimShelterBelongNoticeDetails from "./VictimShelterBelongs/VictimShelterBelongNoticeDetails";
import VictimShelterBelongInquiries from "./VictimShelterBelongs/VictimShelterBelongInquiries";
import VictimShelterBelongInquiryDetails1 from "./VictimShelterBelongs/VictimShelterBelongInquiryDetails1";
import VictimShelterBelongRegisterInquiry from "./VictimShelterBelongs/VictimShelterBelongRegisterInquiry";
import VictimShelterBelongEditInquiry from "./VictimShelterBelongs/VictimShelterBelongEditInquiry";
import VictimShelterBelongHistory from "./VictimShelterBelongs/VictimShelterBelongHistory";
import AdminShelterBelong from "./AdminShelterBelong/AdminShelterBelong";
import AdminShelterBelongNotices from "./AdminShelterBelong/AdminShelterBelongNotices";
import AdminShelterBelongRegisterNotice from "./AdminShelterBelong/AdminShelterBelongRegisterNotice";
import AdminShelterBelongNoticeDetails from "./AdminShelterBelong/AdminShelterBelongNoticeDetails";
import AdminShelterBelongEditNotice from "./AdminShelterBelong/AdminShelterBelongEditNotice";
import AdminShelterBelongInquiries from "./AdminShelterBelong/AdminShelterBelongInquiries";
import AdminShelterBelongInquiriesDetails1 from "./AdminShelterBelong/AdminShelterBelongInquiriesDetails1";
import AdminShelterBelongInquiriesDetails2 from "./AdminShelterBelong/AdminShelterBelongInquiriesDetails2";
import AdminShelterBelongInquiriesAnswer from "./AdminShelterBelong/AdminShelterBelongInquiriesAnswer";
import AdminShelterBelongVictimsInShelter from "./AdminShelterBelong/AdminShelterBelongVictimsInShelter";
import AdminShelterBelongVictimsInShelterDetails from "./AdminShelterBelong/AdminShelterBelongVictimsInShelterDetails";
import AdminShelterBelongReliefSupplies from "./AdminShelterBelong/AdminShelterBelongReliefSupplies";
import ShelterNoBelongList from "./ShelterNoBelong/ShelterNoBelongList";
import ShelterNoBelongNotices from "./ShelterNoBelong/ShelterNoBelongNotices";
import ShelterNoBelongDetails from "./ShelterNoBelong/ShelterNoBelongDetails";
import LinksMobile from "./LinksMobile";
import KakaoMap from "./KakaoMap";
import KakaoAddress from "./KakaoAddress";
import DashboardMobile from "./DashboardMobile";
import QrScanSuppliesMobile from "./QrAdmin/QrScanSuppliesMobile";
import QrScanInOutMobile from "./QrAdmin/QrScanInOutMobile";
import LoginMobile from "./LoginMobile";
import ShelterNoBelongNoticeDetails from "./ShelterNoBelong/ShelterNoBelongNoticeDetails";

const App = () => {
    return (
        <>
            <Routes>
                <Route path="/links-m" element={<LinksMobile />} />

                <Route path="/login" element={<Login />} />
                <Route path="/login-m" element={<LoginMobile />} />
                <Route path="/dashboard-m" element={<DashboardMobile />} />
                <Route path="/victim-register-m" element={<VictimRegisterMobile />} />
                <Route path="/edit-information-admin" element={<EditInformationAdmin />} />
                <Route path="/edit-information-volunteer" element={<EditInformationVolunteer />} />
                <Route path="/edit-information-victim" element={<EditInformationVictim />} />
                <Route path="/find-missing-children-m" element={<FindMissingChildMobile />} />
                <Route path="/find-missing-children-details-m" element={<FindMissingChildDetailsMobile />} />
                <Route path="/find-missing-parent-details-m" element={<FindMissingParentDetailsMobile />} />
                <Route path="/register-missing-children-m" element={<RegisterMissingChildMobile />} />
                <Route path="/edit-missing-children-m" element={<EditMissingChildMobile />} />
                <Route path="/edit-missing-parent-m" element={<EditMissingParentMobile />} />
                <Route path="/menu" element={<MenuMobile />} />
                <Route path="/qr-victim-m/:id" element={<QrVictimMobile1 />} />
                <Route path="/qr-admin-m" element={<QrAdminMobile />} /> 
                <Route path="/qr-scan-in-out" element={<QrScanInOutMobile />} />
                <Route path="/qr-scan-supplies/:id" element={<QrScanSuppliesMobile />} />
                <Route path="/qr-scan-meal" element={<QrScanMealMobile />} />
                <Route path="/bringing-in-relief-supplies-m" element={<BringingInReliefSuppliesMobile />} />
                <Route path="/shelter-victim-belong-m" element={<VictimShelterBelong />} />
                <Route path="/shelter-victim-notices-belong-m" element={<VictimShelterBelongNotices />} />
                <Route path="/shelter-victim-notice-details-belong-m/:id" element={<VictimShelterBelongNoticeDetails />} />
                <Route path="/shelter-victim-inquiries-belong-m" element={<VictimShelterBelongInquiries />} />
                <Route path="/shelter-victim-inquiry-details-belong-m/:id" element={<VictimShelterBelongInquiryDetails1 />} />
                <Route path="/shelter-victim-register-inquiry-belong-m" element={<VictimShelterBelongRegisterInquiry />} />
                <Route path="/shelter-victim-edit-inquiry-belong-m/:id" element={<VictimShelterBelongEditInquiry />} />
                <Route path="/shelter-victim-history-belong-m" element={<VictimShelterBelongHistory />} />
                <Route path="/shelter-admin-belong-m" element={<AdminShelterBelong />} />
                <Route path="/shelter-admin-notices-belong-m" element={<AdminShelterBelongNotices />} />
                <Route path="/shelter-admin-register-notice-belong-m" element={<AdminShelterBelongRegisterNotice />} />
                <Route path="/shelter-admin-notice-details-belong-m/:id" element={<AdminShelterBelongNoticeDetails />} />
                <Route path="/shelter-admin-edit-notice-belong-m/:id" element={<AdminShelterBelongEditNotice />} />
                <Route path="/shelter-admin-inquiries-belong-m" element={<AdminShelterBelongInquiries />} />
                <Route path="/shelter-admin-inquiry-details-belong-m/:id" element={<AdminShelterBelongInquiriesDetails1 />} />
                <Route path="/shelter-admin-inquiry-details-belong-1-m/:id" element={<AdminShelterBelongInquiriesDetails1 />} />
                <Route path="/shelter-admin-inquiry-details-belong-2-m/:id" element={<AdminShelterBelongInquiriesDetails2 />} />
                <Route path="/shelter-admin-inquiry-answer-belong-m/:id" element={<AdminShelterBelongInquiriesAnswer />} />
                <Route path="/shelter-admin-victims-in-shelter-belong-m" element={<AdminShelterBelongVictimsInShelter />} />
                <Route path="/shelter-admin-victims-in-shelter-details-belong-m/:id" element={<AdminShelterBelongVictimsInShelterDetails />} />
                <Route path="/shelter-admin-relief-supplies-belong-m" element={<AdminShelterBelongReliefSupplies />} />
                <Route path="/shelter-list-no-belong-m" element={<ShelterNoBelongList />} />
                <Route path="/shelter-details-no-belong-m/:id" element={<ShelterNoBelongDetails />} />
                <Route path="/shelter-notices-no-belong-m" element={<ShelterNoBelongNotices />} />
                <Route path="/shelter-notice-details-no-belong-m/:id" element={<ShelterNoBelongNoticeDetails />} />
                
                <Route element={<AppLayout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/kakao-map" element={<KakaoMap />} />
                    <Route path="/kakao-address" element={<KakaoAddress />} />
                    
                    <Route path="/victim-management" element={<VictimManagement />} />
                    <Route path="/victim-details/:id" element={<VictimDetails />} />
                    <Route path="/victim-register" element={<VictimRegister />} />
                    <Route path="/find-missing-children" element={<FindMissingChild />} />
                    <Route path="/register-missing-children" element={<RegisterMissingChild />} />
                    <Route path="/find-missing-children-details" element={<FindMissingChildDetails />} />
                    <Route path="/find-missing-parent-details" element={<FindMissingParentDetails />} />
                    <Route path="/edit-missing-children" element={<EditMissingChild />} />
                    <Route path="/edit-missing-parent" element={<EditMissingParent />} />
                    
                    <Route path="/inventory-management" element={<InventoryManagement />} />
                    <Route path="/inventory-details/:id" element={<InventoryDetails />} />
                    <Route path="/transfer-supplies" element={<TransferSupplies />} />
                    <Route path="/warehouse-management" element={<WarehouseManagement />} />
                    <Route path="/warehouse-details/:id" element={<WarehouseDetails />} />
                    <Route path="/warehouse-register" element={<WarehouseRegister />} />
                    <Route path="/incoming-supplies" element={<IncomingSupplies />} />
                    <Route path="/outgoing-supplies" element={<OutgoingSupplies />} />
                    <Route path="/requests-for-supplies" element={<RequestsForSupplies />} />
                    <Route path="/register-for-supplies" element={<RegisterForSupplies />} />
                    <Route path="/requests-for-supplies-details-1" element={<RequestsForSuppliesDetails />} />
                    <Route path="/requests-for-supplies-details-2" element={<RequestsForSuppliesDetails2 />} />

                    <Route path="/shelter-management" element={<ShelterManagement />} />
                    <Route path="/shelter-register" element={<ShelterRegister />} />
                    <Route path="/shelter-details/:id" element={<ShelterDetails />} />
                    <Route path="/shelter-supplies-register" element={<ShelterSuppliesRegister />} />
                    <Route path="/shelter-volunteer-register" element={<ShelterVolunteerRegister />} />
                    <Route path="/shelter-inquiry-board" element={<ShelterInquiryBoard />} />
                    <Route path="/shelter-inquiry-details-1/:id" element={<ShelterInquiryDetails1 />} />
                    <Route path="/shelter-inquiry-details-2/:id" element={<ShelterInquiryDetails2 />} />
                    <Route path="/shelter-inquiry-details-3/:id" element={<ShelterInquiryDetails3 />} />
                    
                    <Route path="/volunteer-management" element={<VolunteerManagement />} />
                    <Route path="/volunteer-details/:id" element={<VolunteerDetails />} />
                    <Route path="/volunteer-register" element={<VolunteerRegister />} />
                    <Route path="/volunteer-assignment" element={<VolunteerAssignment />} />
                    <Route path="/volunteer-requests" element={<VolunteerRequests />} />
                    <Route path="/volunteer-requests-details-1" element={<VolunteerRequestsDetails1 />} />
                    <Route path="/volunteer-requests-details-2" element={<VolunteerRequestsDetails2 />} />
                    <Route path="/volunteer-requests-register" element={<VolunteerRequestsRegister />} />
                    
                    <Route path="/statistics" element={<Statistics />} />
                    <Route path="/user-management" element={<UserManagement />} />
                    <Route path="/user-register" element={<UserRegister />} />
                    <Route path="/user-details/:id" element={<UserDetails />} />
                    <Route path="/disaster-management" element={<DisasterManagement />} />
                    <Route path="/disaster-register" element={<DisasterRegister />} />
                    <Route path="/disaster-details/:id" element={<DisasterDetails />} />
                    <Route path="/notices-news" element={<NoticesNews />} />
                    <Route path="/notices-news-register" element={<NoticesNewsRegister />} />
                    <Route path="/notices-news-details/:id" element={<NoticesNewsDetails />} />
                    <Route path="/notices-news-preview/:id" element={<NoticesNewsPreview />} />
                    {/* <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/track-admin" element={<AdminTrack />} />
                    <Route path="/members" element={<MembersIndex />} />
                    <Route path="/members/create" element={<MembersCreate />} />
                    <Route path="/members/detail/:id" element={<MembersDetail />} />
                    <Route path="/members/edit/:id" element={<MembersEdit />} />
                    <Route path="/pending-members" element={<PendingMembersIndex />} />
                    <Route path="/pending-members/detail/:id" element={<PendingMembersDetail />} />
                    <Route path="/payments" element={<PaymentIndex />} />
                    <Route path="/payments/detail/:id" element={<PaymentDetail />} />
                    <Route path="*" element={<NotFound />} />
                    */}
                </Route>
            </Routes>
        </>
    );
};

const AppLayout = () => {
    const [sidebarToggled, setSidebarToggled] = useState(false);

    return (
        <div className={`admin-app ${sidebarToggled ? "app-sidebar-toggled" : ""}`} id="wrapper">
            <div id="content-wrapper" className="d-flex flex-column" onClick={() => {if (sidebarToggled) setSidebarToggled(false);}}>
                <Header
                    sidebarToggled={sidebarToggled}
                    setSidebarToggled={setSidebarToggled}
                />

                <Outlet />
            </div>
        </div>
    );
};

export default App;
